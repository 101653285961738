import { ACCOUNT_TYPE } from "./auth.model"

export interface ICommunityPost {
  "text": string,
  "image": string,
  "url": string,
  "file": string
}


export interface ICommunityFeed {
  uuid: string
  createdAt: string
  modifiedAt: string
  text: string
  image: any
  images: string[]
  url: any
  file: any
  isAdminPost: boolean
  pinned: boolean
  user?: User
  stats: Stats
  isLoggedInUserReacted: boolean
  profile?: Profile
}
export interface Profile{
  logo? : string
  name? : string
  type? : string
  userAvatar? : string
  userName? : string
  userUUID? : string
  uuid? : string
}
export interface User {
  uuid: string
  name: string
  avatar: string
  organizationName: string
  organizationLogo: string
  organizationType: ACCOUNT_TYPE
  organizationUUID: string
}

export interface Stats {
  totalComments: number
  totalReactions: number
  reactionCount: ReactionCount
}

export interface ReactionCount {
  like: number
  dislike: number
  support: number
  love: number
  funny: number
  surprise: number
  laugh: number
}


export interface IFeedComment {
  comment: string
  user: ICommentUser
  uuid: string
  createdAt: string
  replies: IFeedComment[]
  reactionStats: ReactionStats
}

export interface ICommentUser {
  name: string
  avatar: string
  uuid: string
  accountType: ACCOUNT_TYPE

  organizationLogo: string;
  organizationName: string;
  organizationType: string;
  organizationUUID: string;
}

export enum CommunityWallFileType {
  IMAGE = 'image',
  FILE = 'file',
}
export enum ReactionType {
  LIKE = 'like',
  DIS_LIKE = 'dislike',
  SUPPORT = 'support',
  LOVE = 'love',
  FUNNY = 'funny',
  SURPRISE = 'surprise',
  LAUGH = 'laugh',
}







export interface IReply {
  uuid: string
  comment: string
  createdAt: string
  user: IUser
  reactionStats: ReactionStats
  reactions: Reaction[]
}

interface IUser {
  uuid: string
  accountType: string
  name: string
  avatar: string
  organizationType: string
  organizationUUID: string
  organizationName: string
  organizationLogo: string
}

export interface ReactionStats {
  totalReactions: number
  reactionCount: ReactionCount
  isLoggedInUserReacted: boolean
}

export interface ReactionCount {
  like: number
  dislike: number
  support: number
  love: number
  funny: number
  surprise: number
  laugh: number
}

export interface Reaction {
  reactionType: string
  uuid: string
  user: ReactedUser
}

interface ReactedUser {
  uuid: string
  name: string
  avatar: string
  accountType: string
  organizationType: string
  organizationUUID: string
  organizationName: string
  organizationLogo: string
}
export interface IFeedUserDetails {
  name: string
  avatar: string
  accountType: ACCOUNT_TYPE
  companyUUID: string
  companyName: string
}
