<div class="modal-header">
  <h2 class="text-dark fw-bold fs-1 fs-4qx text-center">
    {{personalDetails.name}}
  </h2>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body px-10 py-8">

  <div class="row">
    <div class="col-8">
      <div class="row">

        <p class="my-4 text-gray-600 text-center fs-3">
          EMAIL
        </p>
        <div class="col-12">
          <a href="mailto:{{personalDetails.email}}" target="_blank"
            class="text-dark text-hover-primary d-flex justify-content-center flex-column align-items-center">
            <i class="bi bi-envelope fs-4hx text-dark text-center"></i>
            <p class="fw-bold fs-4 mt-4 text-center">{{personalDetails.email}}</p>
          </a>
        </div>
      </div>


      <div class="separator separator-dashed my-5"></div>

      <div class="row">
        <p class="my-4 text-gray-600 text-center fs-3">
          CALL
        </p>

        <div class="col-6">
          <a href="tel:{{'+'+personalDetails.mobileNumber}}" target="_blank"
            class="d-flex text-dark text-hover-primary justify-content-center flex-column align-items-center">
            <i class="bi bi-telephone fs-4hx text-center text-dark"></i>
            <p class="fw-bold fs-4 mt-4 text-center">{{'+'+personalDetails.mobileNumber}}</p>
          </a>
        </div>
        <div class="col-6">
          <a href="https://wa.me/+{{(personalDetails.whatsappNumber ||personalDetails.mobileNumber)}}?text=Hello" target="_blank"
            class=" text-dark text-hover-primary d-flex justify-content-center flex-column align-items-center">
            <i class="bi bi-whatsapp fs-4hx text-dark text-center"></i>
            <p class="fw-bold fs-4 mt-4 text-center">Whatsapp</p>
          </a>
        </div>
      </div>
    </div>
    <div class="col-4">

      <div class="px-3">
        <!-- <div class="fw-bolder fs-3">Contact</div> -->
        <div class="py-4 pt-5 d-flex justify-content-center align-items-center flex-column">
          <qr-code [value]="vCardString" [size]="150"></qr-code>

          <p class="text-muted text-center fs-8 mb-0">
            Scan to add contact
          </p>

          <button class="text-center btn-sm text-secondary border border-dark-gray border-1 bg-white "
            id="downloadButtonNormal" [vcdDownloadVCard]="vCard" [encoding]="vCardEncoding.none">
            Download vCard
          </button>

        </div>
      </div>
    </div>
  </div>


</div>
