import { Component, OnInit, Output, EventEmitter, Input, Injectable, OnDestroy } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateStruct, NgbModal, NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import { IMeetingAvailability, IMeetingPatchModel, METTING_AVAILABILITY, MeetingTime } from 'src/app/core/domain/meetings.model';
import * as moment from 'moment-timezone';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil, tap } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { ACCOUNT_TYPE, OrganizationInformationResponseModel } from 'src/app/core/domain';
import { getInvestorsOrg } from 'src/app/core/state/investors-organization-info';
import { getStartUpInfo } from 'src/app/core/state/startup';
import { getCorporateInfo } from 'src/app/core/state/corporate-info';
import { getPartnerInfo } from 'src/app/core/state/partner-info';
import { getProfileData } from 'src/app/core/state/profile';
import { ProfileService } from 'src/app/core/service/profile.service';
import { add } from 'date-fns';
import { getMentorInfo } from 'src/app/core/state/mentor-info';
import { getServiceProviderInfo } from 'src/app/core/state/service-provider-info';
import { calculateSlots, convertToLocalTime, createTimeSlots, findNextAvailableDate, generateTimeSlots, generateTimeSlotsWithAMPM, isDisabledDateForMeeting } from 'src/app/shared/utils/common-methods';
import { MilestonesService } from 'src/app/core/service/milestone.service';
import { accountTypelabelMap } from 'src/CONSTS';

import * as InvestorsOrgInfoActionActions from "src/app/core/state/investors-organization-info/investors-organization-info.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import * as CorporateActions from "src/app/core/state/corporate-info/corporate-info.action";
import * as MentorActions from "src/app/core/state/mentor-info/mentor-info.action";
import * as ServiceProviderActions from "src/app/core/state/service-provider-info/service-provider-info.action";
import * as PartnerActions from "src/app/core/state/partner-info/partner-info.action";
import * as ProgramOfficeActions from "src/app/core/state/program-office-members-info/program-office-members-info.action";
import * as IndividualActions from "src/app/core/state/individual-profile-info/individual-profile-info.action";
import { getBrandDetails } from 'src/app/core/state/global';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { getProgramOfficeInfo } from 'src/app/core/state/program-office-members-info';
import { getIndividualProfileInfo } from 'src/app/core/state/individual-profile-info';

const pad = (i: number): string => i < 10 ? `0${i}` : `${i}`;

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null ? `${pad(time.hour)}:${pad(time.minute)}` : null;
  }
}




/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
// @Injectable()
// export class CustomDateAdapter extends NgbDateAdapter<string> {

//   readonly DELIMITER = '-';

//   fromModel(value: string | null): NgbDateStruct | null {
//     if (value) {
//       const date = value.split(this.DELIMITER);
//       return {
//         day: parseInt(date[0], 10),
//         month: parseInt(date[1], 10),
//         year: parseInt(date[2], 10)
//       };
//     }
//     return null;
//   }

//   toModel(date: NgbDateStruct | null): string | null {
//     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
//   }
// }

@Component({
  selector: 'app-add-meeting-modal',
  templateUrl: './add-meeting-modal.component.html',
  styleUrls: ['./add-meeting-modal.component.scss'],
  providers: [
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
    // { provide: NgbDateAdapter, useClass: CustomDateAdapter },
  ]
})
export class AddMeetingModalComponent implements OnInit, OnDestroy {
  model: NgbDateStruct;

  time = { hour: 13, minute: 30 };

  meetingForm: FormGroup;
  @Input() modalData: {
    otherUser: {
      userUUID?: string;
      uid?: string;
      name: string;
    };
  };
  minDate;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();
  accountTypelabelMap = accountTypelabelMap
  companyName = '';

  userProfileData;
  calenderAvilablity: IMeetingAvailability;
  disabledDaysIndex = []
  timeSlots: Array<{
    label: string,
    value: string
  }> = []
  METTING_AVAILABILITY = METTING_AVAILABILITY;
  reviewers = [];
  selectedMeetingWithUser;
  brandDetails: Partial<IBrandDetails>;

  constructor(
    private meetingService: MeetingService,
    private toastAlertService: ToastAlertService,
    private modal: NgbModal,
    private ngxLoaderService: NgxUiLoaderService,
    private store: Store,
    private profileService: ProfileService,
    private calendar: NgbCalendar,
    private mileStoneService: MilestonesService
  ) { }

  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res;


        this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(async (res) => {
          if (res) {
            this.profileData = res;
            await this.getMeetingAvailability(this.profileData.uuid)

            // this.getMeetingCompanyName();

            if (this.modalData.otherUser) {
              this.getCompanyDetails()
            } else {
              this.getReviewers();
              this.setMeetingForm()
            }
          }
        })
      }
    })


    // this.getCalenderAvilablity();
  }

  isDisabled = (date: NgbDate, current: { month: number }) => {
    return isDisabledDateForMeeting(date,this.calenderAvilablity,this.calendar,this.disabledDaysIndex)
  };

  // getCalenderAvilablity( ) {
  //   this.meetingService.getCalenderAvilablity().subscribe((res)=> {
  //     this.calenderAvilablity = res;
  //     if(this.calenderAvilablity.availability ===  METTING_AVAILABILITY.specific_days) {
  //       this.disabledDaysIndex = this.calenderAvilablity.days.filter((e)=>e.closed).map(e=>e.dayIndex)
  //       console.log('this.disabledDaysIndex', this.disabledDaysIndex)

  //       this.timeSlots = this.createTimeSlots(this.calenderAvilablity.days[0].times);
  //       console.log('slots', this.timeSlots)
  //     }
  //   })
  // }



  async getMeetingAvailability(uid: string) {
    const res = await this.meetingService.getUsersAvailabilityById(uid).toPromise()
    // subscribe((res)=>{
    this.calenderAvilablity = res.data || {};
    if (this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.specific_days) {
      this.disabledDaysIndex = this.calenderAvilablity.days.filter((e) => e.closed).map(e => e.dayIndex)
      console.log('this.disabledDaysIndex', this.disabledDaysIndex)
    } else if (this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.anytime) {
      this.timeSlots = generateTimeSlotsWithAMPM(this.brandDetails?.features?.meeting_time_slot_difference_in_mins);
    }
    // })
  }


  getCompanyDetails() {
    const uid = this.modalData.otherUser.uid || this.modalData.otherUser.userUUID
    this.profileService.getUserPublicProfile(uid).subscribe(async (res) => {
      if (res) {
        this.userProfileData = res;
        console.log('userProfileData', this.userProfileData, { uid })
        await this.getMeetingAvailability(uid)

        this.getMeetingCompanyName();
      }
    }, (error) => {
      this.getMeetingCompanyName();
    })

  }


  getMeetingCompanyName() {
    console.log('getMeetingCompanyNamegetMeetingCompanyName', this.profileData)
    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {

      this.store.pipe(select(getInvestorsOrg)).subscribe((response: OrganizationInformationResponseModel) => {
        if (response) {
          this.companyName = response.data.organizationName
          this.setMeetingForm()
        }else{
          this.store.dispatch(new InvestorsOrgInfoActionActions.GetInvestorsOrgInfo);
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {

      const startupInfo$ = this.store.pipe(select(getStartUpInfo));
      console.log('startupInfo$')
      startupInfo$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.companyName = res.companyName
          console.log('startupInfo comp', this.companyName)
          this.setMeetingForm()
        }else{
          this.store.dispatch(new StartupActions.GetStartUpInfo);
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.pipe(select(getCorporateInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.companyName = res.data.companyName
          this.setMeetingForm()
        }else{
          this.store.dispatch(new CorporateActions.GetCorporateInfo);
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
      this.store.pipe(select(getMentorInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.companyName = res.data.name
          this.setMeetingForm()
        }else{
          this.store.dispatch(new MentorActions.GetMentorInfo);
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      this.store.pipe(select(getServiceProviderInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.companyName = res.data.name
          this.setMeetingForm()
        }else{
          this.store.dispatch(new ServiceProviderActions.GetServiceProviderInfo);
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.PARTNER) {

      this.store.pipe(select(getPartnerInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((response) => {
        if (response) {
          this.companyName = response.data.name
          this.setMeetingForm()
        }else{
          this.store.dispatch(new PartnerActions.GetPartnerInfo);
        }
      })
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {

      this.store.pipe(select(getProgramOfficeInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((response) => {
        if (response) {
          this.companyName = response.data.name
          this.setMeetingForm()
        }else{
          this.store.dispatch(new ProgramOfficeActions.ProgramOfficeInfo);
        }
      })
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.INDIVIDUAL) {

      this.store.pipe(select(getIndividualProfileInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((response) => {
        if (response) {
          this.companyName = response.data.name
          this.setMeetingForm()
        }else{
          this.store.dispatch(new IndividualActions.GetIndividualProfileInfo);
        }
      })
    }
  }


  setMeetingForm() {
    console.log('setMeetingFormsetMeetingFormsetMeetingForm')
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    this.meetingForm = new FormGroup({
      date: new FormControl('', [Validators.required]),
      timeFrom: new FormControl(null, [Validators.required]),
      timeTo: new FormControl('', [Validators.required]),
      meetingTitle: new FormControl('', [Validators.required]),
      meetingDescription: new FormControl('', []),
      otherUserUUID: new FormControl(''),
      duration: new FormControl('30', [Validators.required]),
    });

    if (this.modalData.otherUser) {
      this.meetingForm.get('date').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
        .subscribe((date) => {
          if (this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.specific_days || this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.anytime) {
            this.calculateSlots(date)
          }
        })
    }

    if (this.modalData && this.modalData.otherUser) {
      const today = add(new Date(), {
        hours: 1,
      });
      today.setMinutes(0);
      const startTime = today.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);

      today.setHours(today.getHours() + 2)
      const endTime = today.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);


      const nextAvailableDate = findNextAvailableDate(
        this.calenderAvilablity,
        this.calendar,
        this.disabledDaysIndex
      )
      if(nextAvailableDate) {
        this.model = nextAvailableDate
        console.log('calenderAvilablity abcd', this.calenderAvilablity  , this.disabledDaysIndex)
      }
      if (this.modalData.otherUser) {
        this.meetingForm.patchValue({
          otherUserUUID: this.modalData.otherUser.uid || this.modalData.otherUser.userUUID,
          meetingTitle: (this.userProfileData.org_name || this.modalData.otherUser.name) + ' <> ' + this.companyName,
          // date: {
          //   year: today.getFullYear(),
          //   month: today.getMonth(),
          //   day: today.getDate(),
          // },
          // timeFrom: startTime,
          // timeTo: endTime
        });
      }
    }
  }

  async calculateSlots(date) {

    const uid = this.modalData.otherUser.uid || this.modalData.otherUser.userUUID
    const month = ('' + date.month).length === 2 ? date.month : '0' + date.month
    const day = ('' + date.day).length === 2 ? date.day : '0' + date.day
    const selectedDateFormatted = `${date.year}-${month}-${day}`

    const dateAvailability = await this.meetingService.getUsersAvailabilityByDate(uid, selectedDateFormatted).toPromise()

    this.timeSlots = await calculateSlots({
      date,
      brandDetails: this.brandDetails,
      calendar:this.calendar,
      calenderAvilablity: this.calenderAvilablity,
      dateAvailability,
      timeSlots:this.timeSlots
    })
    console.log('this.timeSlots',this.timeSlots)
  }

  onSubmit(): void {
    const payload: IMeetingPatchModel = {
      ...this.meetingForm.value,
      meetingTimeType: MeetingTime.schedule_later,
    }
    const { date } = this.meetingForm.value;
    payload.date = `${date.year}-${date.month}-${date.day}`
    console.log('sub', payload);

    const dateFrom = new Date(`${date.year}-${date.month}-${date.day} ${payload.timeFrom}`).toUTCString()
    const dateTo = new Date(`${date.year}-${date.month}-${date.day} ${payload.timeTo}`).toUTCString()

    const utcTimeFrom = moment(`${date.year}-${date.month}-${date.day} ${payload.timeFrom}`, 'YYYY-MM-DD HH:mm').utc()
    const sqlDateFrom = utcTimeFrom.utc().format("YYYY-MM-DD")
    const sqlTimeFrom = utcTimeFrom.utc().format("HH:mm")

    const utcTimeTo = moment(`${date.year}-${date.month}-${date.day} ${payload.timeTo}`, 'YYYY-MM-DD HH:mm').utc()
    const sqlDateTo = utcTimeTo.utc().format("YYYY-MM-DD")
    const sqlTimeTo = utcTimeTo.utc().format("HH:mm")

    console.log('sqlDateFrom', sqlDateFrom, 'sqlTimeFrom', sqlTimeFrom);
    console.log('sqlDateFrom', sqlDateTo, 'sqlTimeFrom', sqlTimeTo);


    const payloadNew = {
      ...this.meetingForm.value,
      meetingTimeType: MeetingTime.schedule_later,
      date: sqlDateFrom,
      timeFrom: sqlTimeFrom,
      timeTo: sqlTimeTo,
      offset: '' + new Date().getTimezoneOffset(),
      timeZone: moment.tz.guess(),
    }
    // new
    this.ngxLoaderService.start();
    this.meetingService.createMeeting(payloadNew).subscribe((response) => {
      if (response) {
        this.toastAlertService.showToast(response.message);
        this.close();
        this.ngxLoaderService.stop();
      }
    }, err => {
      this.ngxLoaderService.stop();
    })
  }

  // handleDurationChange(event)  {
  //   let today = new Date();
  //   // today.setHours(today.getHours() + (+event))
  //   today = add(today, {
  //     minutes: +event
  //   })
  //   const endTime = today.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);


  //   this.meetingForm.patchValue({
  //       timeTo: endTime
  //   })
  //   console.log('event', event, this.meetingForm.value)
  // }


  handleDurationChange() {
    let today = new Date();
    const fromTime = this.meetingForm.value.timeFrom;


    // today.setHours(today.getHours() + (+event))
    today.setHours(fromTime.split(':')[0])
    today.setMinutes(fromTime.split(':')[1])
    today = add(today, {
      minutes: +this.meetingForm.value.duration
    })
    const endTime = today.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);


    this.meetingForm.patchValue({
      timeTo: endTime
    })
    console.log('event', this.meetingForm.value)
  }


  close() {
    this.modal.dismissAll();
  }


  getReviewers() {
    this.mileStoneService.getReviewrs().pipe(tap((res: any) => {
      if (res) {
        this.reviewers = res || [];
        this.reviewers = this.reviewers.map((e) => {
          return {
            label: e.otherUser.name,
            value: e.otherUser.uuid,
            accountType: e.otherUser.accountType
          }
        })
        console.log('connectionItems', this.reviewers);
      }
    }, (err) => { })).subscribe();
  }

  handleMeetingWithChange(event) {
    console.log('selectedMeetingWithUser', this.selectedMeetingWithUser)
    this.modalData.otherUser = {
      name: this.selectedMeetingWithUser.label,
      userUUID: this.selectedMeetingWithUser.value
    }
    this.getCompanyDetails()
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
