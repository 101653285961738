<div class="landing-header bg-thin-light" data-kt-sticky="true" data-kt-sticky-name="landing-header">
  <div class="container">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <a routerLink="/">
          <!-- <img alt="Logo" src="assets/v2/media/logos/logo-2.svg" class="logo-default h-25px h-lg-50px" />
          <img alt="Logo" src="assets/v2/media/logos/logo-2.svg" class="logo-sticky h-20px h-lg-40px" /> -->
          <app-logo-renderer class="logo-default h-25px h-lg-50px" ></app-logo-renderer>
          <app-logo-renderer class="logo-sticky h-20px h-lg-40px" ></app-logo-renderer>
        </a>
      </div>
      <div class="d-lg-block" id="kt_header_nav_wrapper">
        <input class="side-menu2" type="checkbox" id="side-menu2" />
        <label class="hamb" for="side-menu2"><span class="hamb-line"></span></label>

        <nav class="nav">
          <ul class="menu2">
            <li>
              <a routerLink="/startups" id="home_menu" (click)="trackOnMixPanel('home_menu')"
                routerLinkActive="active">Home</a>
            </li>



            <li>
              <div ngbDropdown container="body">
                <a ngbDropdownToggle class="menu-link nav-link"> Our Ecosystem </a>
                <div ngbDropdownMenu
                  class="menu menu-sub menu-sub-dropdown menu-column menu-rounded  menu-state-bg-light-primary fw-bold w-250px py-3">
                  <div class="menu-item px-3" id="startups_menu" (click)="trackOnMixPanel('startups_menu')">
                    <a routerLink="/startups" ngbDropdownItem class="menu-link px-3" routerLinkActive="active">
                      Startups
                    </a>
                  </div>
                  <div class="menu-item px-3" id="investors_menu" (click)="trackOnMixPanel('investors_menu')">
                    <a routerLink="/investors" ngbDropdownItem class="menu-link px-3" routerLinkActive="active">
                      Investors
                    </a>
                  </div>
                  <div class="menu-item px-3" id="corporate_menu" (click)="trackOnMixPanel('corporate_menu')">
                    <a routerLink="/corporates" ngbDropdownItem class="menu-link px-3" routerLinkActive="active">
                      Corporates
                    </a>
                  </div>
                  <div class="menu-item px-3" id="partners_menu" (click)="trackOnMixPanel('partners_menu')">
                    <a routerLink="/partners" ngbDropdownItem class="menu-link px-3" routerLinkActive="active">
                      Partners
                    </a>
                  </div>
                </div>
              </div>
            </li>


            <li class="position-relative">
              <a routerLink="/search/jobs" id="job-search" (click)="trackOnMixPanel('job-search_menu')"
                routerLinkActive="active">
                <span>
                  Jobs
                </span>
                <img src="assets/images/new_3.gif" class="logo-default position-absolute new-logo" />
              </a>
            </li>
            <li class="position-relative me-5">
              <a routerLink="/search/challenges" id="challenge" (click)="trackOnMixPanel('challenge-search_menu')"
                routerLinkActive="active">
                <span>
                  Open Innovation
                </span>
                <img src="assets/images/new_3.gif" class="logo-default position-absolute new-logo" />
              </a>
            </li>

            <li>
              <div ngbDropdown container="body">

                <a ngbDropdownToggle class="menu-link nav-link"> Resources </a>

                <div ngbDropdownMenu
                  class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px py-3">

                  <div class="menu-item px-3" id="glossary_menu" (click)="trackOnMixPanel('glossary_menu')">
                    <a routerLink="/glossary" ngbDropdownItem class="menu-link px-3">
                      Glossary
                    </a>
                  </div>
                  <div class="menu-item px-3" id="resources_menu" (click)="trackOnMixPanel('resources_menu')">
                    <a routerLink="/resources" ngbDropdownItem class="menu-link px-3">
                      Contracts & Legal Templates
                    </a>
                  </div>


                  <div class="menu-item px-3" id="government-grants" (click)="trackOnMixPanel('government-grants')">
                    <a routerLink="/government-grants" ngbDropdownItem class="menu-link px-3">
                      Government Grants
                    </a>
                  </div>


                  <div class="menu-item px-3" id="faqs_menu" (click)="trackOnMixPanel('faqs_menu')">
                    <a routerLink="/faqs" ngbDropdownItem class="menu-link px-3">
                      FAQs
                    </a>
                  </div>
                  <div class="menu-item px-3" id="resources_menu" (click)="trackOnMixPanel('startup-kit_menu')">
                    <a routerLink="/startup-kit" ngbDropdownItem class="menu-link px-3">
                      Startup Booster Kit
                    </a>
                  </div>


                </div>
              </div>
            </li>

            <li>
              <a href="https://sanchiconnect.com/blog" id="blog_menu" (click)="trackOnMixPanel('blog_menu')"
                target="_blank" routerLinkActive="active">Blog</a>
            </li>

            <li class="w-100 d-block d-sm-none">

              <div class="separator mt-3 opacity-75 mb-5"></div>


              <div class="row">
                <div class="col-6">
                  <a routerLink="/auth/login"
                    class="btn btn-light bg-white bg-hover-white shadow-sm ls-2 text-uppercase fw-boldest me-3 ms-5"
                    id="login_button" (click)="trackOnMixPanel('login_button')">Login</a>
                </div>
                <div class="col-6">
                  <a routerLink="/auth/register" class="btn btn-theme text-white ls-2 text-uppercase fw-boldest"
                    id="signup_button" (click)="trackOnMixPanel('signup_button')">Sign Up</a>
                </div>
              </div>
            </li>
          </ul>
        </nav>
        <div class="overlay hamb"></div>
      </div>

      <div class="d-flex d-none d-md-block flex-wrap justify-content-between ms-5 text-center">
        <app-user-profile-menu [isPublicHeader]="true" *ngIf="profileData"></app-user-profile-menu>
        <ng-container *ngIf="!profileData">
          <a routerLink="/auth/login"
            class="btn btn-light bg-white bg-hover-white shadow-sm ls-2 text-uppercase fw-boldest me-3 ms-5"
            id="login_button" (click)="trackOnMixPanel('login_button')">Login</a>
          <a routerLink="/auth/register" class="btn btn-theme text-white ls-2 text-uppercase fw-boldest"
            id="signup_button" (click)="trackOnMixPanel('signup_button')">Sign Up</a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
