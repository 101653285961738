<swiper [config]="config" class="mySwiper">
  <ng-template [ngIf]="!investorSlider" [ngIfElse]="investor">
    <ng-template *ngFor="let data of data" swiperSlide>
      <div [ngClass]="parentDivClass">
        <div
          [ngClass]="childDivClass"
          ngbTooltip="{{ data.name }}"
          placement="top"
          container="body"
        >
          <img
            [src]="data.logo | imagekitUrlAppender"
            [class]="data.imgClass"
            alt=""
          />
        </div>
      </div>
    </ng-template>
  </ng-template>

  <ng-template #investor>
    <ng-template *ngFor="let data of data" swiperSlide>
      <div class="mx-5 img-100">
        <div class="position-relative border-dark-light card-rounded">
          <!--begin::Overlay-->
          <div class="overlay overlay-show">
            <!--begin::Image-->
            <div
              class="bgi-no-repeat bgi-position-center bgi-size-cover min-h-320px card-rounded"
              [style.background-image]="
                'url(' + (data.logo | imagekitUrlAppender) + ')'
              "
            ></div>
            <!--end::Image-->
          </div>
          <!--end::Overlay-->
          <!--begin::Heading-->
          <div
            class="position-absolute text-white w-100 bottom-0 light-gradient px-5 py-5"
          >
            <!--begin::Title-->
            <h3 class="text-white fs-1qx fw-bolder mb-0 pb-0">
              {{ data.name }}
            </h3>
            <!--end::Title-->
            <!--begin::Text-->
            <!-- <div class="fs-8 fw-bold">Co Founder, Flipkart</div> -->
            <!--end::Text-->
          </div>
          <!--end::Heading-->
        </div>
      </div>
    </ng-template>
  </ng-template>
</swiper>
