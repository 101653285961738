import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { GlobalService } from 'src/app/core/service/global.service';
import { getBrandDetails } from 'src/app/core/state/global';

@Component({
  selector: 'app-meetings-and-events',
  templateUrl: './meetings-and-events.component.html',
  styleUrls: ['./meetings-and-events.component.scss']
})
export class MeetingsAndEventsComponent implements OnInit {
	activeMeetingsTab = 1;
  @Input() calenderData: any;
  eventData = []
  isNewEvent = false
  brandDetails: Partial<IBrandDetails>;

  constructor(
    private globalService: GlobalService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
        if(this.brandDetails?.features?.events) {
          this.getPlatformEvents()
        }
      }
    })
  }

  getPlatformEvents() {
    this.globalService.getPlatformEvents().subscribe(res => {
      if(res) {
        this.eventData = res;
        this.isNewEvent =  this.eventData.find((e)=> {
          return !e.checkIfResponseExists?.responseAction
        })
      }
    })
  }

}
