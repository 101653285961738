
import { Component, ViewChild, OnInit, ComponentFactoryResolver, ApplicationRef, Injector, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CdkPortal, DomPortalHost } from '@angular/cdk/portal';

/**
 * This component template wrap the projected content
 * with a 'cdkPortal'.
 */

@Component({
  selector: 'window',
  template: `
    <ng-container *cdkPortal>
      <ng-content></ng-content>
    </ng-container>
  `
})
export class WindowComponent implements OnInit, OnDestroy {

  @Input() selectedUrlDetails = '';
  @Output() closed = new EventEmitter();
  // STEP 1: get a reference to the portal
  @ViewChild(CdkPortal) portal: CdkPortal;

  // STEP 2: save a reference to the window so we can close it
  private externalWindow: Window = null;



  // STEP 3: Inject all the required dependencies for a PortalHost
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector) { }


  ngOnInit() {
    // this.externalWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=https://www.iconfinder.com/search?q=whatsapp', '', 'width=600,height=400,left=200,top=200');

    this.externalWindow = window.open(this.selectedUrlDetails, '', 'width=600,height=400,left=200,top=200');
    const timer = setInterval(() => {
      if (this.externalWindow.closed) {
        clearInterval(timer);
        this.closed.emit()
      }
    }, 1000);


    // STEP 5: create a PortalHost with the body of the new window document
    // const host = new DomPortalHost(
    //   this.externalWindow.document.body,
    //   this.componentFactoryResolver,
    //   this.applicationRef,
    //   this.injector
    //   );

    // // STEP 6: Attach the portal
    // host.attach(this.portal);
  }

  handleShareOption() {

  }

  ngOnDestroy() {
    // STEP 7: close the window when this component destroyed
    this.externalWindow.close()
  }
}
