<div class="modal-header">
  <h2 class="text-dark fw-bold fs-1 fs-4qx text-center">
    Share
  </h2>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>


<div class="modal-body px-10 pt-3 pb-4">
  <div class=" w-100">

    <div class="fs-5 rounded bg-secondary border desc-wrapper py-4 px-5 my-4">
      {{details.title}}
    </div>

  </div>
  <div class="d-flex justify-content-between align-items-center my-5">
    <button class="btn btn-dark text-white ls-2  border-radius " (click)="handleCopyTitle()">
      Copy to Clipboard
    </button>

    <div>
      <app-share-links [hideCopyButton]="true" [details]="details"></app-share-links>
    </div>
  </div>
</div>
