<!--begin::Card-->
<div class="card">
  <!--begin::Card body-->
  <div class="text-left">
    <!--begin::Overlay-->
    <div class="d-block overlay">
      <!--begin::Image-->
      <div
        class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded mb-2"
        style="height: 266px;background-image:url({{ s3logoUrl }})"
      ></div>

      <div class="mb-minus-80">
        <h3 class="fw-bolder text-white fs-2x mb-4 text-truncate">
          {{ startup.companyName }}
        </h3>

        <div class="row w-100">
          <div class="col-2 text-truncate text-white w-100 fs-6 mb-6 fw-normal">
            {{ industriesNames }}
          </div>
        </div>
      </div>
      <!--begin::Action-->
      <div class="overlay-layer card-rounded bg-dark bg-opacity-25 text-none">
        <a href="#" class="btn btn-theme text-white ls-2 text-uppercase fw-boldest">Compare</a>
      </div>
      <!--end::Action-->
    </div>
    <!--end::Overlay-->
    <!--begin::Info-->
    <div class="d-flex align-items-center p-5">
      <!--begin::Title-->
      <div class="col-md-4 col-xxl-4">
        <div class="symbol symbol-65px symbol-circle me-3">
          <!-- <ngx-avatars name="{{(startup.user.name | slice:0:1)}}" size="60" *ngIf="!avtarUrl">
          </ngx-avatars>
          <div class="symbol symbol-60px" *ngIf="avtarUrl">
            <img alt="Logo" src="{{avtarUrl}}" style="border-radius: 100%;" />
          </div> -->
        </div>
      </div>
      <div class="col-md-8 col-xl-8 col-xxl-8">
        <span
          class="fw-bolder text-gray-800 cursor-pointer text-hover-primary fs-4 d-block text-truncate"
        >
          <!-- {{startup.user.name |titlecase}} -->
        </span>
        <span class="text-gray-400 mt-1 fw-bolder fs-6">
          <!-- {{ (startup.user.accountRole || 'NA') | titlecase}} -->
        </span>
      </div>
      <!--end::Title-->
    </div>
    <div class="separator separator-dashed my-3"></div>
    <!--end::Info-->
    <div class="card-body d-flex justify-content-between flex-column px-5">
      <!--begin::Content-->
      <div class="mb-0" *ngIf="visibleFieldsSettings.productStage">
        <div class="text-gray-800 fs-4">Product Stage</div>
        <div class="d-flex align-items-center mb-2 pt-3">
          <span class="badge badge-primary fs-3 me-5">{{
            startup.productInformation?.productStage?.name ?? "-"
          }}</span>
        </div>
      </div>
      <!--end::Content-->
      <!--begin::Content-->
      <div class="mb-0" *ngIf="visibleFieldsSettings.targetFundraiser">
        <!--begin::Separator-->
        <div class="separator separator-dashed my-5 mb-5"></div>
        <!--end::Separator-->
        <div class="text-gray-800 fs-4">
          Target fundraise for upcoming round (in {{brandDetails?.features?.currency}})
        </div>
        <div class="text-gray-800 fs-2 fw-bolder">
          {{ startup.financials?.targetFundraise ?? "" }}
        </div>
        <!--begin::Separator-->
        <div class="separator separator-dashed my-5 mb-5"></div>
        <!--end::Separator-->
        <div class="text-gray-800 fs-4">Total Addressable Market (TAM)</div>
        <div class="text-gray-800 fs-2 fw-bolder">
          {{ startup.productInformation?.totalAddressableMarket ?? "" }}
        </div>
      </div>
      <!--end::Content-->

      <!--begin::Content-->
      <div class="mb-0" *ngIf="visibleFieldsSettings.traction">
        <!--begin::Separator-->
        <div class="separator separator-dashed my-5 mb-5"></div>
        <!--end::Separator-->
        <div class="text-gray-800 fs-4">Traction</div>
        <div class="text-gray-800 fs-2 fw-bolder">
          {{ startup.productInformation?.traction ?? "-" }}
        </div>
      </div>
      <!--end::Content-->

      <!--begin::Content-->
      <div class="mb-0" *ngIf="visibleFieldsSettings.pastFunding">
        <!--begin::Separator-->
        <div class="separator separator-dashed my-5 mb-5"></div>
        <!--end::Separator-->
        <div class="text-gray-800 fs-4">Past Funding</div>
        <div class="text-gray-800 fs-2 fw-bolder">
          {{ startup.financials?.pastFunding ?? "None" }}
        </div>
      </div>
      <!--end::Content-->

      <!--begin::Content-->
      <div *ngIf="visibleFieldsSettings.customerFocus">
        <!--begin::Separator-->
        <div class="separator separator-dashed my-5 mb-5"></div>
        <!--end::Separator-->
        <div class="text-gray-800 fs-4">Customer Focus</div>
        <div class="d-flex align-items-center mb-2 pt-3">
          <!--begin::Label-->
          <span class="badge badge-primary fs-3 me-5">B2C</span>
          <!--end::Label-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>

  <!--end::Card body-->
</div>
<!--end::Card-->
