import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import { createSlug } from '../../utils/common-methods';
import { ProfileService } from 'src/app/core/service/profile.service';
import { ToastAlertService } from '../../utils/toast.service';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';

@Component({
  selector: 'app-share-profile-modal',
  templateUrl: './share-profile-modal.component.html',
  styleUrls: ['./share-profile-modal.component.scss']
})
export class ShareProfileModalComponent implements OnInit {
  private destroyed$: Subject<void> = new Subject();

  details: {
    title: string,
    url: string
  };
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  profilePageUrl = '';
  globalSettings: IGlobalSettings;

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private profileService: ProfileService,
    private toastService: ToastAlertService
  ) { }

  ngOnInit(): void {
    this.details = {
      title: '',
      url: ''
    }

    this.profileService.profilePageUrl$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res: any) => {
      if (res) {
        console.log('profilePageUrl$', res)
        this.profilePageUrl = res;

        this.details.url = this.profilePageUrl


      }
    })

    this.store.pipe(select(getProfileData)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((profile) => {
      if (profile) {
        this.profileData = profile;

        if(this.profileData) {
          this.store.pipe(select(getGlobalSettings)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
            if (res) {
              this.globalSettings = res

              this.details.title = this.globalSettings.shareMessages[this.profileData.accountType]
            }
          })
        }

      }
    })
  }

  async handleCopyTitle() {
    await navigator.clipboard.writeText(
      (this.details.url || '') + ' ' + this.details.title
    )
    this.toastService.showToast('Copied to Clipboard')
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
