import { Component, Input, OnInit } from '@angular/core';
import { IDeepTechNews } from 'src/app/core/domain/webinar';
import { getFavIconUrl } from '../../utils/common-methods';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent implements OnInit {
  @Input() item: IDeepTechNews;
  constructor() { }

  ngOnInit(): void {
    const urlObj = new URL(this.item.newsUrl);

    this.item.host = urlObj.host;
    this.item.favicon = getFavIconUrl(urlObj.hostname);
  }

}
