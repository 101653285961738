import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';

@Component({
  selector: 'app-feed-form-file-select-modal',
  templateUrl: './feed-form-file-select-modal.component.html',
  styleUrls: ['./feed-form-file-select-modal.component.scss']
})
export class FeedFormFileSelectModalComponent implements OnInit {
  loading = false;
  @ViewChild("supportingFileDropRef")
  public supportingFileDropRef: ElementRef;
  readonly allowdedMimeTypes = [
    'application/rtf',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // 'image/png',
    // 'image/jpg',
    // 'image/jpeg',
  ]
  readonly MAX_FILE_SIZE = 25; //in mb
  selectedFile;
  modalRef: NgbModalRef;
  @Input() multiple = false;

  constructor(
    private modalService: NgbModal,
    private toastAlertService: ToastAlertService,
  ) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }


  supportingFileBrowseHandler(event: any) {
    const files = event.target.files;
    console.log('supportingFileBrowseHandler', files)
    const filesToUpload = files[0];
    if (!filesToUpload) {
      // this.selectedSupportingFiles = [];
      this.selectedFile = undefined;
      // this.isEditing = undefined;
    } else {
      this.supportingFileDroppedHandler(this.multiple?files : [filesToUpload]);
    }
  }

  supportingFileDroppedHandler(files) {
    const isValid = this.validateFiles(files);
    if (isValid) {
      this.selectedFile = this.multiple ? files:files[0];
      this.handleSubmit()
    }
  }

  validateFiles(files: File[]) {
    try {
      for (const file of files) {
        this.fileSizeValidation(file)
        this.fileTypeValidation(file)
      }
      return true;
    } catch (error) {
      this.toastAlertService.showToast(error.message, 'error');
      return false;
    }
  }

  fileTypeValidation(file: File) {
    if (!file.type || !this.allowdedMimeTypes.includes(file.type)) {
      throw new Error('Allowded File type are ppt, pptx');
    }
  }

  fileSizeValidation(file: File) {
    const sizeInMB = Number((file.size / (1024 * 1024)).toFixed(2));
    console.log({ sizeInMB })
    if (sizeInMB > this.MAX_FILE_SIZE) {
      throw new Error('File size should be less than 25mb');
    }
  }


  handleSubmit() {
    this.modalRef.close({data: this.selectedFile})
  }
}
