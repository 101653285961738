import { PartnersInformation } from '../domain/partners.model';
import { ApiEndpointService } from './api-endpoint.service';


import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  map,
  tap
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
// import { ApiEndpointService } from "./api-endpoint.service";
import * as ProfileActions from "src/app/core/state/profile/profile.action";
import * as CorporateDashboardAction from 'src/app/core/state/corporate-dashboard/corporate.dashboard.action';
import { getProfileData } from "../state/profile";
import { IProfileDetails } from "../domain/profile.model";
import { GlobalService } from "./global.service";
import { IAddPartnerStartup, PartnerInformationModel } from '../domain';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
interface ContactFormInfo {
  name: string,
  emailAddress: string,
  mobileNumber: string,
  companyName: string,
  website: string
}

@Injectable({
  providedIn: 'root'
})
export class MilestonesService {
  profileData: IProfileDetails;

  constructor(private http: HttpClient, private toastService: ToastAlertService, private store: Store, private globalService: GlobalService,
    private router: Router) {
    // this.store.pipe(select(getProfileData))
    //   .pipe(distinctUntilChanged()).subscribe((res) => {
    //     if (res) {
    //       this.profileData = res;
    //     }
    //   })
  }


  public getMilestones(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES);
    return this.http.get(url).pipe(
      map((response: any): PartnersInformation[] => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching milestones list', 'error');
        return throwError(() => fault);
      })
    )
  }

  public deleteMilestone(id: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id;
    return this.http.delete(url).pipe(
      map((response: any): PartnersInformation[] => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while deleting milestone', 'error');
        return throwError(() => fault);
      })
    )
  }

  public getReviewrs(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + 'reviewers';
    return this.http.get(url).pipe(
      map((response: any): PartnersInformation[] => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching milestones list', 'error');
        return throwError(() => fault);
      })
    )
  }

  public getMilestoneById(id: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/info';
    return this.http.get(url).pipe(
      map((response: any): PartnersInformation[] => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching milestones list', 'error');
        return throwError(() => fault);
      })
    )
  }



  public toggleNotification(id: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/toggle-notification';
    return this.http.patch(url, {}).pipe(
      map((response: any): PartnersInformation[] => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching milestones list', 'error');
        return throwError(() => fault);
      })
    )
  }


  public qualitativeMarkComplete(id: string, qualitativeId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + `/qualitative/${qualitativeId}/completed`;
    return this.http.patch(url, {}).pipe(
      map((response: any): PartnersInformation[] => {
        this.toastService.showToast('Milestone marked completed');
        return response
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching milestones list', 'error');
        return throwError(() => fault);
      })
    )
  }


  public quantitativeMilestoneUpdate(id: string, quantitativeMilestoneUUID, value): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + `/quantitative/${quantitativeMilestoneUUID}/update-value`;
    return this.http.patch(url, { value }).pipe(
      map((response: any): PartnersInformation[] => {
        this.toastService.showToast('Milestone updated');
        return response
        // return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching milestones list', 'error');
        return throwError(() => fault);
      })
    )
  }


  public createMilestones(payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES);
    return this.http.post(url, payload).pipe(
      map((response: any) => {
        this.toastService.showToast('Milestone created');
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching milestones list', 'error');
        return throwError(() => fault);
      })
    )
  }

  public updateTargetDate(id: string, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/target-date';
    return this.http.patch(url, payload).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error updating milestone', 'error');
        return throwError(() => fault);
      })
    )
  }

  public updateReviewers(id: string, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/reviewers';
    return this.http.put(url, payload).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error updating milestone', 'error');
        return throwError(() => fault);
      })
    )
  }

  public uploadFile(id, files: File[]): Observable<any> {
    const payload = new FormData();
    for (const file of files) {
      payload.append('files', file);
    }
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/notes/upload-files';
    return this.http.post(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading file'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public createNote(id,payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/notes';
    return this.http.post(url, payload).pipe(
      tap(()=> {
        this.toastService.showToast('Note created');
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating note!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public updateNote(id,noteId,payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/notes/'+noteId;
    return this.http.patch(url, payload).pipe(
      tap(()=> {
        this.toastService.showToast('Note updated');
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating note!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public getNotes(id): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/notes';
    return this.http.get(url).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating note!'), 'error');
        return throwError(() => fault);
      })
    );
  }
  public getMessages(id): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/messages';
    return this.http.get(url).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating note!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public createMessage(id,payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/messages';
    return this.http.post(url, payload).pipe(
      tap(()=> {
        // this.toastService.showToast('Note created');
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating note!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public deleteMessage(id,messageId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/messages/'+messageId;
    return this.http.delete(url).pipe(
      tap(()=> {
        // this.toastService.showToast('Note created');
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while deleting message!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public deleteNote(id,messageId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MILESTONES) + id + '/notes/'+messageId;
    return this.http.delete(url).pipe(
      tap(()=> {
        // this.toastService.showToast('Note created');
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while deleting note!'), 'error');
        return throwError(() => fault);
      })
    );
  }

}
