import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SliderData } from '../SliderData';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, SwiperOptions } from "swiper";
// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

@Component({
  selector: 'app-swiper-slider',
  templateUrl: './swiper-slider.component.html',
  styleUrls: ['../slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SwiperSliderComponent implements OnInit {

  @Input() public data: SliderData[] = [];
  @Input() public slideDisplay: number = 0;
  @Input() public parentDivClass: string = "";
  @Input() public childDivClass: string = "";
  @Input() public investorSlider: boolean = false;
  public config: SwiperOptions = {};
  constructor() {
  }

  ngOnInit(): void {
    this.config = {
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      centerInsufficientSlides: true,
      pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 1 },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          noSwipingClass: '',
          noSwiping: false,
          longSwipes: false,
          shortSwipes: true,
          noSwipingSelector: ''
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 3,
          noSwipingClass: '',
          noSwiping: false,
          longSwipes: false,
          shortSwipes: true,
          noSwipingSelector: ''
        },
        // when window width is >= 640px
        640: {
          slidesPerView: this.slideDisplay,
          noSwipingClass: 'swiper-no-swiping',
          noSwiping: true,
          longSwipes: false,
          shortSwipes: false,
          noSwipingSelector: 'swiper',
        }
      }
    }
  }

}
