<!--begin::List Widget 4-->
<div class="card h-xl-100">
  <!--begin::Header-->
  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label fw-bolder text-dark fs-1">{{title}}</span>
    </h3>
  </div>
  <!--end::Header-->
  <!--begin::Body-->
  <div class="card-body pt-5">
    <!--begin::Item-->
    <ng-template [ngIf]="updatedList && updatedList.length > 0" [ngIfElse]="empty">
      <div class="d-flex align-items-sm-center mb-7" *ngFor="let data of updatedList">
        <!--begin::Symbol-->
        <div class="symbol symbol-50px me-5">
          <ngx-avatars *ngIf="!data.investor.organizationLogo" bgColor="#E9843E" initialsSize="2" size="50" [name]="data.investor.organizationName"></ngx-avatars>
          <ngx-avatars *ngIf="data.investor.organizationLogo" size="50" cornerRadius="4" [round]="false" [src]="data.investor.organizationLogo | imagekitUrlAppender" [name]="data.investor.organizationName"></ngx-avatars>
        </div>
        <!--end::Symbol-->
        <!--begin::Section-->
        <div class="d-flex align-items-center flex-row-fluid flex-wrap">
          <div class="flex-grow-1 me-2">
            <a [routerLink]="data.investor.profilePageUrl" class="text-gray-800 text-hover-primary fs-4 fw-bolder">{{data.investor.organizationName}}</a>
            <span class="text-muted fw-bold d-block fs-7">
              {{data.startupName}}
            </span>
            <span class="text-muted fw-bold d-block fs-7">
              {{data.investmentAmount}} ({{data.round}})
            </span>
          </div>
        </div>
        <!--end::Section-->
      </div>
    </ng-template>
    <ng-template #empty>
      <div class="col-md-12 col-xl-12 col-xxl-12 mt-3">
        <div class="alert alert-primary text-center" role="alert">
          No results found
        </div>
      </div>
    </ng-template>
    <!--end::Item-->
  </div>
  <!--end::Body-->
</div>
<!--end::List Widget 4-->
