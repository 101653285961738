import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';

@Component({
  selector: 'app-reject-connection-modal',
  templateUrl: './reject-connection-modal.component.html',
  styleUrls: ['./reject-connection-modal.component.scss']
})
export class RejectConnectionModalComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() msgText: string = '';
  @Input() applyBtnText: string = "Ok";
  @Input() cancelBtnText: string = "Cancel";
  @Input() maxCharLimit: number = 300;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();

  ACCOUNT_TYPE = ACCOUNT_TYPE;
  message: string;

  messageRejectOptions;

  rejectOptions: Array<{ label?: string, value: string }> = [


    {
      label: 'Other reason',
      value: 'Other', // check conditions,
    }
  ];



  constructor(
    public activeModal: NgbActiveModal,
    private readonly store: Store,
  ) {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        console.log({ res })
        this.profileData = res;
        if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
          this.rejectOptions.unshift({
            value: 'Not sure if I would be relevant here' // only mentor
          },)
          this.rejectOptions.unshift({
            value: 'I have a conflict of interest' // only mentor
          })
        }


        else if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR || this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
          this.rejectOptions.unshift({
            value: 'Sector mismatch'
          },)
          this.rejectOptions.unshift({
            value: 'Stage mismatch'
          })
          if(this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {
            this.rejectOptions.unshift({
              value: 'Business case or market size not compelling enough' // only mentor
            })
          }
        }else{
          this.messageRejectOptions = 'Other'
          console.log('messageRejectOptions', this.messageRejectOptions)
        }
      }
    })
  }

  ngOnInit(): void {
    this.message = this.msgText;
  }

  onApplyBtnClick(): void {
    let message = this.messageRejectOptions === 'Other' ? this.message : this.messageRejectOptions;
    if (this.profileData.accountType !== ACCOUNT_TYPE.INVESTOR) {
      message = this.message
    }
    this.activeModal.close(message);
  }

  closeModal(): void {
    this.activeModal.close(null);
  }


  get isDisabled() {
    // if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {
    return this.messageRejectOptions === 'Other' ? !this.message : !this.messageRejectOptions;
    // }
    // return !this.message;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
