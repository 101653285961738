<ng-template
  [ngIf]="counts.sentConnectionCount || counts.pendingConnectionCount"
>
  <ul class="list-group">
    <li
      class="list-group-item d-flex justify-content-between align-items-center py-6 fs-3 cursor-pointer"
      *ngIf="counts.sentConnectionCount"
      [routerLink]="'/connections/sent-requests'"
    >
      Sent Connections
      <span class="badge badge-dark fw-bold badge-circle p-4 fs-4">{{
        counts.sentConnectionCount
      }}</span>
    </li>

    <li
      class="list-group-item d-flex justify-content-between align-items-center py-6 fs-3 cursor-pointer"
      *ngIf="counts.pendingConnectionCount"
      [routerLink]="'/connections/pending-requests'"
    >
      Connections Received
      <span class="badge badge-dark fw-bold badge-circle p-4 fs-4">{{
        counts.pendingConnectionCount
      }}</span>
    </li>
  </ul>
</ng-template>
