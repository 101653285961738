import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { MixpanelService } from 'src/app/core/service/mixpanel.service';
import { getProfileData } from 'src/app/core/state/profile';

@Component({
  selector: 'app-public-layout-header-v2',
  templateUrl: './public-layout-header-v2.component.html',
  styleUrls: ['./public-layout-header-v2.component.scss']
})
export class PublicLayoutHeaderV2Component implements OnInit, OnDestroy {
  pageScrolled: boolean = false;
  showMobileNavBar: string = "off";
  currentPosition = window.pageYOffset;


  private destroyed$: Subject<void> = new Subject();

  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;

  // @ViewChild(OffcanvasSidebarComponent) offcanvasSidebarComponent: OffcanvasSidebarComponent;
  // public menuList: Array<OffCanvasMenu>;

  constructor(
    private readonly store: Store,
    private mixPanel: MixpanelService
  ) { }

  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      console.log({ res })
      if (res) {
        this.profileData = res;
      } else {
        this.profileData = undefined;
      }
    })
  }


  trackOnMixPanel(id: string) {
    console.log('event', event);
    this.mixPanel.track('click', {
      element: id
    });
  }


  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  open() {
    // this.offcanvasSidebarComponent.openNav();
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll(e: any) {
    let scroll = e.target['scrollingElement'].scrollTop;

    if (scroll > this.currentPosition) {
      this.pageScrolled = true;
    }
    this.currentPosition = scroll;
    if (this.currentPosition == 0) {
      this.pageScrolled = false;
      this.showMobileNavBar = "off";
    } else {
      this.showMobileNavBar = "on";
    }
  }

}
