import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { InvestorProfileCompleteness, InvestorTypes, OrganizationInformationResponseModel } from 'src/app/core/domain';
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';
import { getIndividualInvestorProfileCompleteness, getInvestorProfileCompleteness, providingFundRequestFault, providingFundRequestSuccess } from 'src/app/core/state/investor-dashboard';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import Swal from 'sweetalert2';
import { getInvestorsOrg } from 'src/app/core/state/investors-organization-info';
import { createSlug } from '../../utils/common-methods';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareProfileModalComponent } from '../share-profile-modal/share-profile-modal.component';
import { getBrandDetails } from 'src/app/core/state/global';
import { IBrandDetails } from 'src/app/core/domain/brand.model';

@Component({
  selector: 'app-investor-providing-funds-switch',
  templateUrl: './investor-providing-funds-switch.component.html',
  styleUrls: ['./investor-providing-funds-switch.component.scss']
})
export class InvestorProvidingFundsSwitchComponent implements OnInit, OnDestroy {


  public profileCompleteness: InvestorProfileCompleteness;
  private destroyed$: Subject<void> = new Subject();
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  InvestorTypes = InvestorTypes;
  missingFields = [];
  investorInfo: any;
  investorProfilePageUrl=''
  @Input() showShareFeedback = true;
  @Input() showEditProfileBtn = true;

  brandDetails: Partial<IBrandDetails>;

  constructor(
    private route: ActivatedRoute,
    private readonly store: Store,
    private toastAlertService: ToastAlertService,
    private router: Router,
    public modal: NgbModal,
  ) {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
  }

  ngOnInit(): void {
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
        this.getProfileCompleteness();
      }
    })
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res;
      }
    })
    this.store.pipe(select(providingFundRequestSuccess)).pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe(res => {
      if (res) {
        this.toastAlertService.showToast(res);
      }
    })

    this.store.pipe(select(providingFundRequestFault)).pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe(res => {
      if (res) {
        this.toastAlertService.showToast(res, 'error');
      }
    });

    this.store.pipe(select(getInvestorsOrg)).subscribe((response: OrganizationInformationResponseModel) => {
      if (response) {
        this.investorInfo = response;
        this.investorProfilePageUrl = `/investors/profile/${this.investorInfo.data.uuid}/${createSlug(this.investorInfo.data.organizationName)}`;
      }
    });

  }

  getProfileCompleteness() {
    if (this.profileData.investorType === InvestorTypes.ORGANIZATION || this.profileData.investorType === InvestorTypes.SYNDICATE) {
      this.store.dispatch(new InvestorDashboardAction.GetProfileCompleteness());
      this.store.pipe(select(getInvestorProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
        if (res) {
          this.profileCompleteness = res;
          this.getMissingFields();
        }
      })
    } else {
      this.store.dispatch(new InvestorDashboardAction.GetIndividualProfileCompleteness());
      this.store.pipe(select(getIndividualInvestorProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
        if (res) {
          this.profileCompleteness = res;
          this.getMissingFields();
        }
      })
    }
  }


  getMissingFields = () => {
    this.missingFields = [];

    Object.keys(this.profileCompleteness.forms).forEach((e: string) => {
      this.missingFields = [...this.missingFields, ...(this.profileCompleteness.forms[e].missingFields || [])]
    })
  }


  onToggleChange($event) {
    this.store.dispatch(new InvestorDashboardAction.ProvidingFundRequest);
  }


  checkAndShowModalForSubmitProfile() {
    if (this.profileCompleteness.percentage >= 100 && !this.profileCompleteness.isApprovalRequested && !this.profileCompleteness.canToggleStatus
      && !this.profileCompleteness.isRejected && !this.profileCompleteness?.isApproved) {
      Swal.fire({
        title: 'Awesome! your profile is ready to go live.',
        text: 'Submit to start connecting with startups.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        confirmButtonText: 'Submit',
        showDenyButton: true,
        denyButtonText: 'Preview',
        denyButtonColor: '#000',
        allowOutsideClick: false,
        allowEscapeKey: false,
        preDeny: () => {
          const slug = this.investorInfo.data.organizationName.split(' ').join('-').toLocaleLowerCase();

          const url = this.router.serializeUrl(this.router.createUrlTree(['/investors/profile/' + this.investorInfo.data.uuid, slug]));
          window.open(url, '_blank');
          return false;
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.store.dispatch(new InvestorDashboardAction.SendRequestApproval());
          setTimeout(() => {
            this.router.navigate(['/investors/dashboard']);
          }, 1500);

        } else {
          if (result.isDenied) {
            return false;
          } else {
            localStorage.setItem('approval-modal', 'hide');
          }
        }
      });
    }
  }


  handleShareButton() {
    this.modal.open(
      ShareProfileModalComponent, { centered: true, size: 'lg' }
    )
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get isProfileCompleted ( ) {
    return this.profileCompleteness?.percentage >= 100 && this.profileCompleteness?.isApproved;
  }


  get isLimitedAccess() {
    return this.profileCompleteness?.isRejected && this.brandDetails?.features?.limited_access && this.profileCompleteness?.approvalStatus === 'limited_access'
  }
}
