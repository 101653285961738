import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {


  @Input() title = 'No Data Found';

  options: AnimationOptions = {
    path: '/assets/lottie/no-search-result.json',
  };


  constructor() { }

  ngOnInit(): void {
  }

}
