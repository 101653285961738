<ng-container>
  <div class="card card-flush sticky-top ">
    <div class=" bg-primary rounded bgi-no-repeat bgi-size-cover justify-content-center d-flex flex-column h-260px"
    *ngIf="showOwnProfile">
      <h3 class="card-title align-items-center flex-column text-white d-flex flex-column mt-8">


        <div class="symbol symbol-75px ">
          <img alt="userPhoto" class="bg-white shadow-sm" src="https://placehold.co/100x100/000/FFFFFF/png"
            *ngIf="!s3LogoUrl" />
          <img class="bg-white shadow-sm" alt="userPhoto" [src]="s3LogoUrl | imagekitUrlAppender" *ngIf="s3LogoUrl" />
        </div>


        <span class="fw-bolder fs-2 mb-3 mt-3 cursor-pointer"
          [routerLink]="'/community-feed/posts/user/'+profileData.uuid">{{userDetails?.name ||
          profileData?.name}}</span>

      </h3>
      <!-- *ngIf="showOwnProfile" -->
      <button class="btn btn-sm btn-light w-fit mx-auto ls-2 text-uppercase fw-boldest btn-active-light-primary"

        (click)="openProfile()">
        View profile
      </button>


      <!-- <div class="row text-white mt-3 mx-8" *ngIf="!showOwnProfile">
        <div class="col-4 d-flex flex-column justify-content-center align-items-center cursor-pointer"
          [routerLink]="'/community-feed/posts/user/'+profileData.uuid">
          <p class="fs-1 fw-bolder mb-0">{{stats.totalPost}}</p>
          <p>Posts</p>
        </div>

        <div class="col-4 d-flex flex-column justify-content-center align-items-center cursor-pointer"
          [routerLink]="'/community-feed/posts/me/comments'">
          <p class="fs-1 fw-bolder mb-0">{{stats.totalComment}}</p>
          <p>Comments</p>
        </div>

        <div class="col-4 d-flex flex-column justify-content-center align-items-center cursor-pointer"
          [routerLink]="'/community-feed/posts/me/reactions'">
          <p class="fs-1 fw-bolder mb-0">{{stats.totalPostReaction}}</p>
          <p>Reactions</p>
        </div>
      </div> -->
    </div>

    <!-- *ngIf="!showOwnProfile" -->
    <div class="card-body p-0 py-3 px-2 shadow-sm">
      <div class=" position-relative">
        <div class="row ">

          <div class="col-12">
            <div class="rounded-2 ps-4 pe-1 py-1 cursor-pointer"
              [routerLink]="'/community-feed/posts/user/'+profileData.uuid">

              <div class="m-0 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <span class=" "><i class="bi bi-pencil-square text-black fs-3" [ngClass]="{
                      'text-primary fw-bolder': (userId === profileData.uuid)
                    }"></i>
                  </span>
                  <span class="text-dark fs-4 ms-3 d-flex justify-content-between align-items-center" [ngClass]="{
                    'text-primary fw-bolder': (userId === profileData.uuid)
                  }">My Posts
                  </span>
                </div>
                <!-- <p class="fs-1 fw-bolder mb-0">{{stats.totalPost}}</p> -->
                <span class="badge badge-secondary  fs-4 ">
                  {{stats.totalPost}}
                </span>

              </div>
            </div>

            <div class="separator separator-dashed my-3"></div>

          </div>

          <div class="col-12">
            <div class="rounded-2 ps-4 pe-1 py-1 cursor-pointer" [routerLink]="'/community-feed/posts/me/comments'">

              <div class="m-0 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <span class=" "><i class="bi bi-chat-dots text-black fs-3" [ngClass]="{
                    'text-primary fw-bolder': (type === 'comments')
                  }"></i>
                  </span>
                  <span class="text-dark fs-4 ms-3" [ngClass]="{
                  'text-primary fw-bolder': (type === 'comments')
                }">My Commented Posts</span>
                </div>
                <span class="badge badge-secondary  fs-4 ">
                  {{stats.totalComment}}
                </span>
              </div>
            </div>

            <div class="separator separator-dashed my-3"></div>

          </div>


          <div class="col-12">
            <div class="rounded-2 ps-4 pe-1 py-1 cursor-pointer" [routerLink]="'/community-feed/posts/me/reactions'">

              <div class="m-0 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <span class=" "><i class="bi bi-hand-thumbs-up text-black fs-3" [ngClass]="{
                    'text-primary fw-bolder': (type === 'reactions')
                  }"></i>
                  </span>
                  <span class="text-dark fs-4 ms-3" [ngClass]="{
                  'text-primary fw-bolder': (type === 'reactions')
                }">My Reacted Posts</span>
                </div>
                <span class="badge badge-secondary  fs-4 ">
                  {{stats.totalPostReaction}}
                </span>

                <!-- <p class="fs-1 fw-bolder mb-0">{{stats.totalPostReaction}}</p> -->

              </div>
            </div>

            <!-- <div class="separator separator-dashed mt-4"></div> -->

          </div>


          <!-- <div class="col-12">
            <div class="bg-gray-100 bg-opacity-70 rounded-2 px-6 ps-4 pe-1 py-5">

              <div class="m-0 d-flex align-items-center">
                <span class=" "><i class="bi bi-chat-dots text-black fs-3"></i>
                </span>
                <span class="text-dark fs-7 ms-3">My Comments</span>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="bg-gray-100 bg-opacity-70 rounded-2 px-6 ps-4 pe-1 py-5">

              <div class="m-0 d-flex align-items-center">
                <span class=" "><i class="bi bi-hand-thumbs-up text-black fs-3"></i>
                </span>
                <span class="text-dark fs-7 ms-3">My Liked Posts</span>
              </div>
            </div>
          </div> -->

          <!-- <div [routerLink]="count.link" class="col-12" *ngFor="let count of countBoxes">
            <div class="bg-gray-100 bg-opacity-70 rounded-2 px-6 ps-4 pe-1 py-5">

              <div class="m-0 d-flex align-items-center">
                <span class=" "><i class="bi bi-chat-dots text-black fs-3"></i>
                </span>
                <span class="text-dark fs-7 ms-3">{{count.name}}</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</ng-container>
