<!-- <button class="btn btn-theme text-white ls-2 text-uppercase fw-boldest w-150px me-4 btn-sm" (click)="handleCreateClick()">Set Meeting</button> -->

<!-- <div ngbDropdown class="menu-item" container="body">
    <a ngbDropdownToggle class="menu-link nav-link  p-0">
        <svg enable-background="new 0 0 32 32" height="28px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="28px"
            xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path
                d="M30,6h-0.887c-0.525,0-1.029,0.207-1.404,0.576L25,9.248V8c0-1.657-1.344-3-3-3H3  C1.346,5,0,6.345,0,8v6.972V24c0,1.656,1.343,3,3,3h19c1.656,0,3-1.344,3-3v-1.221l2.709,2.672c0.375,0.369,0.879,0.576,1.404,0.576  H30c1.104,0,2-0.895,2-2V8C32,6.895,31.104,6,30,6z M3,25c-0.552,0-1-0.449-1-1V8c0-0.553,0.447-1,1-1h19c0.551,0,1,0.448,1,1v16  c0,0.551-0.449,1-1,1H3z M30,24.027h-0.887H29l-4-4V20l-1-1v-6l5-5h0.113H30V24.027z"
                fill="#E9843E" id="video" />
        </svg>
    </a>
    <div ngbDropdownMenu
        class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px py-3">
        <div class="menu-item px-3"> -->
<div class="d-flex flex-row" *ngIf="otherUser?.uid !== this.globalSettings?.ceoUUID">
  <a
    ngbDropdownItem
    class=" btn rounded btn-light btn-sm me-3  px-1  "
    (click)="handleStartInstantMeeting()"
  >
    <span class="bi bi-camera-video-fill me-2"></span> Setup Instant meeting
  </a>
  <a ngbDropdownItem class="px-1 btn rounded btn-primary btn-sm" (click)="handleCreateClick()">
    <span class="bi bi-calendar-date  me-2"></span> Schedule meeting
  </a>
</div>
<!-- </div>
    </div>
</div> -->
