<ngx-ui-loader></ngx-ui-loader>

<!-- <div class="card h-md-100">

  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label fw-bolder text-dark fs-3">Upcoming Meetings</span>
    </h3>
  </div>
  <div class="card-body pt-5 px-0">
    <div class="tab-content mb-2 px-9">

      <ng-template [ngIf]="calenderData && calenderData.length > 0" [ngIfElse]="noFound">
        <a class="d-flex align-items-center mb-6 cursor-pointer" target="_blank" *ngFor="let data of calenderData"
        (click)="handleClick(data)"
          >
          <div class="position-relative me-5">
            <p class="calendar mb-0 pb-0">{{data.dateFormatted}} <em>{{data.month}}</em></p>
          </div>

          <div class="flex-grow-1 me-5">
              <div class="text-gray-800 fw-bold fs-3">
                {{data.meetingTitle}}

                <span *ngIf="data.proposedDate"
                class="bi bi-exclamation-circle fs-3 me-3 text-primary ms-2"
                triggers="mouseenter"
                placement="bottom"
                [ngbTooltip]="'Re-schedule requested'"
              >
              </span>
              </div>
             <div class="text-gray-500 fw-bold fs-5" >
              {{meetingTypeLabel[data.meetingType]}}
            </div>

            <div class="text-gray-700 fw-bold fs-7 mt-2">
              {{data.formattedTimeFrom}} -
              {{data.formattedTimeTo}}
            </div>
          </div>
        </a>



        <a [routerLink]="'/calender'" class="text-center btn-outline border-secondary w-100 btn mt-5 text-hover-primary">
          View Calender
        </a>

      </ng-template>
      <ng-template #noFound>
        <div class="d-flex align-items-sm-center mb-7">
          <div class="alert alert-primary alert-width-100 text-center" role="alert">
            No meetings found
          </div>
        </div>
      </ng-template>

      <ng-template #modalContent let-close="close">
         <app-meeting-details-modal [modalData]="modalData"></app-meeting-details-modal>
        <app-meeting-details-modal (refreshData)="getMeetings()" [modalData]="modalData" (editEvent)="editEvent()"  (rescheduleEvent)="rescheduleEvent()" (deleteEvent)="deleteEvent()"></app-meeting-details-modal>
      </ng-template>


      <ng-template #modalEditMeeting let-close="close">
        <app-edit-meeting-event (refreshData)="getMeetings()" [isRescheduling]="isRescheduling" [modalData]="modalData" ></app-edit-meeting-event>
      </ng-template>

      <ng-template #modalDeleteMeeting let-close="close">
        <app-delete-meeting-event (refreshData)="getMeetings()" [modalData]="modalData" ></app-delete-meeting-event>
      </ng-template>



    </div>
  </div>
</div> -->

<div class="tab-content">

  <ng-template [ngIf]="calenderData && calenderData.length > 0" [ngIfElse]="noFound">
    <a  target="_blank" *ngFor="let data of calenderData"
    (click)="handleClick(data)"
      >
      <div class="d-flex align-items-center cursor-pointer">
        <div class="position-relative me-5">
          <p class="calendar mb-0 pb-0">{{data.dateFormatted}} <em>{{data.month}}</em></p>
        </div>

        <div class="flex-grow-1 me-5">
            <div class="text-gray-800 fw-bold fs-3">
              <div class="d-flex justify-content-between align-items-center">
                {{data.meetingTitle}}
              </div>

              <span *ngIf="data.proposedDate"
              class="bi bi-exclamation-circle fs-3 me-3 text-primary ms-2"
              triggers="mouseenter"
              placement="bottom"
              [ngbTooltip]="'Re-schedule requested'"
            >
            </span>
            </div>
          <div class="text-gray-500 fw-bold fs-5" *ngIf="data.meetingType==='job-interview'" >
          <ng-container *ngIf="profileData.accountType===ACCOUNT_TYPE.STARTUP">
            {{data.jobApplication?.applicantName}}
          </ng-container>
          <ng-container *ngIf="profileData.accountType!==ACCOUNT_TYPE.STARTUP">
            {{data.user?.startup?.companyName}}
          </ng-container>
          </div>

          <div class="text-gray-700 fw-bold fs-7 mt-2">
            {{data.formattedTimeFrom}} -
            {{data.formattedTimeTo}}
          </div>
        </div>
        <div class="d-flex flex-column justify-content-end align-content-end" *ngIf="data.afterInterviewStatus">
          <span class="fs-8 text-right" style="text-align: right;">
            Result:
          </span>
          <div class="badge badge-secondary bg-light text-gray-400 p-2">
                      {{ InterviewAfterStatusLabel[data.afterInterviewStatus] || 'No status' }}
          </div>
        </div>
      </div>
      <div class="separator separator-dashed mt-4 mb-4"></div>
    </a>



    <a *ngIf="viewCalender" [routerLink]="'/calender'" class="text-center btn-outline border-secondary w-100 btn mt-5 text-hover-primary">
      View Calender
    </a>

  </ng-template>
  <ng-template #noFound>
    <!-- <div class="d-flex align-items-sm-center">
      <div class="alert alert-primary alert-width-100 text-center" role="alert">
        No meetings found
      </div>
    </div> -->
    <div class="text-center mt-10 mb-10">
      <i class="bi bi-exclamation-circle" style="font-size: 60px;">
      </i><br><br>
      <span class="fs-5 text-center my-4  text-gray-500">
        No meetings found
      </span>
    </div>
  </ng-template>


  <ng-template #modalContent let-close="close">
    <!-- <app-meeting-details-modal [modalData]="modalData"></app-meeting-details-modal> -->
    <app-meeting-details-modal (refreshData)="getMeetings()" [modalData]="modalData" (editEvent)="editEvent()"  (rescheduleEvent)="rescheduleEvent()" (deleteEvent)="deleteEvent()"></app-meeting-details-modal>
  </ng-template>


  <ng-template #modalEditMeeting let-close="close">
    <app-edit-meeting-event (refreshData)="getMeetings()" [isRescheduling]="isRescheduling" [modalData]="modalData" ></app-edit-meeting-event>
  </ng-template>

  <ng-template #modalDeleteMeeting let-close="close">
    <app-delete-meeting-event (refreshData)="getMeetings()" [modalData]="modalData" ></app-delete-meeting-event>
  </ng-template>



</div>
