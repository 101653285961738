import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { IInvestorSearchResponse } from 'src/app/core/domain/search.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getInvestorsToCompare } from 'src/app/core/state/compare';
import { getBrandDetails, getGlobalSettings } from 'src/app/core/state/global';
import * as CompareActions from 'src/app/core/state/compare/compare.action';
import { Router } from '@angular/router';
import { MAX_COMPARISION } from 'src/app/shared/constants/constants';
import { getProfileData } from 'src/app/core/state/profile';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { ProfileService } from 'src/app/core/service/profile.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-investors-search-card',
  templateUrl: './investors-search-card.component.html',
  styleUrls: ['./investors-search-card.component.scss']
})
export class InvestorsSearchCardComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<void> = new Subject();

  @Input('investor') investor: IInvestorSearchResponse;
  public globalSettings$: Observable<IGlobalSettings>;

  globalSettings: IGlobalSettings;
  @Input('disableHover') disableHover: boolean = false;
  @Input('avtarSize') avtarSize: 'small' | 'large' = 'large';
  @Input('isShowCompareButton') isShowCompareButton: boolean = true;
  @Input() openedFrom: 'search' | 'recommended' = 'search';
  @Output() hideRecommendedInvestor = new EventEmitter()
  brandDetails: Partial<IBrandDetails>;

  s3logoUrl = '';

  avtarUrl = '';
  isInvestorInCompareList = false;
  isMaxComparableItemsSelected = false;

  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;

  constructor(
    private store: Store,
    private router: Router,
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
      }
    })
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
      }
    })
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
        if (this.investor.organizationLogo) {
          this.s3logoUrl = res.imgKitUrl + this.investor.organizationLogo + '?tr=w-300,h-300,cm-pad_resize';
        } else {
          this.s3logoUrl = res.assetsImgKitUrl + '/images/no-logo-uploaded.png' + '?tr=w-200,h-200,cm-pad_resize';
        }
        if (this.investor.user.avatar) {
        this.avtarUrl = res.imgKitUrl + this.investor.user.avatar + '?tr=w-300,h-300,cm-pad_resize';
        }
      }
    });
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.store.select(getInvestorsToCompare).pipe(
      takeUntil(this.destroyed$),
      distinctUntilChanged()
    ).subscribe((list) => {
      if (list) {
        this.isInvestorInCompareList = list.includes(this.investor.uuid);
        this.isMaxComparableItemsSelected = list.length >= MAX_COMPARISION;
      }
    })
  }

  addToCompare($event: Event) {
    $event.stopPropagation();
    this.store.dispatch(new CompareActions.AddInvestorToCompare(this.investor.uuid));
  }

  removeFromCompare($event: Event) {
    $event.stopPropagation();
    this.store.dispatch(new CompareActions.RemoveInvestorFromCompareList(this.investor.uuid));
  }


  openDetailsView() {
    if (this.profileData && this.profileService.isProfileLocked) {
      Swal.fire({
        title: 'Unauthorized Access',
        text: 'To access this profile, you need to have your profile approved.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        confirmButtonText: 'Ok'
      }).then((result) => { });
      return;
    }

    const slug = this.investor.organizationName.split(' ').join('-').toLocaleLowerCase();
    const url = this.router.serializeUrl(this.router.createUrlTree(['/investors/profile/' + this.investor.uuid, slug]));
    window.open(url, '_blank');
  }

  hideInvestor($event: Event) {
    $event.stopPropagation();
    this.hideRecommendedInvestor.emit(this.investor.uuid)
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


}
