<div class="footer-section mb-0">

  <div class="landing-dark-bg pt-20">

    <div class="container">

      <div class="row py-10">

        <div class="col-lg-4 mb-10 mb-lg-0">

          <div class="mb-10">

            <div class="d-flex align-items-center flex-equal mb-10">

              <a href="#">
                <app-logo-renderer class="logo-default h-40px h-lg-50px"></app-logo-renderer>
              </a>

            </div>


            <span class="fw-normal fs-6 text-gray-400">We are a specialized community for DeepTech startups, Investors,
              Enterprise Partners and Service Providers.<br />
              <br />
              Your request to access the platform is subject to a review
              first.</span>


            <div class="py-5">
              <div class="d-flex flex-wrap">
                <a target="_blank" href="https://www.facebook.com/sanchiconnects"
                  class="me-5 social_icon wow fadeInLeft" data-wow-delay="0.25s">
                  <img [lazyLoad]="'/images/facebook.svg' | assetsImagekitUrlAppender" [useSrcset]="true" width="100%"
                    alt="" /></a>

                <a target="_blank" href="https://www.linkedin.com/company/80556478/"
                  class="me-5 social_icon wow fadeInLeft" data-wow-delay="0.75s">
                  <img [lazyLoad]="'/images/linkedin.svg'| assetsImagekitUrlAppender" [useSrcset]="true" width="100%"
                    alt="" /></a>
                <a target="_blank" href="https://twitter.com/sanchiconnect" class="me-5 social_icon wow fadeInLeft"
                  data-wow-delay="1s">
                  <img [lazyLoad]="'/images/twitter.svg' | assetsImagekitUrlAppender" [useSrcset]="true" width="100%"
                    alt="" /></a>
              </div>
            </div>
          </div>

        </div>


        <div class="col-lg-4 ps-lg-16">

          <div class="d-flex justify-content-left">

            <div class="d-flex fw-bold flex-column me-20">
              <h4 class="fw-bolder text-gray-400 mb-6">Company</h4>

              <a routerLink="/about-us" class="text-white opacity-50 text-hover-primary fs-5 mb-6">About us</a>

              <a href="https://sanchiconnect.com/blog" target="_blank"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6">Blog</a>

              <a routerLink="/contact-us" class="text-white opacity-50 text-hover-primary fs-5 mb-6">Contact Us</a>
            </div>


            <div class="d-flex fw-bold flex-column ms-lg-20">

              <h4 class="fw-bolder text-gray-400 mb-6">Resources</h4>

              <a routerLink="/glossary" class="text-white opacity-50 text-hover-primary fs-5 mb-6">Glossary</a>

              <a routerLink="/resources" class="text-white opacity-50 text-hover-primary fs-5 mb-6">Contracts & Legal Templates</a>

              <a routerLink="/government-grants" class="text-white text-hover-primary fs-5 mb-6">Government Grants</a>

              <a routerLink="/faqs" class="text-white opacity-50 text-hover-primary fs-5 mb-6">FAQs</a>

              <a routerLink="/startup-kit" class="text-white opacity-50 text-hover-primary fs-5 mb-6">Startup Booster Kit</a>

            </div>

          </div>

        </div>


        <div class="col-lg-4 ps-lg-16">
          <div class="d-flex justify-content-left">

            <div class="d-flex fw-bold flex-column ms-lg-20">
              <h4 class="fw-bolder text-gray-400 mb-6">Legal</h4>
              <a [routerLink]="'/page/1/privacy'" class="text-white opacity-50 text-hover-primary fs-5 mb-6">Privacy
                Policy</a>
              <a [routerLink]="'/page/3/terms-conditions'"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6">Terms and Conditions</a>
              <a [routerLink]="'/page/4/cookie-policy'"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6">Cookie Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="container">

      <div class="landing-dark-separator"></div>


      <div class="flex-column text-center flex-md-row flex-stack py-7 py-lg-10">

        <div class="align-items-center">
          <span class="mx-5 fs-6 fw-bold text-gray-600 pt-1">Copyright @ {{ currentYear }} SanchiConnect.com. All rights
            reserved</span>
          <br />
          <span class="mx-5 fs-6 fw-bold text-gray-600 pt-1">SanchiConnect is a product of Sanchi Techstarter Private
            Limited
          </span>

        </div>

      </div>

    </div>

  </div>

</div>



<div id="kt_scrolltop" class="scrolltop" (click)="scrollToTop()">

  <span class="svg-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
      <path
        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
        fill="currentColor" />
    </svg>
  </span>

</div>
