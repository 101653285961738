import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { USER_TYPE_STARTUP } from '../../constants/startup-invetor-constants';

import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import * as InvestorCompareActions from '../../../core/state/investor-compare';
import { getInvestorCompareDashboard, getInvestorCompareDashboardError } from '../../../core/state/investor-compare';
import * as StartupCompareActions from '../../../core/state/startup-compare';
import { getStartupCompareDashboard, getStartupCompareDashboardError } from '../../../core/state/startup-compare';
import { ToastAlertService } from '../../utils/toast.service';
import { ActivatedRoute } from '@angular/router';
import { IStartUpInfoFetch } from 'src/app/core/domain/startup.model';

@Component({
  selector: 'app-startup-investor-compare',
  templateUrl: './startup-investor-compare.component.html',
  styleUrls: ['./startup-investor-compare.component.scss'],
})
export class StartupInvestorCompareComponent implements OnInit, OnDestroy {

  @Input() userType: string;

  public productStage: boolean = true;
  public customerFocus: boolean = true;
  public businessModel: boolean = true;
  public targetFundraiser: boolean = true;
  public traction: boolean = true;
  public pastFunding: boolean = true;

  idsToCompare = [];

  startupComparisionList: IStartUpInfoFetch[] = [];

  private destroyed$: Subject<void> = new Subject();


  visibleFieldsSettings = {
    productStage: true,
    customerFocus: true,
    businessModel: true,
    targetFundraiser: true,
    traction: true,
    pastFunding: true,
  }


  constructor(
    private readonly store: Store,
    private toastAlertService: ToastAlertService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      console.log({ params }, params.comparing)
      this.idsToCompare = params.comparing.split(',');
      this.getCompareData();
    })
  }

  getCompareData = () => {
    if (this.userType === USER_TYPE_STARTUP) {
      const ids = this.idsToCompare;
      this.store.dispatch(new StartupCompareActions.GetStartupCompare(ids.toString()));
      this.store.pipe(select(getStartupCompareDashboard)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
        if (res) {
          this.startupComparisionList = res.data;
        }
      })


      this.store.pipe(select(getStartupCompareDashboardError)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(error => {
        if (error) {
          this.toastAlertService.showToast(error, 'error')
        }
      })
    } else {
      const ids = this.idsToCompare;
      this.store.dispatch(new InvestorCompareActions.GetInvestorCompare(ids.toString()));
      this.store.pipe(select(getInvestorCompareDashboard)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
        if (res) {
          console.log("res", res);
        }
      })
      this.store.pipe(select(getInvestorCompareDashboardError)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(error => {
        if (error) {
          this.toastAlertService.showToast(error, 'error')
        }
      })
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
