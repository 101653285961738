<div
  aria-labelledby="swal2-title"
  aria-describedby="swal2-html-container"
  *ngIf="isNotDelete == false"
  (click)="closeModal()"
  class="swal2-popup swal2-modal swal2-icon-warning swal2-show mx-auto"
  tabindex="-1"
  role="dialog"
  aria-live="assertive"
  aria-modal="true"
  style="display: grid"
>
  <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex">
    <div class="swal2-icon-content">!</div>
  </div>
  <div
    class="swal2-html-container"
    id="swal2-html-container"
    style="display: block"
  >
    Are you sure you would like to delete this meeting?
  </div>

  <div class="swal2-actions" style="display: flex">
    <div class="swal2-loader"></div>
    <button
      type="button"
      class="swal2-confirm btn btn-theme text-white ls-2 text-uppercase fw-boldest"
      aria-label=""
      style="display: inline-block"
      (click)="onDeleteMeeting()"
    >
      Yes, delete it!</button
    ><button
      type="button"
      class="swal2-deny"
      aria-label=""
      style="display: none"
    >
      No
    </button>
    <button
      type="button"
      class="swal2-cancel btn btn-active-light"
      aria-label=""
      style="display: inline-block"
      (click)="closeModal()"
    >
      No, return
    </button>
  </div>
</div>
