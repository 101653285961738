<div
  class="d-flex flex-column flex-root"
  id="kt_body"
  [attr.data-kt-sticky-landing-header]="showMobileNavBar"
  [attr.data-kt-landing-header]="showMobileNavBar"
  [attr.data-kt-scrolltop]="showMobileNavBar"
  class="bg-white position-relative"
>
  <app-public-layout-header-v2></app-public-layout-header-v2>
  <router-outlet></router-outlet>
  <app-public-layout-footer-v2></app-public-layout-footer-v2>
</div>
