import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: "fullWebsiteURL" })
export class FullWebsiteURLPipe implements PipeTransform {
    transform(url: string) {
        if (!url) {
            return url;
        }
        if (url.includes('http://')) {
            url = url.replace('http://', 'https://');
        }
        else if (!url.includes('http://') && !url.includes('https://')) {
            url = 'https://' + url;
        }
        return url;
    }
}