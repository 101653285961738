import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-limited-access-message-box',
  templateUrl: './limited-access-message-box.component.html',
  styleUrls: ['./limited-access-message-box.component.scss']
})
export class LimitedAccessMessageBoxComponent implements OnInit {

  options = [
    'Opportunity to apply for all future accelerator programs by us and our partners',
    'Engage with larger community using community wall',
    'Business innovation challenges hosted by partners',
    'Startup booster kit (limited)',
    'Contracts & Legal',
    'Templates',
    'Glossary',
    'Reports',
    'News',
    'Videos',
  ]
  criterias = [
    'IP/ Patented technology',
    'Commercialization feasibility',
    'Product',
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
