import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE, InvestorTypes, OrganizationInformationResponseModel } from 'src/app/core/domain';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { GlobalService } from 'src/app/core/service/global.service';
import { getCorporateProfileCompleteness } from 'src/app/core/state/corporate-dashboard/corporate.dashboard.selectors';
import { getBrandDetails } from 'src/app/core/state/global';
import { getIndividualInvestorProfileCompleteness, getInvestorProfileCompleteness } from 'src/app/core/state/investor-dashboard';
import { getMentorProfileCompleteness } from 'src/app/core/state/mentors-dashboard/mentors.dashboard.selectors';
import { getNotificationsCount } from 'src/app/core/state/notifications';
import { NotificationsCount } from 'src/app/core/state/notifications/notifications.model';
import { getProfileData } from 'src/app/core/state/profile';
import { getServiceProviderProfileCompleteness } from 'src/app/core/state/service-provider-dashboard/service-provider.dashboard.selectors';
import { getStartupProfileCompleteness } from 'src/app/core/state/startup-dashboard';
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard";
import * as CorporateDashBoardActions from "src/app/core/state/corporate-dashboard/corporate.dashboard.action";
import * as MentorDashBoardActions from "src/app/core/state/mentors-dashboard/mentors.dashboard.action";
import * as ProgramOfficeDashBoardActions from "src/app/core/state/program-office-dashboard/program-office.dashboard.action";
import * as ServiceProviderDashBoardActions from "src/app/core/state/service-provider-dashboard/service-provider.dashboard.action";
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';
import { MentorshipService } from 'src/app/core/service/mentorship.service';
import { getCorporateInfo } from 'src/app/core/state/corporate-info';
import { getInvestorsOrg } from 'src/app/core/state/investors-organization-info';
import { getMentorInfo } from 'src/app/core/state/mentor-info';
import { getServiceProviderInfo } from 'src/app/core/state/service-provider-info';
import { getStartUpInfo } from 'src/app/core/state/startup';
import * as InvestorsOrgInfoActionActions from "src/app/core/state/investors-organization-info/investors-organization-info.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import * as CorporateActions from "src/app/core/state/corporate-info/corporate-info.action";
import * as PartnerActions from "src/app/core/state/partner-info/partner-info.action";

import * as MentorActions from "src/app/core/state/mentor-info/mentor-info.action";
import * as ProgramOfficeActions from "src/app/core/state/program-office-members-info/program-office-members-info.action";
import * as ServiceProviderActions from "src/app/core/state/service-provider-info/service-provider-info.action";
import { createSlug } from '../../utils/common-methods';
import { getProgramOfficeProfileCompleteness } from 'src/app/core/state/program-office-dashboard/program-office.dashboard.selectors';
import { getProgramOfficeInfo } from 'src/app/core/state/program-office-members-info';
import * as IndividualActions from "src/app/core/state/individual-profile-info/individual-profile-info.action";
import { getIndividualProfileInfo } from 'src/app/core/state/individual-profile-info';

@Component({
  selector: 'app-community-feed-stats',
  templateUrl: './community-feed-stats.component.html',
  styleUrls: ['./community-feed-stats.component.scss']
})
export class CommunityFeedStatsComponent implements OnInit, OnDestroy {
  greetingMessagetext: string = "";

  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();
  countBoxes: any = [];
  counts: NotificationsCount | undefined;
  brandDetails: Partial<IBrandDetails>;
  profileCompleteness;
  ACCOUNT_TYPE = ACCOUNT_TYPE;
  profilePageUrl = ''
  constructor(
    private globalService: GlobalService,
    private readonly store: Store,
    private mentorshipService: MentorshipService
  ) { }

  ngOnInit(): void {
    this.greetingMessagetext = this.globalService.greetingMessage();

    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;


        this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
          if (res) {
            this.brandDetails = res;

            this.countBoxes = [
              {
                "name": "Connect Requests",
                "countKey": 'pendingConnectionCount',
                "icon": "bi bi-person-plus-fill fa-2x",
                "link": "/connections/pending-requests",
              },

            ]
            if(this.brandDetails.features.chat) {
              this.countBoxes.push( {
                "name": "Unread Messages",
                "countKey": 'unreadMessageCount',
                "icon": "fa fa-comments fa-2x",
                "link": "/chat/conversations",
              })
            }

            if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR || this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
              this.countBoxes.push({
                "name": "Mentor Hours",
                "countKey": 'pendingAcceptanceMentorHoursCount',
                "icon": "fa fa-clock-o fa-2x",
                "link": "/mentorship-hours",
              })
            }
            if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
              this.countBoxes.push({
                "name": "Document requests",
                "countKey": 'pendingDocumentsUploadCount',
                "icon": "fa fa-file fa-2x",
                "link": "/startups/edit/supporting-documents",
              })

              this.countBoxes.push({
                "name": "Pending Applications",
                "countKey": 'pendingProgramFormSubmissionCount',
                "icon": "fa fa-hand-holding-usd fa-2x",
                "link": "/programs/applied",
                "onlyShowIfNotZero": true
              })
            }
            if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
              this.countBoxes.push({
                "name": "Avg. Ratings",
                "countKey": 'avgRating',
                "icon": "fa fa-star fa-2x",
                "link": "/mentorship-hours",
              })
            }


          }
        })

        this.getStartupOrProfileInformation()
      }
    })


    this.store.pipe(select(getNotificationsCount)).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.counts = { ...res };

        if (this.profileData?.accountType === ACCOUNT_TYPE.MENTOR) {
          this.mentorshipService.getMentorShipStats().subscribe((res) => {
            if (res) {
              this.counts['avgRating'] = (res['avg_ratings'] || 0) + '<span class="ratings-subtitle"> of ' + res['total_ratings'] + ' ratings</span>'
            }
          })
        }

      }
    });
    this.getProfileCompleteness();

  }

  getProfileCompleteness() {
    if(!this.profileData ) {
      return
    }
    if (this.profileData?.accountType === ACCOUNT_TYPE.INVESTOR) {

      if (this.profileData.investorType === InvestorTypes.INDIVIDUAL) {
        this.store.dispatch(new InvestorDashboardAction.GetIndividualProfileCompleteness());
        this.store.pipe(select(getIndividualInvestorProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
          if (res) {
            this.profileCompleteness = res;
          }
        })
      } else {
        this.store.dispatch(new InvestorDashboardAction.GetProfileCompleteness());
        this.store.pipe(select(getInvestorProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
          if (res) {
            this.profileCompleteness = res;
          }
        })
      }

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getStartupProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
          console.log({ res })
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.dispatch(new CorporateDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getCorporateProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
      this.store.dispatch(new MentorDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getMentorProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {
      this.store.dispatch(new ProgramOfficeDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getProgramOfficeProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      this.store.dispatch(new ServiceProviderDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getServiceProviderProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    }
  }


  getStartupOrProfileInformation() {
    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {
      this.store.dispatch(new InvestorsOrgInfoActionActions.GetInvestorsOrgInfo);

      this.store.pipe(select(getInvestorsOrg)).subscribe((response: OrganizationInformationResponseModel) => {
        if (response) {
          this.profilePageUrl = `/investors/profile/` + `${response.data.uuid}/${createSlug(response.data.organizationName)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      this.store.dispatch(new StartupActions.GetStartUpInfo);

      const startupInfo$ = this.store.pipe(select(getStartUpInfo));
      startupInfo$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/startups/profile/` + `${res.uuid}/${createSlug(res.companyName)}`
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.dispatch(new CorporateActions.GetCorporateInfo);

      this.store.pipe(select(getCorporateInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/corporates/profile/` + `${res.data.uuid}/${createSlug(res.data.companyName)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
      this.store.dispatch(new MentorActions.GetMentorInfo);

      this.store.pipe(select(getMentorInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          console.log('getMentorInfogetMentorInfo')
          this.profilePageUrl = `/mentors/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {
      this.store.dispatch(new ProgramOfficeActions.ProgramOfficeInfo);

      this.store.pipe(select(getProgramOfficeInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/program-office-members/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      this.store.dispatch(new ServiceProviderActions.GetServiceProviderInfo);

      this.store.pipe(select(getServiceProviderInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          console.log('getMentorInfogetMentorInfo')
          this.profilePageUrl = `/service-provider/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.PARTNER) {
      this.store.dispatch(new PartnerActions.GetPartnerInfo);
      this.profilePageUrl = `/partners/edit/partner-information`
    } else if (this.profileData.accountType === ACCOUNT_TYPE.OTHER) {
      this.profilePageUrl = `/search/startups`
    }else if (this.profileData.accountType === ACCOUNT_TYPE.INDIVIDUAL) {
      this.store.dispatch(new IndividualActions.GetIndividualProfileInfo);

      this.store.pipe(select(getIndividualProfileInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          // this.profilePageUrl = `/individuals/edit/individual-information`
          this.profilePageUrl = `/individuals/profile/`+ `${res.data.uuid}/${createSlug(res.data.name)}`
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

}
