import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VCard, VCardEncoding, VCardFormatter } from 'ngx-vcard';

@Component({
  selector: 'app-connection-user-info-modal',
  templateUrl: './connection-user-info-modal.component.html',
  styleUrls: ['./connection-user-info-modal.component.scss']
})
export class ConnectionUserInfoModalComponent implements OnInit, OnChanges {

  @Input() personalDetails: any = {};



  vcardData;

  public vCard: VCard = {
  };
  public vCardString = '';
  public vCardEncoding: typeof VCardEncoding = VCardEncoding;
  isContactInfoVisible;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    const personalDetails = this.personalDetails
    this.vCard = {
      name: {
        firstNames: personalDetails.name,
        lastNames: ''
      },
      email: [{
        value: personalDetails.email, param: {
          type: 'work'
        }
      }],
      telephone: [{ value: '+' + personalDetails.mobileNumber, param: { type: 'cell' } }],
      organization: {
        value: personalDetails.orgName,
        param: { type: 'work' }
      }
    }

    this.vCardString = VCardFormatter.getVCardAsString(this.vCard);
  }

  ngOnChanges(changes: SimpleChanges): void {

  }


  closeModal(): void {
    this.modalService.dismissAll();
  }



}
