<div class="d-flex flex-wrap flex-center mb-20 w-100">
  <div class="row w-100">
    <ng-template [ngIf]="data.length > 0" [ngIfElse]="noFound">
      <ng-container *ngFor="let partner of data">
        <div class="col-md-6 mt-small d-md-block mb-10">
          <div class="card card-box">
            <div class="card-header">
              <!-- <img class="h-100-w-100" src="https://picsum.photos/200" /> -->
              <ngx-avatars
                *ngIf="!partner.logo"
                bgColor="var(--color-theme)"
                initialsSize="2"
                size="90"
                [name]="partner.name"
              >
              </ngx-avatars>
              <ngx-avatars
                *ngIf="partner.logo"
                size="90"
                cornerRadius="4"
                [round]="false"
                [src]="createS3LogoUrl(partner.logo) | imagekitUrlAppender"
                [name]="partner.name"
              ></ngx-avatars>
              <ul [ngStyle]="{ 'list-style-type': 'none' }">
                <li>
                  <h1>{{ partner.name }}</h1>
                </li>
                <li>
                  <i class="bi-people-fill fs-4 pe-2 text-black"></i
                  ><span class="fs-3 fw-bold">{{ partner.partnerType | titlecase}}</span>
                </li>
                <li>
                  <i class="bi bi-link-45deg fs-4 pe-2 text-black"></i
                  ><a
                    target="_blank"
                    [href]="partner.website | fullWebsiteURL"
                    class="fs-3 fw-bold"
                    (click)="trackOnMixPanel('bounce_'+ partner.website, 'website')"
                    >{{ partner.website }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="card-body card-body-padding">
              <p class="fs-5 text-dark-400">{{ partner.shortDescription }}</p>
            </div>
            <div class="card-footer" [ngStyle]="{ 'text-align': 'right' }">
              <a
                class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest border-radius"
                tabindex="0"
                (click)="routeToPartnersDetailsPage(partner)"
                id="home_banner_getstarted"
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #noFound>
      <div class="d-flex align-items-sm-center mb-7">
        <div
          class="alert alert-primary alert-width-100 text-center"
          role="alert"
        >
          No results found
        </div>
      </div>
    </ng-template>
  </div>
</div>
