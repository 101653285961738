import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { IInvestorExternalInvestments, StartupInvestorMonthlyInvest } from 'src/app/core/domain';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';
import { createSlug } from '../../utils/common-methods';

@Component({
  selector: 'app-our-investors-portfolio',
  templateUrl: './our-investors-portfolio.component.html',
  styleUrls: ['./our-investors-portfolio.component.scss']
})
export class OurInvestorsPortfolioComponent implements OnInit {

  @Input() title: string;
  @Input() data: IInvestorExternalInvestments[];

  public globalSettings$: Observable<IGlobalSettings>;
  public avatar = '';
  public s3LogoUrl: string = '';
  public globalSettings: IGlobalSettings;
  private destroyed$: Subject<void> = new Subject();

  public updatedList: IInvestorExternalInvestments[];

  constructor(private readonly store: Store) { }

  ngOnInit(): void {
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));

    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.globalSettings = res; this.createS3LogoUrl(); }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getUpdatedData();
  }

  getUpdatedData() {
    if (this.data && this.data.length > 0) {
      this.updatedList = this.data.map((row: IInvestorExternalInvestments) => {
        this.avatar = row.investor.organizationLogo;
        this.createS3LogoUrl();
        const profilePageUrl = `/investors/profile/` + `${row.investor.uuid}/${createSlug(row.investor.organizationName)}`;
        return {
          ...row,
          investor: {
            organizationName: row.investor.organizationName,
            organizationLogo: this.s3LogoUrl,
            profilePageUrl: profilePageUrl,
            uuid: row.investor.uuid
          }
        }
      })
    }
  }

  createS3LogoUrl = () => {
    if (this.globalSettings?.imgKitUrl && this.avatar) {
      this.s3LogoUrl = this.avatar + '?tr=w-100,h-100,cm-pad_resize';
    } else {
      this.s3LogoUrl = '';
    }
  }

}
