<div class="modal-header bg-light-orange border border-gray-300">
  <h4 class="modal-title">
    <a href="https://powerpitch.ai/" target="_blank">
      <img src="https://i0.wp.com/powerpitch.ai/wp-content/uploads/2023/09/Powerpitch-logo.png?w=250&ssl=1"
        class="logo-default h-25px h-lg-30px"/>
    </a>
  </h4>
  <button type="button" class="btn-close" (click)="closeModal()"></button>
</div>
<iframe
  [src]="editPageUrl | safeHtml:'resourceUrl'"
  style="width: 100%; height: 100%;border:0px; "
  allow="camera *;microphone *;clipboard-read;clipboard-write;"></iframe>
