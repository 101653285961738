<div class="modal-header">
  <h2 class="text-dark fw-bold fs-5 text-center">
    Upload a file
  </h2>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>


<div class="modal-body px-10 pt-3 pb-4">
  <div class="my-3 w-100">


    <div>
      <input type="file" #supportingFileDropRef [multiple]="false" [id]="'doc-ref'" hidden
        (change)="supportingFileBrowseHandler($event)" accept=".png, .jpg, .jpeg" />
      <!-- accept=".pdf, .doc, .docx, .rtf, .ppt, .pptx" -->
      <label [for]="'doc-ref'" appDragAndDropFileSelect
        class="btn btn-outline btn-outline-dashed text-center p-10 mb-5 w-100 bg-white"
        (fileDropped)="supportingFileDroppedHandler($event)">
        Click or Drop file in this box to upload.<br />Accepted
        formats: ppt, pptx, doc, docx, pdf

        <br />

        <span class="badge badge-secondary badge-lg ms-2 mt-4 px-5 py-4 fs-3"><i
            class="bi bi-cloud-upload text-black fs-3"></i> Upload</span>
      </label>
    </div>
    <p class="fs-6 mt-2" *ngIf="selectedFile">
      <b>Selected File:</b> {{selectedFile.name}}
    </p>



  </div>
  <div class="my-2 d-flex justify-content-end">
    <button class="btn btn-light ls-2 text-uppercase fw-boldest btn-active-light-primary " (click)="closeModal()">
      Cancel
    </button>
    <!-- <button class="btn  btn-theme text-white ls-2 text-uppercase ms-2 fw-boldest" [disabled]="!selectedFile"
      (click)="handleSubmit()">
      Select
      <span class="indicator-progress" [class.d-inline-block]="loading">
        <span class="spinner-border spinner-border-sm align-middle ms-2"> </span>
      </span>
    </button> -->
  </div>
</div>
