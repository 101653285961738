<div class="card h-xl-100">

  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label fw-bolder text-dark fs-3" [innerHTML]="title"></span>
    </h3>
  </div>


  <div class="card-body pt-5">

    <ng-template [ngIf]="data && updatedList.length > 0" [ngIfElse]="empty">
      <div class="d-flex align-items-sm-center mb-7" *ngFor="let data of updatedList" container="body"
        triggers="mouseenter:mouseleave" popoverClass="popover-custom-width">



        <div class="d-flex  flex-row-fluid flex-wrap">
          <div class="flex-grow-1 me-2">
            <a [href]="'/jobs/' + data.jobUUID + '/details'" target="_blank"
              class="text-gray-800 text-hover-primary fs-4 fw-bolder">{{data.jobTitle}}</a>



            <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
              <span class="text-gray-400 fw-semibold d-block mt-2">
                <i class="fa fa-briefcase fs-4 me-3 text-gray-400" aria-hidden="true"></i>{{ JobTypeLabels[data.jobType]
                }}
              </span>

              <span class="text-gray-400 fw-semibold d-flex ms-4 mt-2 align-items-center">
                <i class="bi bi-geo-alt fs-2 text-gray-400 me-2" aria-hidden="true"></i>
                <span class="">
                  {{ data.locations }}
                </span>
              </span>

            </div>
          </div>
        </div>


        <div class="badge badge-secondary" ngbTooltip="{{data.applicationCount}} applications">
          {{data.applicationCount}} applicants
        </div>

      </div>

      <a [href]="'/jobs'" target="_blank" class="text-center btn-outline border-secondary w-100 btn text-hover-primary">
        View more
      </a>
    </ng-template>
    <ng-template #empty>
      <div class="d-flex align-items-sm-center mb-7">
        <div class="alert alert-primary alert-width-100 text-center w-100" role="alert">
          No results found
        </div>
      </div>
    </ng-template>

  </div>

</div>
