<div class="modal-header">
  <h4 class="modal-title">Would like to pass, because</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <div>
    <div class="w-100">
      <ul
        class="list-group rounded"
        >
        <!-- *ngIf="profileData.accountType === ACCOUNT_TYPE.INVESTOR" -->
        <li
          class="list-group-item w-100 py-4"
          *ngFor="let option of rejectOptions"
        >
          <div
            class="form-check form-check-custom form-check-solid form-check-sm"
          >
            <input
              class="form-check-input"
              type="radio"
              [value]="option.value"
              id="{{ option.value }}"
              name="message"
              [(ngModel)]="messageRejectOptions"
            />
            <label
              class="form-check-label"
              style="text-align: start"
              for="{{ option.value }}"
            >
              {{ option.label || option.value }}
            </label>
          </div>
        </li>
      </ul>

      <div
        class="form-floating mb-7 w-100 mt-5"
        *ngIf="
          messageRejectOptions === 'Other'
          "
          >
          <!-- profileData.accountType !== ACCOUNT_TYPE.INVESTOR -->
        <textarea
          class="form-control"
          [(ngModel)]="message"
          placeholder="Reason"
          id=""
          rows="5"
        ></textarea>
        <label for="floatingTextarea ">Share your reason to decline</label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest"
    [disabled]="isDisabled"
    (click)="onApplyBtnClick()"
  >
    {{ applyBtnText }}
  </button>
  <button
    class="btn btn-light ls-2 text-uppercase fw-boldest btn-active-light-primary me-2"
    (click)="closeModal()"
  >
    {{ cancelBtnText }}
  </button>
</div>
