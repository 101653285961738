import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';

@Component({
  selector: 'app-twitter-widget',
  templateUrl: './twitter-widget.component.html',
  styleUrls: ['./twitter-widget.component.scss']
})
export class TwitterWidgetComponent implements OnInit, OnDestroy {

  public globalSettings$: Observable<IGlobalSettings>;
  public globalSettings: any;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private readonly store: Store
  ) { }

  ngOnInit(): void {
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));

    // Get Global Settings
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
      }
    });
  }
  extractTwitterName() {
    let tw = this.globalSettings?.socialLinks?.twitter || ''
    const splitted = tw.split('/');
    return splitted[splitted.length-1];
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }
}
