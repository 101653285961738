import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Observable, distinctUntilChanged, takeUntil } from 'rxjs';
import { ICalenderEventModal } from 'src/app/core/domain/meetings.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { getProfileData } from 'src/app/core/state/profile';
import 'add-to-calendar-button';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { createSlug } from 'src/app/shared/utils/common-methods';
import { ProfileService } from 'src/app/core/service/profile.service';
import Swal from 'sweetalert2';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { getBrandDetails } from 'src/app/core/state/global';

@Component({
  selector: 'app-meeting-details-modal',
  templateUrl: './meeting-details-modal.component.html',
  styleUrls: ['./meeting-details-modal.component.scss']
})
export class MeetingDetailsModalComponent implements OnInit, OnDestroy, OnChanges {

  @Input() modalData: {
    action: string;
    event: ICalenderEventModal;
  };

  @Input() isRescheduling = false;

  @Output() editEvent = new EventEmitter()
  @Output() rescheduleEvent = new EventEmitter()
  @Output() deleteEvent = new EventEmitter()
  @Output() refreshData = new EventEmitter<any>();
  @Output() onFollowUp = new EventEmitter()

  private destroyed$: Subject<void> = new Subject();
  public profileDetails$: Observable<IProfileDetails>;
  profileDetails: IProfileDetails;
  meetingNote = ''
  editNoteMode = false
  ACCOUNT_TYPE = ACCOUNT_TYPE
  brandDetails: IBrandDetails;

  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private meetingService: MeetingService,
    private profileService: ProfileService,
    private router: Router,
    private readonly store: Store,
    private uiLoader: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {
    console.log('modalData', this.modalData)
    this.profileDetails$ = this.store.pipe(select(getProfileData));
    this.profileDetails$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.profileDetails = res; }
    })

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res as IBrandDetails
    }})
    this.handleFormatProposedTime()

    if (this.modalData) {
      this.modalData = JSON.parse(JSON.stringify(this.modalData))
      let participant = this.modalData.event?.receiver
      let user = this.modalData.event?.user
      if (participant) {
        participant.profileUrl = this.profileService.getProfileLink(participant.companyUUID || (participant as any).startup?.uuid, participant.companyName || participant?.startup?.companyName, participant.accountType)
      }
      if (user) {
        user.profileUrl = this.profileService.getProfileLink(user.companyUUID, user.companyName, user.accountType)
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleFormatProposedTime()
  }

  handleFormatProposedTime() {
    const eventData = this.modalData.event
    if (this.modalData.event.proposedDate) {

      const proposedGMTTime = moment.utc(`${eventData.proposedDate} ${eventData.proposedStartTime}`).local();

      const toGMTTime = moment.utc(`${eventData.proposedDate} ${eventData.proposedEndTime}`).local();



      this.modalData.event.proposedTimeFrom = moment(proposedGMTTime).format('hh:mm a')
      this.modalData.event.proposedTimeTo = moment(toGMTTime).format('hh:mm a')
    }
  }



  get meetingUrl() {
    const event: any = this.modalData.event
    const link = this.router.serializeUrl(this.router.createUrlTree(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid]));
    return window.location.origin + link
  }
  meetingDetail(event) {
    this.closeModal();
    // this.router.navigate(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid])
    const link = this.router.serializeUrl(this.router.createUrlTree(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid]));
    window.open(link, '_blank');
  }

  handleReschedule() {
    console.log('event', this.modalData)
    this.rescheduleEvent.emit(this.modalData)
  }

  closeModal(): void {
    this.modal.dismissAll();
  }


  tConvert(time, date) {
    return time
  }


  handleDeleteEvent() {
    this.deleteEvent.emit(this.modalData)
    // this.closeModal();
    // this.modal.open(this.modalDeleteContent);
  }

  handleEditEvent(acceptReschedule = false): void {
    this.editEvent.emit({ data: this.modalData, acceptReschedule })
    // this.modal.open(this.modalEditContent, { size: 'mw-650px' });
  }

  handleCreateFollowup(acceptReschedule = false): void {
    this.onFollowUp.emit({ data: this.modalData, acceptReschedule })
  }

  handleAccept() {
    // this.handleEditEvent(true)
    this.uiLoader.start()
    this.meetingService.proposeAction(this.modalData.event.uuid, {
      action: 'accepted',
    }).subscribe((res) => {
      this.closeModal()
      this.uiLoader.stop()
      this.refreshData.emit()
    }, err => {
      this.uiLoader.stop()
    })
  }

  async handleReject() {
    const { value: text, isDenied, isDismissed } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Rejection Message (Optional)',
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false
    })

    if (isDismissed || isDenied) {
      return
    }

    // if (text) {
    //   Swal.fire(text)
    // }

    this.uiLoader.start()
    this.meetingService.proposeAction(this.modalData.event.uuid, {
      action: 'rejected',
      rejectionMessage: text
    }).subscribe((res) => {
      this.closeModal()
      this.uiLoader.stop()

      this.refreshData.emit()
    }, err => {
      this.uiLoader.stop()
    })
  }

  formatTo24Hour(timeAMPM) {
    const time24Hour = moment(timeAMPM, 'h:mm A').format('HH:mm');
    return time24Hour
  }


  get isMeetingExpired() {
    const date = moment.utc(`${this.modalData?.event.date} ${this.modalData?.event.timeFrom}`).local()
    return moment(date).isSameOrBefore(moment())
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onEditNote() {
    this.editNoteMode = true
    this.meetingNote = this.modalData.event.meetingsNotes?.[0]?.notes
  }

  onUpdateNote() {
    this.meetingService.saveMeetingNotes(this.modalData.event.uuid, {
      notes: this.meetingNote
    }).subscribe(() => {
      this.editNoteMode = false
      if (!this.modalData.event.meetingsNotes || !this.modalData.event.meetingsNotes[0]) {
        this.modalData.event.meetingsNotes = [{ notes: '' }] as any
      }
      this.modalData.event.meetingsNotes[0].notes = this.meetingNote
    })
  }

  handleAcceptMeetingRequest() {
    this.uiLoader.start()
    this.meetingService.handleAcceptMeetingRequest(this.modalData.event.uuid).subscribe(() => {
      this.uiLoader.stop()
      this.closeModal()
    }, err => {
      this.uiLoader.stop()
    })
  }


  async handleRejectMeetingRequest() {
    const { value: text, isDenied, isDismissed } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Rejection Message (Optional)',
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false
    })

    if (isDismissed || isDenied) {
      return
    }

    this.uiLoader.start()
    this.meetingService.handleRejectMeetingRequest(this.modalData.event.uuid, {
      rejectionReason: text
    }).subscribe(() => {
      this.uiLoader.stop()
      this.closeModal()
    }, err => {
      this.uiLoader.stop()
    })
  }

  async onShareNote() {
    const { value: text, isDenied, isDismissed, isConfirmed } = await Swal.fire({
      title: 'Are you sure to share the notes with other party?',
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonColor: 'var(--color-theme)',
      cancelButtonColor: '#7D8185',
    })

    if (isConfirmed) {
      this.uiLoader.start()
      this.meetingService.shareMeetingNotes(this.modalData.event.uuid).subscribe(() => {
        this.uiLoader.stop()
        this.closeModal()
      }, err => {
        this.uiLoader.stop()
      })
    }
  }
}
