import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractIndustries'
})
export class ExtractIndustriesPipe implements PipeTransform {

  transform(value: Array<string>, ...args: unknown[]): string {
    let industries: string;
    if (value && value.length === 1) {
      industries = value[0];
    } else if (value && value.length > 2) {
      industries = value[0] + ", " + value[1];
    } else if (value && value.length === 2) {
      industries = value[0] + ", " + value[1];
    }
    return industries;
  }
}
