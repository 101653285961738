import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, takeUntil } from 'rxjs';
import { ServicesLookingFor } from 'src/app/core/domain/startup.model';
import { getStartUpInfo } from 'src/app/core/state/startup';

@Pipe({
  name: 'checkService'
})
export class CheckServicePipe implements PipeTransform {
  servicesLookingFor = [
    // ServicesLookingFor.customer_access,
    // ServicesLookingFor.fundraising,
  ];

  // TODO: if feature not enabled return true for all
  constructor(
    private store: Store
  ) {
    this.store.pipe(select(getStartUpInfo)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.servicesLookingFor = res.servicesLookingFor;
      }
    });

  }

  /**
   * Must pass arg here if your data is changing, so the pipe can get triggered for eg pass startupInfo in args
   */
  transform(serviceToCheck: ServicesLookingFor | ServicesLookingFor[], ...args: unknown[]): unknown {
    if (!serviceToCheck) {
      return true;
    }
    // console.log('checking', this.servicesLookingFor , serviceToCheck)

    if (Array.isArray(serviceToCheck)) {
      return !!this.servicesLookingFor.find(e => {
        return serviceToCheck.find((s) => e === s);
      })
    } else {
      return this.servicesLookingFor.indexOf(serviceToCheck) >= 0;
    }
  }

  checkForSpecificStartUp(serviceToCheck: ServicesLookingFor | ServicesLookingFor[], servicesLookingFor, ...args: unknown[]) {
    if (!serviceToCheck) {
      return true;
    }

    if (Array.isArray(serviceToCheck)) {
      return !!servicesLookingFor.find(e => {
        return serviceToCheck.find((s) => e === s);
      })
    } else {
      return servicesLookingFor.indexOf(serviceToCheck) >= 0;
    }

  }

}
