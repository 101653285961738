import { Component, OnInit } from '@angular/core';
import { IWebinar } from 'src/app/core/domain/webinar';

@Component({
  selector: 'app-youtube-player-modal',
  templateUrl: './youtube-player-modal.component.html',
  styleUrls: ['./youtube-player-modal.component.scss']
})
export class YoutubePlayerModalComponent implements OnInit {
  webinar: IWebinar;
  constructor() { }

  ngOnInit(): void {
  }

}
