<div class="d-flex align-items-center single-post">
  <div class=" box mt-1 position-relative d-flex align-items-center me-1">

    <div class="">
      <input type="checkbox" id="like" class="field-reactions">
      <h3 class="text-desc">Press space and after tab key to navigation</h3>
      <span class="svg-icon svg-icon-2 label-reactions m-0">
        <i class="bi fs-5 header-icons-font-size" [ngClass]="
          { 'bi-hand-thumbs-up-fill text-primary': comment?.reactionStats?.isLoggedInUserReacted,
          'bi-hand-thumbs-up': !comment.reactionStats?.isLoggedInUserReacted
        }">
        </i>
      </span>

      <ng-container *ngIf="!reacted">
        <div class="toolbox"></div>
        <label class="overlay" for="like"></label>
        <button class="reaction-like" (click)="handleReaction(ReactionType.LIKE)"><span
            class="legend-reaction">Like</span></button>
        <button class="reaction-love" (click)="handleReaction(ReactionType.LOVE)"><span
            class="legend-reaction">Love</span></button>
        <button class="reaction-haha" (click)="handleReaction(ReactionType.LAUGH)"><span
            class="legend-reaction">Haha</span></button>
        <button class="reaction-wow" (click)="handleReaction(ReactionType.SURPRISE)"><span
            class="legend-reaction">Wow</span></button>
        <button class="reaction-sad" (click)="handleReaction(ReactionType.SUPPORT)"><span
            class="legend-reaction">Sad</span></button>
        <button class="reaction-angry" (click)="handleReaction(ReactionType.DIS_LIKE)"><span
            class="legend-reaction">Angry</span></button>
      </ng-container>
    </div>

  </div>
  <span class="cursor-pointer" (click)="openReactions()">
    {{comment?.reactionStats?.totalReactions}} {{comment?.reactionStats?.totalReactions === 1 ? 'reaction' : 'reactions'}}
  </span>
</div>
