import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PowerPitchService } from 'src/app/core/service/power-pitch.service';
import { GlobalService } from "src/app/core/service/global.service";

@Component({
  selector: 'app-connect-power-pitch-modal',
  templateUrl: './connect-power-pitch-modal.component.html',
  styleUrls: ['./connect-power-pitch-modal.component.scss']
})
export class ConnectPowerPitchModalComponent implements OnInit {

  isConnecting = false;
  modalInst: NgbModalRef;
  constructor(
    private modal: NgbModal,
    private powerPitchService: PowerPitchService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.modal.dismissAll();
  }

  handleConnect() {
    this.isConnecting = true;
    this.powerPitchService.connectPowerPitch().subscribe((r) => {
      this.isConnecting = false;

      this.powerPitchService.getPowerPitchVideo().subscribe((video) => {
        if (video) {
          console.log('getPowerPitchVideo ==> ', video)
          if (this.modalInst) {
            this.modalInst.close({ hasVideo: true })
          }
        }
      }, error => {
        if (error.status === 404) {
        // if (this.globalService.getErrorMessage(error) === 'Video not found') {
          this.powerPitchService.createPowerPitchVideo().then(pro => {
            pro.subscribe(res => {
              this.modalInst.close({ hasVideo: true, created: true })
            })
          })
        }
      })
    }, err => {
      this.isConnecting = false;
    })
  }

}
