import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { accountTypelabelMap } from 'src/CONSTS';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { CommonService } from 'src/app/core/service/common.service';
import { SearchService } from 'src/app/core/service/search.service';
import { getGlobalSettings } from 'src/app/core/state/global';
import { createSlug } from 'src/app/shared/utils/common-methods';

@Component({
  selector: 'app-global-elastic-search',
  templateUrl: './global-elastic-search.component.html',
  styleUrls: ['./global-elastic-search.component.scss']
})
export class GlobalElasticSearchComponent implements OnInit {
  private destroyed$: Subject<void> = new Subject();

  @Input() hideSearchBar: boolean = false;
  @Input() searchPlaceHolder: string = 'Search for a startup, investor etc...';
  @Input() module: 'startups' | 'investors' | 'corporates' | 'mentors' | 'service_provider' | 'partners' = 'startups';
  @Input() apiEndpoint: string = 'search-global';
  accTypeToRouteMap = {
    [ACCOUNT_TYPE.STARTUP]: 'startups',
    [ACCOUNT_TYPE.INVESTOR]: 'investors',
    [ACCOUNT_TYPE.MENTOR]: 'mentors',
    [ACCOUNT_TYPE.CORPORATE]: 'corporates',
    [ACCOUNT_TYPE.PARTNER]: 'partners',
    [ACCOUNT_TYPE.SERVICE_PROVIDER]: 'service_provider',
  }
  accountTypelabelMap = accountTypelabelMap

  searchTerm = '';

  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings: IGlobalSettings;
  public model: any;

  public getScreenWidth: number;
  public getScreenHeight: number;

  public hideShowInput: boolean;


  constructor(
    public searchService: SearchService,
    private readonly store: Store,
    private router: Router,
    private commonService: CommonService
  ) {
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
  }

  ngOnInit(): void {
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.globalSettings = res; }
    });

    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }


  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      tap((e)=> this.searchValueBeforeDebounce = e),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) => {
        this.searchTerm = term;
        const resultsData = this.searchService.globalElasticSearch(term, this.apiEndpoint);
        console.log(resultsData);
        return resultsData
      }),
      map(e => {
        if(e.length){
          e.push({
            uuid: null,
            name: "Show All Results",
            logo: "",
            accountType: null,
            searchType: "view_all",
            viewAll: true,
            "redirectUri": `/global-search?query=${ typeof this.model === 'object' ?  this.searchTerm : this.model}`
          });
        }

        return e.length ? e.map((item) => {
          if (item.logo) { item.logo = this.globalSettings.imgKitUrl + item.logo }

          if (item.accountType) {
            if (item.accountType === ACCOUNT_TYPE.PARTNER) {
              item['redirectUri'] = `/${this.accTypeToRouteMap[item.accountType]}/${item.uuid}/${createSlug(item?.name || '')}`;
            } else if(item.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER){
              item['redirectUri'] = `/service-provider/profile/${item.uuid}/${createSlug(item?.name || '')}`;
            } else {
              item['redirectUri'] = `/${this.accTypeToRouteMap[item.accountType]}/profile/${item.uuid}/${createSlug(item?.name || '')}`;
            }
          } else if (item.searchType) {
            if (item.searchType === 'news') {
              item['redirectUri'] = `/news?selected=${item.uuid}&name=${item.name}`
            }
            if (item.searchType === 'videos') {
              item['redirectUri'] = `/videos?selected=${item.uuid}&name=${item.name}`
            }
            if (item.searchType === 'reports') {
              item['redirectUri'] = `/reports?selected=${item.uuid}&name=${item.name}`
            }
          }
          // else if(item.viewAll){
          //   item['redirectUri'] = `/global-search?query=${ typeof this.model === 'object' ?  this.searchTerm : this.model}`
          // }
          return item;
        }) : [{ name: "No results found", viewAll: true }]
      })
    );

  formatter = (x: { name: string }) => x.name;


  onSelectItem(e) {
    if (e.item.redirectUri) {
      console.log('e.item.redirectUri', e.item.redirectUri)
      this.router.navigateByUrl(e.item.redirectUri);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  hideShowSearchInput(value: boolean) {
    this.hideShowInput = value;
  }

  hideShowFilters(value: boolean) {
    this.commonService.filterSectionSubject.next(value);
  }

  searchValueBeforeDebounce = '';
  handleKeyPress(event) {
    if (event.code === 'Enter') {
        this.router.navigate(['/global-search'], {
          queryParams: {
            query: typeof this.model === 'object' ?  this.searchValueBeforeDebounce : this.model
          }
        });
    }
  }


}
