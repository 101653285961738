<ng-template>

</ng-template>
<!--begin::Toolbar-->
<div class="toolbar pt-10 shadow-sm" id="kt_toolbar">
  <!--begin::Container-->
  <div id="kt_toolbar_container" class="container-xxl d-flex flex-stack flex-wrap gap-2">
    <!--begin::Page title-->
    <div class="page-title d-flex flex-column align-items-start me-3 py-2 py-lg-5 gap-2">
      <!--begin::Title-->
      <h1 class="d-flex text-dark fw-bolder m-0 fs-3">Compare</h1>
      <!--end::Title-->
      <!--begin::Breadcrumb-->
      <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7">
        <!--begin::Item-->
        <li class="breadcrumb-item text-gray-600">
          <a routerLink="/" class="text-gray-600 text-hover-primary">Home</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-gray-500">Compare</li>
        <!--end::Item-->
      </ul>
      <!--end::Breadcrumb-->
    </div>
    <!--end::Page title-->
    <!--begin::Actions-->
    <div class="d-flex">
    </div>
    <!--end::Actions-->
  </div>
  <!--end::Container-->
</div>
<!--end::Toolbar-->
<!--begin::Checkbox list-->
<div class="d-flex container-xxl">
  <div class="flex-row-fluid align-items-start card">
    <div class="card-body flex-row-fluid">
      <div class="form-check form-check-inline form-check-solid">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
          [(ngModel)]="visibleFieldsSettings.productStage">
        <label class="form-check-label flex-grow-1 fw-bold text-gray-700 fs-6" for="inlineCheckbox1">Product
          Stage</label>
      </div>
      <div class="form-check form-check-inline form-check-solid">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox2"
          [(ngModel)]="visibleFieldsSettings.customerFocus">
        <label class="form-check-label flex-grow-1 fw-bold text-gray-700 fs-6" for="inlineCheckbox2">Customer
          Focus</label>
      </div>
      <div class="form-check form-check-inline form-check-solid">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox3"
          [(ngModel)]="visibleFieldsSettings.businessModel">
        <label class="form-check-label flex-grow-1 fw-bold text-gray-700 fs-6" for="inlineCheckbox3">Business
          Model</label>
      </div>
      <div class="form-check form-check-inline form-check-solid">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox4"
          [(ngModel)]="visibleFieldsSettings.targetFundraiser">
        <label class="form-check-label flex-grow-1 fw-bold text-gray-700 fs-6" for="inlineCheckbox3">Target
          Fundraise</label>
      </div>
      <div class="form-check form-check-inline form-check-solid">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox5"
          [(ngModel)]="visibleFieldsSettings.traction">
        <label class="form-check-label flex-grow-1 fw-bold text-gray-700 fs-6" for="inlineCheckbox3">Traction</label>
      </div>
      <div class="form-check form-check-inline form-check-solid">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox6"
          [(ngModel)]="visibleFieldsSettings.pastFunding">
        <label class="form-check-label flex-grow-1 fw-bold text-gray-700 fs-6" for="inlineCheckbox3">Past Funding
          details</label>
      </div>
    </div>
  </div>
</div>
<!--end::Checkbox list-->
<!--begin::Container-->
<div id="kt_content_container" class="d-flex flex-column-fluid align-items-start container-xxl">
  <div class="content flex-row-fluid pb-20" id="kt_content">
    <!--begin::Search vertical-->
    <div class="d-flex flex-column flex-lg-row">
      <!--begin::Layout-->
      <div class="flex-lg-row-fluid">
        <!--begin::Tab Content-->
        <div class="tab-content">
          <!--begin::Tab pane-->
          <div id="kt_project_users_card_pane" class="tab-pane fade show active">
            <!--begin::Row-->
            <div class="row g-6 g-xl-9">
              <div class="col-md-3 col-xl-3 col-xxl-3" *ngFor="let startup of startupComparisionList">
                <app-startup-compare-card [startup]="startup" [visibleFieldsSettings]="visibleFieldsSettings">
                </app-startup-compare-card>
              </div>

            </div>
            <!--end::Row-->

          </div>
          <!--end::Tab pane-->

        </div>
        <!--end::Tab Content-->
      </div>
      <!--end::Layout-->
    </div>
    <!--begin::Search vertical-->
  </div>

</div>
