<!-- <ngx-ui-loader class="edit-meeting"></ngx-ui-loader> -->
<div class="modal-content" *ngIf="meetingForm">
  <form
    [formGroup]="meetingForm"
    (ngSubmit)="onSubmit()"
    class="form fv-plugins-bootstrap5 fv-plugins-framework"
    id="kt_modal_add_event_form"
  >
    <div class="modal-header">
      <h2 class="fw-bolder" data-kt-calendar="title">
        {{ isRescheduling ? "Propose new time" : "Edit Meeting" }}
      </h2>
      <div
        class="btn btn-icon btn-sm btn-active-icon-primary"
        id="kt_modal_add_event_close"
      >
        <span class="svg-icon svg-icon-1" (click)="close()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect
              opacity="0.5"
              x="6"
              y="17.3137"
              width="16"
              height="2"
              rx="1"
              transform="rotate(-45 6 17.3137)"
              fill="currentColor"
            ></rect>
            <rect
              x="7.41422"
              y="6"
              width="16"
              height="2"
              rx="1"
              transform="rotate(45 7.41422 6)"
              fill="currentColor"
            ></rect>
          </svg>
        </span>
      </div>
    </div>
    <div class="modal-body py-10 px-lg-17">
      <div
        class="fv-row mb-9 fv-plugins-icon-container"
        *ngIf="!isRescheduling && !acceptReschedule"
      >
        <label class="fs-6 fw-bold required mb-2">Meeting title</label>
        <input
          type="text"
          formControlName="meetingTitle"
          class="form-control form-control-solid"
          placeholder=""
          name="calendar_event_name"
        />
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
      <div
        class="row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
      >
        <div class="col-6">
          <label class="fs-6 fw-bold mb-2 required">Date</label>

          <input
            class="form-control"
            formControlName="date"
            class="form-control form-control-solid flatpickr-input"
            placeholder="yyyy-mm-dd"
            name="dp"
            [(ngModel)]="model"
            ngbDatepicker
            #d="ngbDatepicker"
            (click)="d.toggle()"
          />

          <div class="fv-plugins-message-container invalid-feedback"></div>
        </div>

        <!-- <div class="col-6" data-kt-calendar="datepicker">
          <div class="fv-row mb-9">
            <label class="fs-6 fw-bold mb-2">Start Time</label>
            <input
              formControlName="timeFrom"
              class="form-control form-control-solid flatpickr-input"
              (ngModelChange)="handleDurationChange()"
              name="calendar_event_start_time"
              placeholder="Pick a start time"
              id="kt_calendar_datepicker_start_time"
              type="time"
            />
          </div>
        </div> -->


        <div class="col-6">
          <div class="fv-row mb-9">
            <label class="fs-6 fw-bold mb-2">Start Time</label>

            <!-- <select formControlName="timeFrom" class="form-control form-control-solid"
              (ngModelChange)="handleDurationChange()">
              <option value="">Select time</option>
              <option *ngFor="let t of timeSlots" value="{{t}}">{{t | twentyFourHoursToAmPm}}</option>
            </select> -->

            <ng-select [items]="timeSlots" formControlName="timeFrom" [multiple]="false" placeholder="Select Time"
            (change)="handleDurationChange()" [bindLabel]="'label'" [bindValue]="'value'" class="meeting-time-select"
            appendTo="body">
            <ng-template ng-label-tmp let-item="item">
              <b>{{item.label}}</b>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <b>{{item.label}}</b>
            </ng-template>
          </ng-select>
          </div>
        </div>
      </div>


      <div class="fv-row mb-5 fv-plugins-icon-container">
        <label class="fs-6 fw-bold required mb-2">Duration</label>
        <select
          formControlName="duration"
          class="form-control form-control-solid"
          (ngModelChange)="handleDurationChange()"
        >
          <option value="">Select duration</option>
          <option value="30">30 minutes</option>
          <option value="45">45 minutes</option>
          <option value="60">60 minutes</option>
        </select>
        <!-- <input type="text" formControlName="duration" class="form-control form-control-solid" placeholder=""
            name="calendar_event_name" (ngModelChange)="handleDurationChange($event)" /> -->
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>

      <!-- <div class="col" data-kt-calendar="datepicker">
          <div class="fv-row mb-9">
            <label class="fs-6 fw-bold mb-2">End Time</label>
            <ngb-timepicker formControlName="timeTo"></ngb-timepicker>
          </div>
        </div> -->
    </div>
    <div class="modal-footer flex-center">
      <button
        type="reset"
        id="kt_modal_add_event_cancel"
        (click)="close()"
        class="btn btn-light ls-2 text-uppercase fw-boldest me-3"
      >
        Cancel
      </button>
      <button
        type="submit"
        [disabled]="meetingForm.invalid"
        id="kt_modal_add_event_submit"
        class="btn btn-theme text-white ls-2 text-uppercase fw-boldest"
      >
        <span class="indicator-label">Submit</span>
        <span class="indicator-progress"
          >Please wait...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span
        ></span>
      </button>
    </div>
    <div></div>
  </form>
</div>
