import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/core/service/profile.service';
import { SiteMetadataService } from 'src/app/core/service/site-metadata.service';
import { ImagekitUrlAppenderPipe } from 'src/app/shared/pipes/imagekit-url-appender.pipe';
import { toPng } from 'html-to-image';
import { ICertificate } from 'src/app/modules/account/pages/acc-certificates/acc-certificates.component';
import { jsPDF } from 'jspdf';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-certificate-viewer-modal',
  templateUrl: './certificate-viewer-modal.component.html',
  styleUrls: ['./certificate-viewer-modal.component.scss']
})
export class CertificateViewerModalComponent implements OnInit {

  certificates: ICertificate[] = []
  constructor(
    private profileService: ProfileService,
    private imagekit: ImagekitUrlAppenderPipe,
    private siteMetadataService: SiteMetadataService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.siteMetadataService.setTitle('Certificate');
    this.fetchCertificates()
  }

  fetchCertificates() {
    this.profileService.getCertificates().subscribe((res) => {
      console.log('res', res)
      if (res) {
        this.certificates = res.data || []
      }
    })
  }


  async downloadCerti() {
    // const divEle = document.getElementById('hidden-certi-ele');
    const divEle = document.getElementById('certificate-div');
    toPng(divEle, {
      // width: 1200,
      // height: 800
      // height: 1200
    }) .then(function (dataUrl) {
      // var img = new Image();
      // img.src = dataUrl;
      // document.body.appendChild(img);

      // const link = document.createElement('a')
      // link.download = 'my-image-name.png'
      // link.href = dataUrl
      // link.click()


      const pdf = new jsPDF('landscape'); // Use 'landscape' or 'portrait' orientation as needed
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('my-certificate.pdf');


    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
  }


  async downloadCertiAsImage() {
    // const divEle = document.getElementById('hidden-certi-ele');
    const divEle = document.getElementById('certificate-div');
    toPng(divEle, {
    }) .then(function (dataUrl) {
      var img = new Image();
      img.src = dataUrl;
      document.body.appendChild(img);

      const link = document.createElement('a')
      link.download = 'my-image-name.png'
      link.href = dataUrl
      link.click()
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
  }

  saveAs(uri, filename) {

    var link = document.createElement('a');

    if (typeof link.download === 'string') {

        link.href = uri;
        link.download = filename;

        //Firefox requires the link to be in the body
        document.body.appendChild(link);

        //simulate click
        link.click();

        //remove the link when done
        document.body.removeChild(link);

    } else {

        window.open(uri);

    }
}

closeModal(): void {
  this.activeModal.close();
}


}
