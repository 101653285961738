<div class="modal-header">
  <h2 class="text-dark fw-bold fs-1 fs-4qx text-center">

    {{modalHeader ? modalHeader : 'Verify whatsapp number'}}
  </h2>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body px-10 py-8">


  <div class="w-lg-500px px-10 mx-auto" *ngIf="!otpSent && loginForm">

    <form class="form w-100" novalidate id=" " data-kt-redirect-url="#" action="#" [formGroup]="loginForm"
      (ngSubmit)="onSubmit()">

      <!-- *ngIf="brandDetails?.features?.mobile_otp_verification" -->
      <ng-container>

        <div class="text-left mb-4 mt-4">
          <div class="text-gray-400 fw-bold fs-4">
            Choose your country code and enter your mobile number
          </div>
        </div>


        <div class="row g-0 m-0 px-2 bg-secondry mobile-field-login" style="background: #F5F8FA">
          <div class="col-3 pe-0">
            <select formControlName="countryCode" class="form-select" id="floatingSelect"
              aria-label="Floating label select example" id="country">
              <option *ngFor="let country of COUNTRY_CODES_ARR" value={{country.value}}>{{
                '+' + country.value}} </option>
            </select>
          </div>
          <div class="col-9 ps-0">
            <div class="">
              <input type="tel" class="form-control form-control-lg form-control-solid" id="mobile-number"
                placeholder="Mobile number" formControlName="mobileNumber" [ngClass]="
                { 'is-invalid': !loginForm.get('mobileNumber').valid && (loginForm.get('mobileNumber')?.touched || loginForm.get('mobileNumber')?.dirty),
                'is-valid': loginForm.get('mobileNumber').value && loginForm.get('mobileNumber').valid && (loginForm.get('mobileNumber')?.touched || loginForm.get('mobileNumber')?.dirty)
              }" />

            </div>

          </div>
          <span class="invalid-feedback d-block ms-1"
            *ngIf="loginForm.get('mobileNumber')?.hasError('pattern') && (loginForm.get('mobileNumber')?.touched || loginForm.get('mobileNumber')?.dirty)">
            Enter a valid mobile number
          </span>
        </div>


      </ng-container>
      <!-- <p class="text-center fs-3 my-8"
        *ngIf="brandDetails?.features?.mobile_otp_verification && brandDetails?.features?.email_otp_verification">
        or
      </p>

      <ng-container *ngIf="brandDetails?.features?.email_otp_verification">


        <div class="fv-row mb-10">
          <div class="mb-7">
            <input type="email" class="form-control form-control-lg form-control-solid" id="email"
              placeholder="Email address" formControlName="email" [ngClass]="
                { 'is-invalid': !loginForm.get('email').valid && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty),
                'is-valid': loginForm.get('email').value  && loginForm.get('email').valid && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty)
              }" />

            <span class="invalid-feedback d-block ms-1"
              *ngIf="loginForm.get('email')?.hasError('required') && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty)">
              Email address is required
            </span>
            <span class="invalid-feedback d-block ms-1"
              *ngIf="loginForm.get('email')?.hasError('email') && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty)">
              Enter a valid email
            </span>
          </div>
        </div>
      </ng-container> -->


      <div class="text-center mt-8">
        <button [disabled]="
        (!loginForm.get('email').valid && loginForm.get('email').value) || (!loginForm.get('mobileNumber').valid && loginForm.get('mobileNumber').value)
        || (!loginForm.get('email').value && !loginForm.get('mobileNumber').value)
         || loader" type="submit" id="kt_sign_in_submit"
          class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest w-100 mb-5"> <span
            class="indicator-label">Continue</span>
          <span class="indicator-progress" [class.d-inline-block]="loader">
            <span class="spinner-border spinner-border-sm align-middle ms-2">
            </span>
          </span>
        </button>


      </div>


      <!-- <p class="text-center fs-3 my-8">
        Don't have an account? <span class="text-primary cursor-pointer fw-bold"
          (click)="openRegisterModal()">Register</span>
      </p> -->

    </form>

  </div>



  <div class="w-lg-600px px-10 mx-auto" *ngIf="loginForm && otpSent">
    <form class="form w-100 mb-10 d-flex align-items-center flex-column" novalidate="novalidate"
      data-kt-redirect-url="../../demo20/dist/index.html" id="kt_sing_in_two_steps_form" [formGroup]="loginForm"
      (ngSubmit)="onSubmit()">
      <div class="text-center mb-10">

        <h1 class="text-dark mb-3">Verify your OTP</h1>


        <div class="text-muted fw-bold fs-5 mb-5">
          <!-- Enter the 4 digit verification code <br /> sent to your mobile number or email -->
          Enter the 4 digit verification code sent <br /> to your whatsapp number.
        </div>

        <!-- *ngIf="loginForm.value.mobileNumber" -->
        <div class="fw-bolder text-dark fs-3">
          ******{{ loginForm.value.mobileNumber.toString() | slice: 6:10 }}
        </div>
        <!-- <div class="fw-bolder text-dark fs-3" *ngIf="!loginForm.value.mobileNumber && loginForm.value.email">
          ******{{ loginForm.value.email.toString() | slice: 4 }}
        </div> -->


      </div>


      <div class="mb-10 px-md-10">


        <div class="d-flex flex-wrap flex-stack justify-content-center">
          <ng-otp-input [config]="otpInputConfig" [formCtrl]="otpControl"
            (onInputChange)="onOtpChange($event)"></ng-otp-input>
        </div>

      </div>


      <button [disabled]="!loginForm.valid || buttonDisabled || loader" type="submit" id="kt_sign_in_submit"
        class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest w-50 mb-5 text-center">
        <span class="indicator-label">Submit</span>
        <span class="indicator-progress" [class.d-inline-block]="loader">
          <span class="spinner-border spinner-border-sm align-middle ms-2">
          </span>
        </span>
      </button>


    </form>
  </div>




</div>
