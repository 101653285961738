<ngx-ui-loader></ngx-ui-loader>

<div class="tab-content mb-2">

  <ng-template [ngIf]="calenderData && calenderData.length > 0" [ngIfElse]="noFound">
    <a  target="_blank" *ngFor="let data of calenderData"
      (click)="handleClick(data)">
      <div class="d-flex align-items-center cursor-pointer">
        <div class="position-relative me-5">
          <p class="calendar mb-0 pb-0">{{data.dateFormatted}} <em>{{data.month}}</em></p>
        </div>

        <div class="flex-grow-1 me-5">
          <div class="text-gray-800 fw-bold fs-3">
            {{data.eventTitle}}
          </div>
          <div class="text-gray-500 fw-bold fs-5 d-flex justify-content-between">
            <p [ngbTooltip]="data.speakerBio" target="_blank" class="text-gray-500 mb-0">
              {{data.speakerName}}
            </p>

            <div class="badge badge-light rounded text-black" (click)="$event.stopPropagation()"
            *ngIf="data.checkIfResponseExists?.responseAction"
              target="_blank">
              {{data.checkIfResponseExists.responseAction|titlecase}}
            </div>
          </div>

          <div class="text-gray-700 fw-bold fs-7">
            {{data.timeFrom}} -
            {{data.timeTo}}
          </div>
        </div>
      </div>

      <div class="separator separator-dashed mt-4 mb-4"></div>

    </a>



    <a [routerLink]="'/calender/events'"
      class="text-center btn-outline border-secondary w-100 btn mt-5 text-hover-primary">
      View All Events
    </a>

  </ng-template>
  <ng-template #noFound>
    <!-- <div class="d-flex align-items-sm-center">
      <div class="alert alert-primary alert-width-100 text-center" role="alert">
        No events found
      </div>
    </div> -->
    <div class="text-center mt-10 mb-10">
      <i class="bi bi-exclamation-circle" style="font-size: 60px;">
      </i><br><br>
      <span class="fs-5 text-center my-4  text-gray-500">
        No events found
      </span>
    </div>
  </ng-template>

  <ng-template #modalContent let-close="close">
    <app-event-details-modal [modalData]="modalData"></app-event-details-modal>
  </ng-template>

</div>

<!-- <div class="card h-md-100">

  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label fw-bolder text-dark fs-3">Upcoming Events</span>
    </h3>
  </div>
  <div class="card-body pt-5 px-0">

  </div>
</div> -->
