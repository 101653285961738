import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format, isToday } from 'date-fns';
import * as moment from 'moment';
import { ACCOUNT_TYPE, CalenderEvent, CalenderEventData, CalenderWeek } from 'src/app/core/domain';
import { GlobalService } from 'src/app/core/service/global.service';
import { EventDetailsModalComponent } from '../event-details-modal/event-details-modal.component';
import { EventDetailsModalWrapperComponent } from '../event-details-modal-wrapper/event-details-modal-wrapper.component';
@Component({
  selector: 'app-events-calender',
  templateUrl: './events-calender.component.html',
  styleUrls: ['./events-calender.component.scss']
})
export class EventsCalenderComponent implements OnInit, OnChanges {
  @Output() refreshEvents = new EventEmitter()
  @Input() events: any[]
  calenderData = []
  modalData;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  constructor(
    private globalService: GlobalService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getPlatformEvents()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calenderData = this.getCalenderEventData(this.events);
  }

  handleClick(data) {
    this.modalData = {
      action: 'click',
      event: data
    }
    console.log('modalData', this.modalData)
    const ref = this.modalService.open(EventDetailsModalWrapperComponent, { size: data.eventType === 'webinar' ?  'mw-650px' : 'lg' });
    ref.componentInstance.modalInst = ref
    ref.componentInstance.modalData = this.modalData
    ref.closed.subscribe((res) => {
      this.getPlatformEvents()
      this.refreshEvents.emit()
    })
  }


  getPlatformEvents() {
    if(this.events?.length) {
      return
    }
    this.globalService.getPlatformEvents().subscribe(res => {
      console.log('getPlatformEvents', res)
      this.calenderData = this.getCalenderEventData(res);
    })
  }


  getCalenderEventData(data): CalenderEvent[] {
    if (data?.length > 0) {
      const calenderEventList = data.map((data: CalenderEvent) => {

        // const fromGMTTime = moment.utc(`${data.date}T${data.timeFrom}`).local();
        // const toGMTTime = moment.utc(`${data.date} ${data.timeTo}`).local();

        let fromGMTTime = moment.utc(`${data.date} ${data.timeFrom}`)
        // if(convertToLocalTime) {
        //   fromGMTTime = fromGMTTime.local();
        // }
        let toGMTTime = moment.utc(`${data.date} ${data.timeTo}`)
        // if(convertToLocalTime) {
        //   toGMTTime = toGMTTime.local();
        // }

        const payload: any = {
          ...data,
          month: moment(fromGMTTime).format('MMM'),
          dateFormatted: moment(fromGMTTime).format('DD'),
          formattedTimeFrom: moment(fromGMTTime).format('hh:mm a'),
          formattedTimeTo: moment(toGMTTime).format('hh:mm a'),
          timeFrom: moment(fromGMTTime).format('hh:mm a'),
          timeTo: moment(toGMTTime).format('hh:mm a'),
        }
        return payload;
      })
      return calenderEventList;
    }
    return [];
  }


}
