import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  editPageLinks = new BehaviorSubject([]);
  editPageLinks$ = this.editPageLinks.asObservable();
  sidebarLessNonLoggedInViewActive = new BehaviorSubject(false);
  sidebarLessNonLoggedInViewActive$ = this.sidebarLessNonLoggedInViewActive.asObservable();

  public filterSectionSubject = new BehaviorSubject(false);
  constructor(
    private router: Router
  ) { }

  setEditPageLinks(links) {
    this.editPageLinks.next(links)
  }

  scrollFormsToTop() {
    const wrapper = document.getElementById('kt_wrapper')
    if(wrapper) {
      wrapper.scrollTop = 0
    }
  }

  goToNextPage() {
    this.editPageLinks$.pipe(take(1)).subscribe((res) => {
      const foundRoute = res.findIndex((e) => e.route === this.router.url);
      if (foundRoute >= 0 && res[foundRoute + 1]) {
        this.router.navigate([res[foundRoute + 1].route])
        this.scrollFormsToTop()
      }
    })
  }
  goToPreviousPage() {
    this.editPageLinks$.pipe(take(1)).subscribe((res) => {
      const foundRoute = res.findIndex((e) => e.route === this.router.url);
      if (foundRoute >= 0 && res[foundRoute -1 ]) {
        this.router.navigate([res[foundRoute - 1].route])
        this.scrollFormsToTop()
      }
    })
  }

  async getNextRoute() {
    let res = await this.editPageLinks$.pipe(take(1)).toPromise()
    const foundRoute = res.findIndex((e) => e.route === this.router.url);
    if (foundRoute >= 0 && res[foundRoute + 1]) {
      return res[foundRoute + 1].route
    }
    return undefined
  }
  async getPreviousRoute() {
    let res = await this.editPageLinks$.pipe(take(1)).toPromise()
    const foundRoute = res.findIndex((e) => e.route === this.router.url);
    if (foundRoute >= 0 && res[foundRoute - 1]) {
      return res[foundRoute - 1].route
    }
    return undefined
  }
}
