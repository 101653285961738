import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';

@Component({
  selector: 'app-feed-form-image-select-modal',
  templateUrl: './feed-form-image-select-modal.component.html',
  styleUrls: ['./feed-form-image-select-modal.component.scss']
})
export class FeedFormImageSelectModalComponent implements OnInit {
  loading = false;
  @ViewChild("supportingFileDropRef")
  public supportingFileDropRef: ElementRef;
  @Input() readonly allowdedMimeTypes = [
    // 'application/rtf',
    // 'application/pdf',
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/vnd.ms-powerpoint',
    // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'image/png',
    'image/jpg',
    'image/jpeg',
  ]
  readonly MAX_FILE_SIZE = 25; //in mb
  selectedFile: Array<any> = [];
  modalRef: NgbModalRef;

  @Input() accept = '.png, .jpg, .jpeg';
  @Input() acceptLabel = 'png, jpg, jpeg';

  constructor(
    private modalService: NgbModal,
    private toastAlertService: ToastAlertService,
  ) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }


  // supportingFileBrowseHandler(event: any) {
  //   this.selectedFile = [];
  //   const files = event.target.files;
  //   console.log('supportingFileBrowseHandler', files)
  //   const filesToUpload = files;
  //   if (!filesToUpload.length) {
  //     // this.selectedSupportingFiles = [];
  //     this.selectedFile = [];
  //     // this.isEditing = undefined;
  //   } else {
  //       this.supportingFileDroppedHandler(filesToUpload);
  //     }
  //   }

  // supportingFileDroppedHandler(files) {
  //   if(files.length > 10) {
  //     this.toastAlertService.showToast('Maximum 10 images can be selected', 'error');
  //     this.modalRef.close({data: []})
  //     return
  //   }


  //   for (const file of files) {
  //     const isValid = this.validateFiles([file]);
  //     if (isValid) {
  //       this.selectedFile.push(file);
  //     }
  //   }
  //   this.handleSubmit()
  // }

  // validateFiles(files: File[]) {
  //   try {
  //     for (const file of files) {
  //       this.fileSizeValidation(file)
  //       this.fileTypeValidation(file)
  //     }
  //     return true;
  //   } catch (error) {
  //     this.toastAlertService.showToast(error.message, 'error');
  //     return false;
  //   }
  // }

  // fileTypeValidation(file: File) {
  //   if (!file.type || !this.allowdedMimeTypes.includes(file.type)) {
  //     throw new Error('Allowded File type are ' +this.acceptLabel);
  //   }
  // }

  // fileSizeValidation(file: File) {
  //   const sizeInMB = Number((file.size / (1024 * 1024)).toFixed(2));
  //   console.log({ sizeInMB })
  //   if (sizeInMB > this.MAX_FILE_SIZE) {
  //     throw new Error('File size should be less than 25mb');
  //   }
  // }


  handleSubmit(event) {
    this.modalRef.close(event)
  }
}
