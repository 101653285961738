import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, distinctUntilChanged, takeUntil } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';
import { RateExpirenceModalComponent } from 'src/app/shared/common-components/rate-expirence-modal/rate-expirence-modal.component';

@Component({
  selector: 'app-account-setting-header',
  templateUrl: './account-setting-header.component.html',
  styleUrls: ['./account-setting-header.component.scss']
})
export class AccountSettingHeaderComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();
  public globalSettings$: Observable<IGlobalSettings>;
  public profileDetails$: Observable<IProfileDetails>;
  globalSettings: IGlobalSettings;

  constructor(
    private readonly store: Store,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.globalSettings = res; }
    })
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openFeedback() {
    this.modalService.open(RateExpirenceModalComponent, { size: 'md', scrollable: true, centered: true })
  }
}
