import { Component, Input, OnInit } from '@angular/core';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';

@Component({
  selector: 'app-share-links',
  templateUrl: './share-links.component.html',
  styleUrls: ['./share-links.component.scss']
})
export class ShareLinksComponent implements OnInit {
  showPortal = false;
  @Input() hideCopyButton = false;
  @Input() hideTitle = false;
  @Input() details: {
    title: string,
    url: string
  };
  selectedUrlDetails;

  constructor(
    private toastService: ToastAlertService
  ) { }

  ngOnInit(): void {

  }

  async handleShareOption(option: 'fb' | 'wp' | 'twitter' | 'linkedin' | 'clipboard') {
    console.log('details', this.details)
    if (option === 'fb') {
      this.selectedUrlDetails = 'https://www.facebook.com/sharer/sharer.php?u=' + this.details.url
    } else if (option === 'wp') {
      this.selectedUrlDetails = `https://web.whatsapp.com/send?text=${this.details.url}
       ${this.details.title}&type=custom_url&app_absent=0
      `
    } else if (option === 'twitter') {
      this.selectedUrlDetails = `https://twitter.com/intent/tweet?url=${this.details.url}&text=${this.details.title}`
    } else if (option === 'linkedin') {
      this.selectedUrlDetails = `https://www.linkedin.com/shareArticle?url=${this.details.url}&summary=${this.details.title}`
    } else if (option === 'clipboard') {
      await navigator.clipboard.writeText(
        this.details.url
      )
      this.toastService.showToast('Link copied')
    }
  }

  onWindowClose() {
    this.selectedUrlDetails = ''
  }
}
