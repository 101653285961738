import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rating-viewer',
  templateUrl: './star-rating-viewer.component.html',
  styleUrls: ['./star-rating-viewer.component.scss']
})
export class StarRatingViewerComponent implements OnInit {
  @Input() rating: number = 0; // the rating value passed as input
  calculatedRating;
  ngOnInit(): void {
    this.calculatedRating = this.roundToNearestStep(this.rating)
  }
  roundToNearestStep(value: number, step: number = 0.25): number {
    return Math.round(value / step) * step;
  }

}
