<div class=" card alert ">
  <p class="fs-3 fw-bold">
    We could not include you in our DEEPTECH ONLY community since your start-up was unable to qualify our deep tech
    eligibility criteria.
  </p>


  <p class="fs-4 pb-0">
    Criteria :
  </p>
  <div class="fs-5 mt-0">
    <p *ngFor="let item of criterias;let i=index" class="mb-0">
      {{i+1}}. {{item}}
    </p>
    <p class="mb-0">
      - Reviewed by financial and business experts.
    </p>

  </div>


  <div class="fs-5 mt-5">
    <p class="fs-4 ">
      However, you now have access (limited) to the community where you can leverage :
    </p>
    <p *ngFor="let item of options" class="mb-0">
      - {{item}}
    </p>

  </div>

  <p class="mt-4 fs-4">
    Hope you make the most out of this opportunity
  </p>
</div>
