<div class="d-flex mb-5">
  <div class="flex-row-fluid align-items-start card overflow-auto">
    <div class="py-4 px-4 flex-row-fluid w-100 d-flex justify-content-between align-items-center">
      <div class="tab-content">
        <div class="tab-pane fade active show">
          <div class="header-menu flex-column align-items-stretch flex-lg-row">
            <div class="menu menu-lg-rounded menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary fw-bold align-items-stretch flex-grow-1
              ">

              <div *ngFor="let link of navLinks; let i = index" class="d-flex align-items-center">
                <div>
                  <a *ngIf="link?.showMenu" [routerLink]="link.route" [routerLinkActive]="'show here'"
                    class="menu-item me-lg-1">
                    <ng-container>
                      <span class="menu-link py-3">
                        <div class="menu-title no-wrap">
                          {{ link.title }}
                        </div>
                      </span>
                    </ng-container>
                  </a>
                </div>

                <!-- <div>
                  <a *ngIf="link?.showMenu" [routerLink]="link.route" [routerLinkActive]="'show here'" class="menu-item me-lg-1">
                    <ng-container>
                      <span class="menu-link py-3">
                        <div class="menu-title">
                          {{ link.title }}
                        </div>
                      </span>
                    </ng-container>
                  </a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content">
        <div class="tab-pane fade active show">
          <div class="header-menu flex-column align-items-stretch flex-lg-row">
            <div class="menu menu-lg-rounded menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary fw-bold align-items-stretch flex-grow-1
              ">
              <div *ngFor="let link of rightSideTabs; let i = index" class="d-flex align-items-center">
                <ng-container *ngIf="brandDetails?.features?.[link.featureKey]">
                  <div>
                    <a *ngIf="link?.showMenu" [routerLink]="link.route" [routerLinkActive]="'show here'"
                      class="menu-item me-lg-1">
                      <ng-container>
                        <span class="menu-link py-3">
                          <div class="menu-title no-wrap">
                            {{ link.title }}
                          </div>
                        </span>
                      </ng-container>
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
