import {
    HttpClient,
    HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    Observable,
    throwError
} from "rxjs";
import {
    catchError,
    map
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import {
  ACCOUNT_TYPE,
    FaqModel
} from "../domain";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";

@Injectable({
    providedIn: "root"
})
export class RecommendedService {

    constructor(private http: HttpClient, private toastService: ToastAlertService, private globalService: GlobalService) {

    }

    public getRecommendedInvestors(): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.RECOMMENDED_INVESTOR);
        return this.http.get(url).pipe(
            map((response: any) => {
                return response.data;
            }),
            catchError((fault: HttpErrorResponse) => {
                this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while getting recommended investor!'), 'error');
                return throwError(() => fault);
            })
        );
    }

    public hideRecommendedInvestors(investorId: string): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.HIDE_RECOMMENDED_INVESTOR) + investorId;
      return this.http.get(url).pipe(
          // map((response: any) => {
          //     return response.data;
          // }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while hiding recommended investor!'), 'error');
              return throwError(() => fault);
          })
      );
    }


    public getRecommendedStartups(accType?: ACCOUNT_TYPE): Observable<any> {
      const endpoint = accType === ACCOUNT_TYPE.MENTOR ? ApiEndpointService.ENDPOINT.RECOMMENDED_MENTOR_STARTUPS : ApiEndpointService.ENDPOINT.RECOMMENDED_STARTUPS
        const url = ApiEndpointService.getEndpoint(endpoint);
        return this.http.get(url).pipe(
            map((response: any) => {
                return response.data;
            }),
            catchError((fault: HttpErrorResponse) => {
                this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while getting recommended investor!'), 'error');
                return throwError(() => fault);
            })
        );
    }



    public getRecommendedMentors(): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.RECOMMENDED_MENTORS);
      return this.http.get(url).pipe(
          map((response: any) => {
              return response.data;
          }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while getting recommended mentors!'), 'error');
              return throwError(() => fault);
          })
      );
  }


    public hideRecommendedStartups(startupId: string): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.HIDE_RECOMMENDED_STARTUPS) + startupId;
      return this.http.get(url).pipe(
          // map((response: any) => {
          //     return response.data;
          // }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while hiding recommended startup!'), 'error');
              return throwError(() => fault);
          })
      );
    }
  }
