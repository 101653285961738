<!-- <div class="modal-header">
  <h2 class="text-dark fw-bold fs-1 fs-4qx text-center">
    {{ip.programTitle}}
  </h2>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div> -->
<div class="modal-body px-5 pb-8 position-relative">
  <!-- <div class="d-flex justify-content-end mt-0 position-absolute " style="right: 8px;top: 5px;">
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div> -->


  <div class=" px-5 mx-auto d-flex flex-column align-items-start ">

    <div class="d-flex justify-content-between align-items-center w-100 pb-5 border-bottom">
      <div class="d-flex align-items-center  ">
        <!-- <img *ngIf="ip?.programLogo" src="{{ ip.programLogo ? (ip.programLogo | imagekitUrlAppender) + '?tr=w-200,h-100,cm-pad_resize' : 'assets/images/no-image.svg'}}"
         style="width: fit-content;max-width: 100px;" />
        <ngx-avatars *ngIf="!ip?.programLogo" [cornerRadius]="0" [name]="ip?.programTitle" size="100" bgColor="#ccc" class="custom-avatar-ip">
        </ngx-avatars> -->

        <h2 class="fs-2hx  mb-0">
          {{ip.inventionTitle}}
        </h2>
      </div>

      <div class="text-center ">
        <button class="btn btn-lg btn-primary text-white ls-2  w-100 mb-5" [disabled]="loader"
        [disabled]="applied "
        (click)="applyForProgram()">
          <span class="indicator-label">{{applied?('Applied'): 'Apply'}}</span>

          <span class="indicator-progress" [class.d-inline-block]="loader">
            <span class="spinner-border spinner-border-sm align-middle ms-2">
            </span>
          </span>
        </button>
      </div>


    </div>

    <!-- <ng-container *ngIf="wordData?.meaning">
      <span class="text-gray-800 fs-3 fw-bolder mb-2">Meaning</span>
      <div class="col-12 fs-6 mb-6">
        {{ wordData?.meaning }}
      </div>
    </ng-container> -->


    <!-- <h2 class="text-dark fw-bold fs-1 fs-4qx mt-8 text-center">
      {{ip.programTitle}}
    </h2> -->
    <!-- <p class="text-gray-600 fs-3 mt-3">
      Deadline: <ng-container *ngIf="ip.applicationClosedDate">{{ip.applicationClosedDate |
        date:'mediumDate'}}</ng-container>
      <ng-container *ngIf="!ip.applicationClosedDate">No Deadline</ng-container>
    </p> -->

    <div class="d-flex flex-column details-wrapper">
      <div class="mt-5" *ngIf="ip?.abstract">
        <div class="col-12 mt-2 fs-3 fw-bolder mb-4">
          {{ ip?.abstract| decodeHtmlString }}
        </div>
      </div>


      <div class="mt-5 d-flex align-items-center fs-3">
        <span class="text-gray-800  fw-bolder ">Application number: </span>
        <div class="col-12 ">
          &nbsp;{{ip.applicationNumber ? (ip.applicationNumber)  : 'No application number'}}
        </div>
      </div>


      <div class="mt-5" *ngIf="ip?.completeSpecification">
        <span class="text-gray-800 fs-3 fw-bolder mb-2">Complete Specification:</span>
        <div class="col-12 mt-2 fs-3" [innerHTML]="ip?.completeSpecification">

        </div>
      </div>
    </div>


    <div class="w-100 d-flex justify-content-end pt-4 border-top mt-5">
      <button class="btn btn-light border  text-black ls-2   mb-3" (click)="closeModal()">
        Close
      </button>
    </div>

  </div>







</div>
