import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { finalize, Subject } from 'rxjs';
import { RecommendedService } from 'src/app/core/service/recommended.service';
import Swal from 'sweetalert2';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation, SwiperOptions, Virtual, Pagination } from "swiper";
import { SwiperComponent } from 'swiper/angular';
// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Virtual, Pagination]);



@Component({
  selector: 'app-recommended-investors',
  templateUrl: './recommended-investors.component.html',
  styleUrls: ['./recommended-investors.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecommendedInvestorsComponent implements OnInit, OnDestroy {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @Input() simpleUI = false;
  private destroyed$: Subject<void> = new Subject();
  loading = false;
  investors = []
  // chunkedInvestors = []
  constructor(
    private recommendedService: RecommendedService
  ) { }

  ngOnInit(): void {
    this.loading = true
    this.getInvestors()
  }

  getInvestors() {
    this.recommendedService.getRecommendedInvestors().pipe(finalize(() => this.loading = false)).subscribe((res) => {
      console.log('getRecommendedInvestors', res)
      if (res && res.length) {
        this.investors = res.map((e) => {
          return {
            ...e,
            user: e.user[0]
          }
        })
      }
    })
  }

  onMouseenter(){
    console.log('onMouseenter', this.swiper.swiperRef)
    this.swiper.swiperRef.autoplay.stop()
  }

  onMouseleave() {
    this.swiper.swiperRef.autoplay.start()
  }

  hideInvestor(id: string) {
    Swal.fire({
      title: 'Hide Recommendation',
      text: 'Are you sure want to remove this recommendation. The result will not appear in recommendations in future.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-theme)',
      cancelButtonColor: '#7D8185',
      confirmButtonText: 'Hide'
    }).then((result) => {
      if (result.isConfirmed) {
        this.recommendedService.hideRecommendedInvestors(id).subscribe((res) => {
          if (res) {
            console.log('hideInvestor');
            this.getInvestors()
          }
        })
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
