import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import { ACCOUNT_TYPE_TO_DASHBOARD_UTL } from 'src/app/shared/constants/navlinks';
import { AuthService } from 'src/app/core/service';
import { getBrandDetails, getGlobalSettings } from 'src/app/core/state/global';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { ACCOUNT_TYPE, OrganizationInformationResponseModel } from 'src/app/core/domain';
import * as InvestorsOrgInfoActionActions from "src/app/core/state/investors-organization-info/investors-organization-info.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import * as CorporateActions from "src/app/core/state/corporate-info/corporate-info.action";
import { getStartUpInfo } from 'src/app/core/state/startup';
import { getInvestorsOrg } from 'src/app/core/state/investors-organization-info';
import { Router } from '@angular/router';
import { NotificationsCount } from 'src/app/core/state/notifications/notifications.model';
import { getNotificationsCount } from 'src/app/core/state/notifications';
import { OffCanvasMenu, OffcanvasSidebarComponent } from 'src/app/shared/common-components/offcanvas-sidebar/offcanvas-sidebar.component';
import { IStartUpInfoFetch, ServicesLookingFor } from 'src/app/core/domain/startup.model';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { ProfileService } from 'src/app/core/service/profile.service';
import { INavLinkNew, INavMenuItem, getNavMenusByUserType } from 'src/app/shared/constants/navMenus';
import { createSlug } from 'src/app/shared/utils/common-methods';

@Component({
  selector: 'app-protected-layout-header',
  templateUrl: './protected-layout-header.component.html',
  styleUrls: ['./protected-layout-header.component.scss']
})
export class ProtectedLayoutHeaderComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();
  s3LogoUrl: string = '';

  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  public globalSettings$: Observable<IGlobalSettings>;
  public notificationsCount$: Observable<NotificationsCount>;
  globalSettings: IGlobalSettings;
  notificationsCount: NotificationsCount;

  profilePageUrl = '';

  navItems = getNavMenusByUserType(ACCOUNT_TYPE.OTHER, {});

  selectedNavItem: INavMenuItem = undefined;
  ACCOUNT_TYPE_TO_DASHBOARD_UTL = ACCOUNT_TYPE_TO_DASHBOARD_UTL;
  ACCOUNT_TYPE = ACCOUNT_TYPE;
  @ViewChild(OffcanvasSidebarComponent) offcanvasSidebarComponent: OffcanvasSidebarComponent;
  navMenuList: Array<OffCanvasMenu>;
  startupInfo: IStartUpInfoFetch;
  public startupInfo$: Observable<IStartUpInfoFetch>;
  brandDetails: Partial<IBrandDetails>;
  // itemsInMore = [];


  constructor(
    private readonly store: Store,
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService
  ) {

    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.notificationsCount$ = this.store.pipe(select(getNotificationsCount));
  }

  ngOnInit(): void {
    this.startupInfo$ = this.store.pipe(select(getStartUpInfo));
    this.startupInfo$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.startupInfo = res;
      }
    })

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
        this.validateRouteFeature();


        this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
          this.profileData = res;
          let NavItemsVisible = getNavMenusByUserType(res?.accountType, this.brandDetails as IBrandDetails);

          this.navItems = NavItemsVisible;
          // .filter((e) => !e.isInMore);


          const dashKey = this.navItems.find((e) => {
            return e.featureKey === 'show_dashboard'
          })
          if (!this.brandDetails.features['show_dashboard'] && dashKey) {
            this.navItems.unshift({
              title: 'Application Form',
              id: 'Application Form',
              route: '/startups/edit/startup-information',
              children: [],
              iconClass: 'bi bi-house'
              // children: [{
              //   title: 'Application Form',
              //   route: '/startups/edit/startup-information'
              // }],
            })
          }

          // this.itemsInMore = NavItemsVisible.filter((item) => item.isInMore && (!item.featureKey || this.brandDetails.features[item.featureKey]));
          this.getMobileViewNavMenus(NavItemsVisible)


          if (res) {
            this.createS3LogoUrl();
            this.getStartupOrProfileInformation();
          } else {
            this.profileData = undefined;
          }
          this.setDefaultSelectedItem();
        })
      }
    })
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.globalSettings = res; this.createS3LogoUrl(); }
    });


    this.setNotificationsCount();
  }

  setDefaultSelectedItem() {
    const selectedItem = this.navItems.find((navItem) => {
      const routeSelected = this.router.url.includes(navItem.route);
      const isChildSelected = navItem.children.find((subItem) => this.router.url.includes(subItem.route));
      return isChildSelected || routeSelected;
    })
    // if (selectedItem) {
    this.selectedNavItem = selectedItem;
    // }
    // this.validateRouteFeature();
  }


  validateRouteFeature() {
    if (this.selectedNavItem) {

      if (!this.selectedNavItem.featureKey || this.brandDetails?.features?.[this.selectedNavItem.featureKey]) {
        // everything is fine here
      } else {
        setTimeout(() => {
          if (this.selectedNavItem.featureKey === 'show_dashboard') {
            this.router.navigate(['/startups/edit/startup-information']);
            return
          }
          this.router.navigate(['/errors/404']);
        })
      }

    }
  }


  setNotificationsCount(): void {
    this.notificationsCount$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.notificationsCount = res; }
    });
  }


  getStartupOrProfileInformation() {
    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {
      this.store.dispatch(new InvestorsOrgInfoActionActions.GetInvestorsOrgInfo);

      this.store.pipe(select(getInvestorsOrg)).subscribe((response: OrganizationInformationResponseModel) => {
        if (response) {
          this.profilePageUrl = `/investors/profile/` + `${response.data.uuid}/${createSlug(response.data.organizationName)}`
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      this.store.dispatch(new StartupActions.GetStartUpInfo);

      const startupInfo$ = this.store.pipe(select(getStartUpInfo));
      startupInfo$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.profilePageUrl = `/startups/profile/` + `${res.uuid}/${createSlug(res.companyName)}`
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.dispatch(new CorporateActions.GetCorporateInfo);
    }
  }

  // createSlug = (name) => {
  //   const slug = name.split(' ').join('-').toLocaleLowerCase();
  //   return slug;
  // }

  createS3LogoUrl = () => {
    if (this.globalSettings?.s3Url && this.profileData?.avatar) {
      this.s3LogoUrl = this.globalSettings.imgKitUrl + this.profileData?.avatar;
    } else {
      this.s3LogoUrl = '';
    }
  }

  handleLogout() {
    this.authService.logout();
  }

  onSelectNavItem(item: INavMenuItem) {
    this.selectedNavItem = item;
    this.router.navigate([item.route]);
    // if (this.selectedNavItem.children?.[0]) {
    //   const redirectUrl = this.selectedNavItem.children[0].route;
    //   this.router.navigate([redirectUrl]);
    // }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  extractURL(item) {
    this.selectedNavItem = item;
    return item.route
    // if (this.selectedNavItem.children?.[0]) {
    //   const redirectUrl = this.selectedNavItem.children[0].route;
    //   return redirectUrl
    // }
  }

  getMobileViewNavMenus(navItems) {
    this.navMenuList = navItems.map((item) => {
      return {
        id: 1,
        menu: item.title,
        link: this.extractURL(item),
        haveSubmenu: false,
        subMenuList: [],
        haveExternalLink: false
      }
    })
  }

  isItemSelected(item: INavMenuItem) {
    if (!this.selectedNavItem) {
      return false
    }
    const isSelected = this.selectedNavItem?.id === item.id
    const isChildSelected = item.children?.find((e) => e.id === this.selectedNavItem?.id)

    return isSelected || isChildSelected
  }
  isChildItemSelected(item: INavMenuItem) {
    const isChildSelected = this.selectedNavItem?.children?.find((e) => e.id === item?.id)
    if(!isChildSelected) {
      return
    }
    const routeSelected = this.router.url.includes(item.route);

    // if(item.id === 'tickets') {
    //   console.log('tickets isSelected', isChildSelected, this.selectedNavItem.title)
    // }
    return routeSelected
  }

  open() {
    this.offcanvasSidebarComponent.openNav();
  }
}
