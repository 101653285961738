import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getFormsList } from 'src/app/core/state/global';
import { getProfileData } from 'src/app/core/state/profile';
import { createSlug } from '../../utils/common-methods';
import { FormManagementService } from 'src/app/core/service/form-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastAlertService } from '../../utils/toast.service';
import { CommonService } from 'src/app/core/service/common.service';

@Component({
  selector: 'app-form-next-step-button',
  templateUrl: './form-next-step-button.component.html',
  styleUrls: ['./form-next-step-button.component.scss']
})
export class FormNextStepButtonComponent implements OnInit, OnDestroy {

  @Input() url: string;
  @Input() disableNavigation = false;
  @Input() disableNavigationMessage = ' Please fill in all the required information.';

  @Output() next = new EventEmitter()

  private destroyed$: Subject<void> = new Subject();
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;

  buttonVisible = true;
  previousButtonVisible = true;
  isPreviewPage = false;

  constructor(
    private store: Store,
    private router: Router,
    private toastAlertService: ToastAlertService,
    private commonService: CommonService,
    private route: ActivatedRoute,
  ) {
    this.isPreviewPage = this.route.snapshot.data?.['preview']
  }

  async ngOnInit() {

    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;

        this.store.pipe(select(getFormsList)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(async (res) => {

          this.commonService.editPageLinks$.subscribe(async (res)=>{
            if(res) {
              this.buttonVisible = !!(await this.commonService.getNextRoute())
              this.previousButtonVisible = !!(await this.commonService.getPreviousRoute())
            }
          })
          // if (!res[this.profileData.accountType]?.length && this.url.includes('/first')) {
          //   this.buttonVisible = false
          // } else {
          //   this.buttonVisible = true
          // }
        })
      }
    })
  }

  handleClick() {
    if (!this.disableNavigation || this.isPreviewPage) {
      this.next.emit()
      // this.router.navigate([this.url]);
      this.commonService.goToNextPage()

    } else if (this.disableNavigationMessage) {
      this.toastAlertService.showToast(this.disableNavigationMessage, 'error')
    }
  }

  handlePreviousClick() {
    if (!this.disableNavigation || this.isPreviewPage) {
      // this.next.emit()
      // this.router.navigate([this.url]);
      this.commonService.goToPreviousPage()

    } else if (this.disableNavigationMessage) {
      this.toastAlertService.showToast(this.disableNavigationMessage, 'error')
    }
  }


  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
