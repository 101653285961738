import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { GetAllConnectionsReqPayload } from "src/app/modules/connections/connections.model";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { ConnectionRequestTypes } from "src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/utils/enums";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root"
})
export class ConnectionsService {

  constructor(private http: HttpClient, private toastService: ToastAlertService, private globalService: GlobalService) { }

  public GetConnectionsTypesCount(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS + '/types/counts');
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching connections types count'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public GetConnections(filters: { searchName?: string; pageNumber?: number; limit?: number } = {}): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS);
    return this.http.get(url, {
      params: filters
    }).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching profile!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public SendConnectionRequest(payload: { toUserUUID: string, message: string }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS + '/send/request');
    return this.http.post(url, payload).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while sending connection request', 'error');
        return throwError(() => fault);
      })
    );
  }


  public SendConnectionRequestFromInvestor(payload: { toUserUUID: string, message: string }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS + '/investor');
    return this.http.post(url, payload).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while sending connection request', 'error');
        return throwError(() => fault);
      })
    );
  }

  public checkConnectionRequest(toUserUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS + '/check/request/' + toUserUUID);
    return this.http.post(url, toUserUUID).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while checking connection request', 'error');
        return throwError(() => fault);
      })
    );
  }

  public removeConnection(connectionUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS + '/' + connectionUUID);
    return this.http.delete(url).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while removing connection', 'error');
        return throwError(() => fault);
      })
    );
  }

  public getAllConnections(payload: GetAllConnectionsReqPayload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS);
    const urlWithQueryParams = url + '?' + (payload.searchName ? `searchName=${payload.searchName}&` : '') + `sortBy=${payload.sortBy}&orderBy=${payload.orderBy}&pageNumber=${payload.pageNumber}&limit=${payload.limit}`
    return this.http.get(urlWithQueryParams).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while getting all connections', 'error');
        return throwError(() => fault);
      })
    );
  }

  public getRequestedConnections(requestType: ConnectionRequestTypes, payload: {
    pageNumber: number,
    limit: number
  } = {
      pageNumber: 1,
      limit: 500
    }): Observable<any> {
    const urlPostFix = `?pageNumber=${payload.pageNumber}&limit=${payload.limit}`
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS + '/requests/' + requestType + urlPostFix);
    return this.http.get(url,).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while getting connections by request types', 'error');
        return throwError(() => fault);
      })
    );
  }

  public acceptConnection(payload: { connectionUUID: string, actionMessage: string }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS + "/accept/" + payload.connectionUUID
    );
    return this.http.patch(url, { actionMessage: payload.actionMessage }).pipe(
      map((response: any): any => {
        this.toastService.showToast(response?.message);
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while accepting connection', 'error');
        return throwError(() => fault);
      })
    );
  }

  public rejectConnection(payload: { connectionUUID: string, actionMessage: string }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS + "/reject/" + payload.connectionUUID
    );
    return this.http.patch(url, { actionMessage: payload.actionMessage }).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while rejecting connection', 'error');
        return throwError(() => fault);
      })
    );
  }


  public publicConnectionRejectAccept(payload: any) {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PUBLIC_CONNECTION);
    return this.http.post(url, payload).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while rejecting/ accepting connection', 'error');
        return throwError(() => fault);
      })
    );
  }


  public uploadConnectionDocument(file: File): Observable<{ message: string, data: any }> {
    const payload = new FormData();
    payload.append('file', file);
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTION_DOC_UPLOAD);
    return this.http.post(url, payload).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving connection file!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public getParentUserByGrp(grpChatId: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS + '/parent-user/group/' + grpChatId);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  public checkOnlineStatus(userIds: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CONNECTIONS + '/check_user_online_status');
    return this.http.get(url, { params: { userIds } }).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching online status', 'error');
        return throwError(() => fault);
      })
    );
  }

}
