<div class="card">
  <div class="tab-content">
    <ul
      ngbNav
      #nav="ngbNav"
      [(activeId)]="activeMeetingsTab"
      class="nav nav-pills nav-pills-custom d-flex position-relative w-100"
    >
      <li
        (click)="activeMeetingsTab = 1"
        [ngbNavItem]="1"
        class="nav-item mx-0 p-0 pt-3 w-50 me-0"
        [ngClass]="{
          'active-border': activeMeetingsTab === 1,
          'w-100': !brandDetails?.features?.events,
          'w-50': brandDetails?.features?.events
        }"
        >
        <!-- [ngClass]="activeMeetingsTab === 1 ? 'active-border' : ''" -->
        <a
          class="text-center btn-color-muted border-0 h-100 px-0 d-flex justify-content-center align-items-end"
          data-bs-toggle="pill"
          ngbNavLink
          [ngClass]="
            activeMeetingsTab === 1
              ? 'text-primary active-border'
              : 'text-black'
          "
        >
          <span class="fw-bolder fs-6 mb-3">Upcoming meetings</span>
        </a>
      </li>

      <li
        *ngIf="brandDetails?.features?.events"
        (click)="activeMeetingsTab = 2"
        [ngbNavItem]="2"
        class="nav-item mx-0 p-0 pt-3 w-50 me-0"
        [ngClass]="activeMeetingsTab === 2 ? 'active-border' : ''"
      >
        <a
          class="text-center btn-color-muted border-0 h-100 px-0 d-flex justify-content-center align-items-end"
          data-bs-toggle="pill"
          ngbNavLink
          [ngClass]="
            activeMeetingsTab === 2
              ? 'text-primary active-border'
              : 'text-black'
          "
        >
          <span class="fw-bolder fs-6 mb-3">Upcoming Events
            <img *ngIf="isNewEvent" src="assets/images/new_3.gif" class="logo-default position-absolute new-logo" />
          </span>
        </a>


      </li>
    </ul>
  </div>
  <div class="card-body">
      <app-dashboard-common-calender *ngIf="activeMeetingsTab === 1"
        [calenderData]="calenderData"
      ></app-dashboard-common-calender>

      <app-events-calender *ngIf="brandDetails?.features?.events && activeMeetingsTab === 2"></app-events-calender>

  </div>
</div>
