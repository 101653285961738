<div class="header bg-white-imp shadow-sm" data-kt-sticky="true" data-kt-sticky-name="header"
  data-kt-sticky-offset="{default: '200px', lg: '300px'}">

  <div class="header-top d-flex align-items-stretch flex-grow-1">

    <div class="d-flex container-xxl align-items-stretch">

      <div class="d-flex align-items-center me-5 justify-content-center flex-row-fluid">


        <app-logo-renderer class="h-25px h-lg-50px" [headerLogo]="false"></app-logo-renderer>


      </div>
    </div>

  </div>
</div>
