<div class="position-relative"
[ngbTooltip]="'Only approved users can post.'"
[disableTooltip]="profileCompleteness?.isApproved"
>
  <textarea class="form-control comment-form border-0 p-0 pe-10 resize-none "
    (keyup.enter)="handleSubmit()"
    style="resize: none;"
    [disabled]="!profileCompleteness?.isApproved"
    data-kt-autosize="true" rows="1"
    [(ngModel)]="comment"
    placeholder="Write a comment"></textarea>
  <div class="position-absolute top-0 end-0 me-n5">
    <!-- <span class="btn btn-icon btn-sm btn-active-color-primary pe-0 me-2">

      <span class="svg-icon svg-icon-3 mb-3">
        <i class="bi bi-paperclip fs-5 header-icons-font-size"></i>
      </span>

    </span> -->
    <span class="btn btn-icon btn-sm btn-active-color-primary ps-0" (click)="handleSubmit()">
      <div ngbTooltip="Only approved users can post." [disableTooltip]="profileCompleteness?.isApproved">

      <span class="svg-icon svg-icon-2 mb-3">
        <i class="bi bi-send fs-5 header-icons-font-size"></i>
      </span>
      </div>
    </span>
  </div>
</div>
