import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { PartnersInformation } from 'src/app/core/domain/partners.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';
import { createSlug } from '../../utils/common-methods';
import slugify from 'slugify';
import { Router } from '@angular/router';
import { MixpanelService } from 'src/app/core/service/mixpanel.service';

@Component({
  selector: 'app-our-partner',
  templateUrl: './our-partner.component.html',
  styleUrls: ['./our-partner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OurPartnerComponent implements OnInit, OnChanges, OnDestroy {

  @Input('data') public data: Array<PartnersInformation>;
  public updatedList: Array<PartnersInformation>;
  ACCOUNT_TYPE = ACCOUNT_TYPE;
  public globalSettings$: Observable<IGlobalSettings>;
  public avatar = '';
  public s3LogoUrl: string = '';
  public globalSettings: IGlobalSettings;
  private destroyed$: Subject<void> = new Subject();

  constructor(private readonly store: Store, private router: Router,
    private mixPanel: MixpanelService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));

    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.globalSettings = res; }
    })
    this.getUpdatedData();
  }

  ngOnInit(): void {
  }

  getUpdatedData() {
    if (this.data && this.data.length > 0) {
      this.updatedList = this.data.map((row: PartnersInformation) => {
        this.avatar = row.logo;
        const profilePageUrl = `/startups/profile/${row.uuid}/${createSlug(row.name)}`
        return {
          ...row,
          logo: this.s3LogoUrl,
          pageRoute: profilePageUrl
        }
      })
    }
  }

  getTrimmedString(text: string): string {
    return text.replace(/\s/g, '');
  }

  createS3LogoUrl = (url: string): string => {
    if (this.globalSettings?.imgKitUrl && url) {
      return url + '?tr=w-100,h-100,cm-pad_resize';
    } else {
      return '';
    }
  }

  routeToPartnersDetailsPage(partner: PartnersInformation) {
    const slug = slugify(partner.name, {
      replacement: '-',  // replace spaces with replacement character, defaults to `-`
      remove: undefined, // remove characters that match regex, defaults to `undefined`
      lower: true,      // convert to lower case, defaults to `false`
      strict: true,     // strip special characters except replacement, defaults to `false`
      locale: 'vi',       // language code of the locale to use
      trim: true         // trim leading and trailing replacement chars, defaults to `true`
    });

    this.router.navigate(['/partners', partner.uuid, slug]);
  }


  trackOnMixPanel(action: string, element: string) {
    this.mixPanel.track(action, {
      element
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
