import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AdsState } from "./ads.reducer";

export const selectAdsState = createFeatureSelector<AdsState>("ads");

export const getAds = createSelector(
  selectAdsState,
  (state: AdsState) => state.ads
);

