<button type="submit" id="kt_password_reset_submit" *ngIf="previousButtonVisible"
  class="btn btn-lg btn-dark text-white ls-2 text-uppercase fw-boldest fw-bolder me-4" (click)="handlePreviousClick()">
  <!-- [routerLink]="url" -->
  <span class="indicator-label d-none d-lg-block">Previous Step</span>
  <span class="indicator-label d-block d-lg-none">Previous</span>
</button>

<button type="submit" id="kt_password_reset_submit" *ngIf="buttonVisible"
  class="btn btn-lg btn-dark text-white ls-2 text-uppercase fw-boldest fw-bolder" (click)="handleClick()">
  <!-- [routerLink]="url" -->
  <span class="indicator-label d-none d-lg-block">Next Step</span>
  <span class="indicator-label d-block d-lg-none">Next</span>

</button>
