<div class="card">
  <div class="card-body d-flex flex-column flex-center">
    <div class="mb-2">
      <h2 class="fw-bold text-gray-800 text-center lh-lg mb-5">
        Your profile is in
        <br />
        <span class="fw-boldest" *ngIf="chartPer < 100"> draft mode </span>
        <span
          class="fw-boldest"
          *ngIf="chartPer >= 100 && !profileCompleteness?.isApprovalRequested"
        >
          Ready to submit
        </span>
        <span
          class="fw-boldest"
          *ngIf="chartPer >= 100 && profileCompleteness?.isApprovalRequested"
        >
        Submitted
        </span>
      </h2>

      <div class="d-flex align-items-center mb-5 flex-column">
        <div class="w-100px flex-shrink-0 me-2">
          <apx-chart
            [series]="chartOptions?.series"
            [chart]="chartOptions?.chart"
            [plotOptions]="chartOptions?.plotOptions"
            [labels]="chartOptions?.labels"
            [stroke]="chartOptions?.stroke"
            [fill]="chartOptions?.fill"
          ></apx-chart>
        </div>

        <div class="m-0">
          <h4 class="fw-bolder text-gray-800 mb-3 fs-2">
            {{ chartPer }}% complete
          </h4>
        </div>
      </div>

      <button class="btn btn-dark text-white ls-2 text-uppercase fw-boldest btn-sm fs-8 ms-2"
      [routerLink]="profileEditPageUrl"
      *ngIf="profileCompleteness?.percentage < 100"
      >
        <i
          class="bi bi-pencil-fill cursor-pointer text-white fs-8"
        ></i>
        <span>
          Edit Profile
        </span>
      </button>

    </div>
  </div>
</div>
