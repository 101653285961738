import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFeedComment, IReply, ReactionType } from 'src/app/core/domain/community-feed';
import { ReactionsModalComponent } from '../community-feed-list/reactions-modal/reactions-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs';

@Component({
  selector: 'app-community-feed-reaction',
  templateUrl: './community-feed-reaction.component.html',
  styleUrls: ['./community-feed-reaction.component.scss']
})
export class CommunityFeedReactionComponent implements OnInit {
  reacted = false;
  ReactionType = ReactionType;

  @Input() comment: IFeedComment;
  @Output() onReaction = new EventEmitter<ReactionType>();

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {

  }

  handleReaction(reaction: ReactionType) {
    this.onReaction.emit(reaction)
  }

  openReactions() {
    // let modalRef = this.modalService.open(ReactionsModalComponent, {
    //   centered: true,
    //   size: 'md',
    // });
    // modalRef.componentInstance.comment = this.comment;
    // modalRef.dismissed.pipe(tap((val) => {
    //   modalRef = undefined;
    // })).subscribe();
  }


  // get likeText() {
  //   return this.comment?.reactionStats?.totalReactions
  // }

}
