import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { SiteMetadataService } from 'src/app/core/service/site-metadata.service';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/lottie/coming-soon.json',
  };

  constructor(
    public route: ActivatedRoute,
    private title: Title,
    private siteMetadataService: SiteMetadataService,
  ) { }

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    let title = snapshot.data['title'] as Array<string>;
    this.siteMetadataService.setTitle(title);

  }

}
