import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CompareState } from "./compare.reducer";

export const selectCompare = createFeatureSelector<CompareState>("compare");

export const getStartupsToCompare = createSelector(
  selectCompare,
  (state: CompareState) => state.startupsToCompare
);

export const getInvestorsToCompare = createSelector(
  selectCompare,
  (state: CompareState) => state.investorsToCompare
);


export const getCorporateToCompare = createSelector(
  selectCompare,
  (state: CompareState) => state.corporatesToCompare
);

