<swiper [config]="config" class="mySwiper">
  <ng-template swiperSlide *ngFor="let list of globalTestimonialsList">
    <!--begin::Item-->
    <!--begin::Row-->
    <div
      class="row w-xl-1000px mx-auto gy-10 d-flex align-items-center justify-content-center"
    >
      <!--begin::Col-->
      <div class="col-12 col-md-3 px-5">
        <div class="testimonial-img">
          <img [src]="list?.avatar | imagekitUrlAppender" width="100%" alt="" />
        </div>
      </div>
      <!--end::Col-->
      <!--begin::Col-->
      <div class="col-12 col-md-9 px-10">
        <p
          class="fs-2 text-white mobile-center"
          [innerHTML]="list?.comment"
        ></p>

        <br />
        <h2 class="fs-2 mobile-center text-white fs-1tx fw-bolder">
          {{ list?.name }}
        </h2>
        <h3
          class="text-white fs-6 mobile-center uppercase font-weight-300 word-spacing"
        >
          {{ list?.designation }}, {{ list?.company }}
        </h3>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->
    <!--end::Item-->
  </ng-template>
</swiper>
