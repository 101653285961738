<div class="footer-section mb-0 shadow-sm">
  <div class="bg-white py-7">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="row">
            <div class="col-12">
              <span class="fw-normal text-dark fs-6 text-400">Copyright &copy; {{ currentYear }} {{host}}. All rights reserved.</span>
            </div>
            <div class="col-12 mt-4">
              <div class="text-dark d-flex align-items-start align-items-lg-center justify-content-left flex-column flex-lg-row">
                <a class="me-4 text-dark no-wrap" *ngIf="globalSettings?.contactDetails?.contactNumber" href="tel:{{globalSettings?.contactDetails?.contactNumber}}" target="_blank">
                  <i class="bi bi-telephone"></i> {{globalSettings?.contactDetails?.contactNumber}}
                </a>

                <a class="me-4 text-dark no-wrap" href="mailto:{{globalSettings?.contactDetails?.contactEmail}}" target="_blank" *ngIf="globalSettings?.contactDetails?.contactEmail" >
                  <i class="bi bi-envelope"></i> {{globalSettings?.contactDetails?.contactEmail}}
                </a>
              </div>
            </div>
            <span class="me-4 mt-4 no-wrap" *ngIf="globalSettings?.contactDetails?.contactAddress">
              <i class="bi bi-geo-alt-fill"></i> {{globalSettings?.contactDetails?.contactAddress}}
            </span>

          </div>
        </div>
        <div class="col-12 col-md-4 flex-column d-flex align-items-lg-end align-items-start mt-4 mt-lg-0">
          <div class="d-flex social-icons align-items-center justify-content-end">
            
            <div class="d-flex mb-point-five" *ngIf="globalSettings?.socialLinks?.linkedin">
              <a ngbTooltip="Follow on Linkedin" target="_blank" [href]="globalSettings?.socialLinks?.linkedin" class="wow fadeInLeft">
                <i class="bi bi-linkedin socialIcon"></i>
                <!-- <img
                  class="ng-lazyloaded footer-icon-hover  w-50" [useSrcset]="true" [lazyLoad]="
                  'assets/v2/media/icons/linkedin.png'
                " /> -->
                </a>
            </div>
            <div class="d-flex"  *ngIf="globalSettings?.socialLinks?.twitter">
              <a ngbTooltip="Follow on Twitter" target="_blank" [href]="globalSettings?.socialLinks?.twitter" class="wow fadeInLeft">
                <i class="bi bi-twitter socialIcon"></i>
                <!-- <img
                style="width: 24px;height: 24px;"
                  class="ng-lazyloaded " [useSrcset]="true" [lazyLoad]="
                  'assets/v2/media/icons/x-twitter.svg'
                " /> -->
                </a>
            </div>
            <div class="d-flex" *ngIf="globalSettings?.socialLinks?.youtube">
              <a ngbTooltip="Subscribe on Youtube" target="_blank" [href]="globalSettings?.socialLinks?.youtube" class="wow fadeInLeft">
                <i class="bi bi-youtube socialIcon"></i>
                <!-- <img
                  class="ng-lazyloaded ms-4 w-60" [useSrcset]="true" [lazyLoad]="
                  'assets/v2/media/icons/icons8-youtube-logo-50.png'
                " /> -->
                </a>
            </div>
            <div class="d-flex mb-point-five" *ngIf="globalSettings?.socialLinks?.facebook">
              <a ngbTooltip="Follow on Facebook" target="_blank" [href]="globalSettings?.socialLinks?.facebook" class="wow fadeInLeft">
                  <i class="bi bi-facebook socialIcon "></i>
                <!-- <img
                  class="ng-lazyloaded w-50" [useSrcset]="true" [lazyLoad]="
                  'assets/v2/media/icons/fb.png'
                " /> -->
                </a>
            </div>
            
            <div class="d-flex" *ngIf="globalSettings?.socialLinks?.instagram">
              <a ngbTooltip="Follow on Instagram"target="_blank" [href]="globalSettings?.socialLinks?.instagram" class="wow fadeInLeft">
                <i class="bi bi-instagram socialIcon me-0"></i>
                <!-- <img
                  class="ng-lazyloaded  w-60" [useSrcset]="true" [lazyLoad]="
                  'assets/v2/media/icons/icons8-instagram-64.png'
                " /> -->
                </a>
            </div>
            <!-- icons8-instagram-48.png -->
          </div>
          <span class="fw-normal text-dark mt-4 fs-6 text-400">
            Powered by Sanchi Connect Private Limited
          </span>
        </div>
        <!-- <div class="col-12 col-md-4 text-end d-flex flex-column">
          <a href="https://sanchiconnect.com" target="_blank" class="text-dark">
            <span class="fs-8">Powered by</span><br>
            <img src="https://img-cdn.sanchiconnect.com/assets/v2/media/logos/logo-2-white.svg"
              class="logo-default h-30px " />
          </a>
            <span class="mt-8 text-dark cursor-pointer" *ngIf="profileData" (click)="openFeedback()">
              Feedback
            </span>
        </div> -->
      </div>
    </div>
  </div>
</div>


<div id="kt_scrolltop" class="scrolltop" (click)="scrollToTop()">

  <span class="svg-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
      <path
        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
        fill="currentColor" />
    </svg>
  </span>

</div>
