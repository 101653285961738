<!--begin::Footer Section-->
<div class="footer-section mb-0">
  <!--begin::Wrapper-->
  <div class="landing-dark-bg pt-20">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Row-->
      <div class="row mb-10 d-none">
        <!--begin::Col-->
        <div class="col-lg-7 mb-10 mb-lg-0">
          <!--begin::Block-->
          <div>
            <!--begin::Text-->
            <h1 class="text-white fs-3hx fw-bold">Subscribe for updates</h1>
            <span class="fw-normal fs-3 text-gray-400 opacity-40"
              >Stay on top of the latest additions, blog posts and
              opportunities</span
            >
            <!--end::Text-->
          </div>
          <!--end::Block-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-5 ps-lg-16">
          <!--begin::Navs-->
          <div class="d-flex justify-content-center col-xl-12">
            <div class="row fv-row">
              <!--begin::Col-->
              <!--begin::Col-->
              <div class="col-md-6 px-5">
                <div
                  class="row bg-white py-2 w-450px mx-1 rounded wow fadeInLeft"
                  data-wow-delay="0.40s"
                >
                  <div id="mc_embed_signup">
                    <form
                      action="https://sanchiconnect.us17.list-manage.com/subscribe/post?u=4da69cb671342fd5c26bddcde&amp;id=96b0679b39"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      class="validate"
                      target="_blank"
                      novalidate
                      style="padding: 0px"
                    >
                      <!--begin::Col-->
                      <div class="col-md-7 float-left">
                        <!--begin::Input group-->
                        <div class="form pt-1">
                          <div id="mc_embed_signup_scroll">
                            <div class="mc-field-group">
                              <input
                                placeholder="Your email address.."
                                type="email"
                                value=""
                                name="EMAIL"
                                class="required email form-control form-control-transparent border-0"
                                id="mce-EMAIL"
                              />
                            </div>
                            <div
                              style="padding: 0px; margin: 0px"
                              id="mce-responses"
                              class="clear"
                            >
                              <div
                                class="response"
                                id="mce-error-response"
                                style="display: none"
                              ></div>
                              <div
                                class="response"
                                style="padding: 0px; margin: 0px"
                                id="mce-success-response"
                                style="display: none"
                              ></div>
                            </div>
                            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                          </div>
                          <!--end::Input group-->
                        </div>
                      </div>
                      <!--end::Col-->
                      <!--begin::Col-->
                      <div class="col-md-5 px-0 float-left">
                        <div class="right">
                          <!--begin::Submit button-->
                          <div
                            style="position: absolute; left: -5000px"
                            aria-hidden="true"
                          >
                            <input
                              type="text"
                              name="b_4da69cb671342fd5c26bddcde_96b0679b39"
                              tabindex="-1"
                              value=""
                            />
                          </div>
                          <button
                            type="submit"
                            value="Subscribe"
                            name="subscribe"
                            class="btn w-100 btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest"
                          >
                            Subscribe
                          </button>

                          <!--end::Submit button-->
                        </div>
                      </div>
                      <!--end::Col-->
                    </form>
                  </div>
                  <!--End mc_embed_signup-->
                </div>
              </div>
              <!--end::Col-->
              <!--end::Col-->
            </div>
          </div>
          <!--end::Navs-->
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Separator-->
      <div class="landing-dark-separator mb-10 d-none"></div>
      <!--end::Separator-->
      <!--begin::Row-->
      <div class="row py-10">
        <!--begin::Col-->
        <div class="col-lg-4 mb-10 mb-lg-0">
          <!--begin::Block-->
          <div class="mb-10">
            <!--begin::Logo-->
            <div class="d-flex align-items-center flex-equal mb-10">
              <!--begin::Logo image-->
              <a href="#">

                <app-logo-renderer
                class="logo-default h-40px h-lg-50px"
              ></app-logo-renderer>
              </a>
              <!--end::Logo image-->
            </div>
            <!--end::Logo-->
            <!--begin::Text-->
            <span class="fw-normal fs-6 text-gray-400"
              >We are a specialized community for DeepTech startups, Investors,
              Enterprise Partners and Service Providers.<br />
              <br />
              Your request to access the platform is subject to a review
              first.</span
            >
            <!--end::Text-->

            <div class="py-5">
              <div class="d-flex flex-wrap">
                <a
                  target="_blank"
                  href="https://www.facebook.com/sanchiconnects"
                  class="me-5 social_icon wow fadeInLeft"
                  data-wow-delay="0.25s"
                >
                  <img [lazyLoad]="'/images/facebook.svg'| assetsImagekitUrlAppender" [useSrcset]="true" width="100%" alt=""
                /></a>

                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/80556478/"
                  class="me-5 social_icon wow fadeInLeft"
                  data-wow-delay="0.75s"
                >
                  <img [lazyLoad]="'/images/linkedin.svg'| assetsImagekitUrlAppender" [useSrcset]="true" width="100%" alt=""
                /></a>
                <a
                  target="_blank"
                  href="https://twitter.com/sanchiconnect"
                  class="me-5 social_icon wow fadeInLeft"
                  data-wow-delay="1s"
                >
                  <img [lazyLoad]="'/images/twitter.svg'| assetsImagekitUrlAppender" [useSrcset]="true" width="100%" alt=""
                /></a>
              </div>
            </div>
          </div>
          <!--end::Block-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-4 ps-lg-16">
          <!--begin::Navs-->
          <div class="d-flex justify-content-left">
            <!--begin::Links-->
            <div class="d-flex fw-bold flex-column me-20">
              <!--begin::Subtitle-->
              <h4 class="fw-bolder text-gray-400 mb-6">Company</h4>
              <!--end::Subtitle-->
              <!--begin::Link-->
              <a
                routerLink="/about-us"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >About us</a
              >
              <!--end::Link-->
              <!--begin::Link-->
              <a
                href="https://sanchiconnect.com/blog"
                target="_blank"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Blog</a
              >
              <!--end::Link-->
              <!--begin::Link-->
              <a
                routerLink="/contact-us"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Contact Us</a
              >
              <!--end::Link-->
            </div>
            <!--end::Links-->
            <!--begin::Links-->
            <div class="d-flex fw-bold flex-column ms-lg-20">
              <!--begin::Subtitle-->
              <h4 class="fw-bolder text-gray-400 mb-6">Resources</h4>
              <!--end::Subtitle-->
              <!--begin::Link-->
              <a
                routerLink="/glossary"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Glossary</a
              >
              <!--end::Link-->
              <!--begin::Link-->
              <a
                routerLink="/resources"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Downloads</a
              >
              <!--end::Link-->
              <!--begin::Link-->
              <a
                routerLink="/faqs"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >FAQs</a
              >
              <!--end::Link-->
            </div>
            <!--end::Links-->
          </div>
          <!--end::Navs-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-4 ps-lg-16">
          <div class="d-flex justify-content-left">
            <!-- <div class="d-flex fw-bold flex-column me-20">
              <h4 class="fw-bolder text-gray-400 mb-6">Insights</h4>
              <a
                href="#"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >How it works? (Startups)</a
              >
              <a
                href="#"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >How it works? (Investors)</a
              >
              <a
                href="#"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Help Center</a
              >
            </div> -->
            <div class="d-flex fw-bold flex-column ms-lg-20">
              <h4 class="fw-bolder text-gray-400 mb-6">Legal</h4>
              <a
                [routerLink]="'/page/1/privacy'"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Privacy Policy</a
              >
              <a
                [routerLink]="'/page/3/terms-conditions'"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Terms and Conditions</a
              >
              <a
                [routerLink]="'/page/4/cookie-policy'"
                class="text-white opacity-50 text-hover-primary fs-5 mb-6"
                >Cookie Policy</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end::Container-->
    <!--begin::Container-->
    <div class="container">
      <!--begin::Separator-->
      <div class="landing-dark-separator"></div>
      <!--end::Separator-->
      <!--begin::Wrapper-->
      <div class="flex-column text-center flex-md-row flex-stack py-7 py-lg-10">
        <!--begin::Copyright-->
        <div class="align-items-center">
          <span class="mx-5 fs-6 fw-bold text-gray-600 pt-1"
            >Copyright @ {{ currentYear }} SanchiConnect.com. All rights
            reserved</span
          >
          <br />
          <span class="mx-5 fs-6 fw-bold text-gray-600 pt-1"
            >SanchiConnect is a product of Sanchi Techstarter Private Limited
          </span>
        </div>
        <!--end::Copyright-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Wrapper-->
</div>
<!--end::Footer Section-->

<!--begin::Scrolltop-->
<div id="kt_scrolltop" class="scrolltop" (click)="scrollToTop()">
  <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
  <span class="svg-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.5"
        x="13"
        y="6"
        width="13"
        height="2"
        rx="1"
        transform="rotate(90 13 6)"
        fill="currentColor"
      />
      <path
        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
        fill="currentColor"
      />
    </svg>
  </span>
  <!--end::Svg Icon-->
</div>
<!--end::Scrolltop-->
