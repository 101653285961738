import { createFeatureSelector, createSelector } from "@ngrx/store";
import { InvestorCompareState } from "./investor.compare.reducer";

export const selectInvestorCompareState = createFeatureSelector<InvestorCompareState>("investorCompare");

export const getInvestorCompareDashboard = createSelector(
    selectInvestorCompareState,
    (state: InvestorCompareState) => state.payload
)

export const getInvestorCompareDashboardError = createSelector(
    selectInvestorCompareState,
    (state: InvestorCompareState) => state.investorCompareError
)