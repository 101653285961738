import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { IStartUpInfoFetch } from 'src/app/core/domain/startup.model';
import { getBrandDetails, getGlobalSettings } from 'src/app/core/state/global';

@Component({
  selector: 'app-startup-compare-card',
  templateUrl: './startup-compare-card.component.html',
  styleUrls: ['./startup-compare-card.component.scss']
})
export class StartupCompareCardComponent implements OnInit {
  private destroyed$: Subject<void> = new Subject();
  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings: IGlobalSettings;
  brandDetails: Partial<IBrandDetails>;

  @Input() startup: IStartUpInfoFetch;
  @Input() visibleFieldsSettings;

  public productStage: boolean = true;
  public customerFocus: boolean = true;
  public businessModel: boolean = true;
  public targetFundraiser: boolean = true;
  public traction: boolean = true;
  public pastFunding: boolean = true;
  industriesNames = [];
  s3logoUrl = '';


  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {

    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));

    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
        this.s3logoUrl = res.s3Url + this.startup.companyLogo;
      }
    });

    this.industriesNames = this.startup.startupIndustries.map((e) => e.name)

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.brandDetails = res
      }
    })

  }

}
