import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { StartupInvestorInfoInterface } from 'src/app/core/domain/startup-investor-dashboard.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { createSlug } from '../../utils/common-methods';
import { Params, Router } from '@angular/router';
import { ProfileService } from 'src/app/core/service/profile.service';

@Component({
  selector: 'app-dashboard-common',
  templateUrl: './dashboard-common.component.html',
  styleUrls: ['./dashboard-common.component.scss']
})
export class DashboardCommonComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() data: StartupInvestorInfoInterface[];
  @Input() accountTypes: ACCOUNT_TYPE;
  @Input() queryParams: Params;
  ACCOUNT_TYPE = ACCOUNT_TYPE;
  public globalSettings$: Observable<IGlobalSettings>;
  public avatar = '';
  public s3LogoUrl: string = '';
  public globalSettings: IGlobalSettings;
  private destroyed$: Subject<void> = new Subject();
  public updatedList: StartupInvestorInfoInterface[];

  public alertType: string = "info";

  constructor(private readonly store: Store,
    private profileService: ProfileService,
    private router: Router) { }

  ngOnInit(): void {
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));

    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.globalSettings = res; this.createS3LogoUrl(); }
    })
    this.getUpdatedData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getUpdatedData();
  }

  getUpdatedData() {
    if (this.data && this.data.length > 0) {
      this.updatedList = this.data.map((row: StartupInvestorInfoInterface) => {
        this.avatar = row.logo;
        this.createS3LogoUrl();
        let profilePageUrl = (this.accountTypes === ACCOUNT_TYPE.STARTUP ? `/startups/profile/` : `/investors/profile/`)
          + `${row.uuid}/${createSlug(row.name)}`

          if(this.accountTypes === ACCOUNT_TYPE.CORPORATE)  {
            profilePageUrl =  `/corporates/profile/` + `${row.uuid}/${createSlug(row.name)}`
          }

          if(this.accountTypes === ACCOUNT_TYPE.MENTOR)  {
            profilePageUrl =  `/mentors/profile/` + `${row.uuid}/${createSlug(row.name)}`
          }

          if(this.accountTypes === ACCOUNT_TYPE.SERVICE_PROVIDER)  {
            profilePageUrl =  `/service-provider/profile/` + `${row.uuid}/${createSlug(row.name)}`
          }
        return {
          ...row,
          logo: this.s3LogoUrl,
          pageRoute: profilePageUrl
        }
      })
    }
  }

  nagivate(pageRoute, q = {}) {
    if(!this.profileService.isProfileLocked){
      this.router.navigate([pageRoute], {queryParams: q})
    }
  }

  tooltipDisabledProfileLock() {
    if (this.profileService.isProfileLocked) {
      return "To access this section, you need to have your profile approved.";
    }
    return ''
  }

  get viewMoreLink ()  {
    if(this.accountTypes  === ACCOUNT_TYPE.STARTUP) {
      return '/search/startups'
    }
    if(this.accountTypes  === ACCOUNT_TYPE.INVESTOR) {
      return '/search/investors'
    }
    if(this.accountTypes  === ACCOUNT_TYPE.CORPORATE) {
      return '/search/corporates'
    }
    if(this.accountTypes  === ACCOUNT_TYPE.MENTOR) {
      return '/search/mentors'
    }
    if(this.accountTypes  === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      return '/search/service-providers'
    }
  }

  createS3LogoUrl = () => {
    if (this.globalSettings?.imgKitUrl && this.avatar) {
      this.s3LogoUrl = this.avatar + '?tr=w-100,h-100,cm-pad_resize';
    } else {
      this.s3LogoUrl = '';
    }
  }

}
