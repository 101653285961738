import { Component, OnInit, Output, EventEmitter, Input, Injectable, OnDestroy } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateStruct, NgbModal, NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import { IMeetingAvailability, IMeetingPatchModel, METTING_AVAILABILITY, MeetingTime } from 'src/app/core/domain/meetings.model';
import * as moment from 'moment-timezone';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil, tap } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import { ProfileService } from 'src/app/core/service/profile.service';
import { add } from 'date-fns';
import { calculateSlots, convertToLocalTime, createTimeSlots, findNextAvailableDate, generateTimeSlots, generateTimeSlotsWithAMPM, isDisabledDateForMeeting } from 'src/app/shared/utils/common-methods';
import { MilestonesService } from 'src/app/core/service/milestone.service';
import { accountTypelabelMap } from 'src/CONSTS';

import { getBrandDetails } from 'src/app/core/state/global';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { NgbTimeStringAdapter } from '../add-meeting-button/add-meeting-modal/add-meeting-modal.component';
import { FacilitiesService } from 'src/app/core/service/facilities.service';
import { IBookingByFaciilityUUID, IFacility } from 'src/app/core/domain/facility.model';
import { ACCOUNT_TYPE, OrganizationInformationResponseModel } from 'src/app/core/domain';
import { getCorporateInfo } from 'src/app/core/state/corporate-info';
import { getIndividualProfileInfo } from 'src/app/core/state/individual-profile-info';
import { getInvestorsOrg } from 'src/app/core/state/investors-organization-info';
import { getMentorInfo } from 'src/app/core/state/mentor-info';
import { getPartnerInfo } from 'src/app/core/state/partner-info';
import { getProgramOfficeInfo } from 'src/app/core/state/program-office-members-info';
import { getServiceProviderInfo } from 'src/app/core/state/service-provider-info';
import { getStartUpInfo } from 'src/app/core/state/startup';
import * as InvestorsOrgInfoActionActions from "src/app/core/state/investors-organization-info/investors-organization-info.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import * as CorporateActions from "src/app/core/state/corporate-info/corporate-info.action";
import * as MentorActions from "src/app/core/state/mentor-info/mentor-info.action";
import * as ServiceProviderActions from "src/app/core/state/service-provider-info/service-provider-info.action";
import * as PartnerActions from "src/app/core/state/partner-info/partner-info.action";
import * as ProgramOfficeActions from "src/app/core/state/program-office-members-info/program-office-members-info.action";
import * as IndividualActions from "src/app/core/state/individual-profile-info/individual-profile-info.action";
import * as ProfileActions from "src/app/core/state/profile/profile.action";

const pad = (i: number): string => i < 10 ? `0${i}` : `${i}`;

@Component({
  selector: 'app-book-facility',
  templateUrl: './book-facility.component.html',
  styleUrls: ['./book-facility.component.scss'],
  providers: [
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
  ]
})
export class BookFacilityComponent implements OnInit, OnDestroy {

  model: NgbDateStruct;
  endDateModel: NgbDateStruct

  time = { hour: 13, minute: 30 };

  meetingForm: FormGroup;
  @Input() facility: IFacility
  @Input() modalData: {
    otherUser: {
      userUUID?: string;
      uid?: string;
      name: string;
    };
  };
  minDate;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();
  // accountTypelabelMap = accountTypelabelMap
  // companyName = '';

  // userProfileData;
  calenderAvilablity: IMeetingAvailability;
  disabledDaysIndex = []
  timeSlots: Array<{
    label: string,
    value: string
  }> = []
  endTimeSlots: Array<{
    label: string,
    value: string
  }> = []
  METTING_AVAILABILITY = METTING_AVAILABILITY;
  // reviewers = [];
  // selectedMeetingWithUser;
  brandDetails: Partial<IBrandDetails>;
  facilityBookedForDay;
  facilityBookedForDayCalculatedOnFrontend;
  allBookingsOfFacility: IBookingByFaciilityUUID[] = []
  constructor(
    private meetingService: MeetingService,
    private toastAlertService: ToastAlertService,
    private modal: NgbModal,
    private ngxLoaderService: NgxUiLoaderService,
    private store: Store,
    private profileService: ProfileService,
    private calendar: NgbCalendar,
    private facilitiesService: FacilitiesService
  ) { }

  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res;



        this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(async (res) => {
          if (res) {
            if(!res.email) {
              this.store.dispatch(new ProfileActions.GetProfile());
            }
            this.profileData = res;
            if(this.profileData &&this.profileData.email) {
              await this.getMeetingAvailability(this.facility.uuid)

              this.getMeetingCompanyName();

            }
        }})

        // this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(async (res) => {
        //   if (res) {
        //     this.profileData = res;
        //     await this.getMeetingAvailability(this.facility.uuid)

        //     this.getMeetingCompanyName();

        //     // if (this.modalData.otherUser) {
        //     //   this.getCompanyDetails()
        //     // } else {
        //     // this.getReviewers();
        //     // this.setMeetingForm()
        //     // }
        //   }
        // })
      }
    })

    this.facilitiesService.getAllBookingsOfFacilityId(this.facility.uuid).subscribe((res)=> {
      console.log('availablit', res)
      if(res) {
        this.allBookingsOfFacility = res;
      }
    })
    // this.getCalenderAvilablity();
  }

  checkFacilityBookedForDay(startDateParam = this.model, endDateParam = this.endDateModel) {
    if (this.calenderAvilablity?.availabilityTimeType === 'day' && this.allBookingsOfFacility?.length) {
      const date = startDateParam;
      const endDate = endDateParam;

      const monthPadded = date.month.toString().padStart(2, '0');
      const datePadded = date.day.toString().padStart(2, '0');
      const dateFormatted = `${date.year}-${monthPadded}-${datePadded}`;

      const endMonthPadded = endDate.month.toString().padStart(2, '0');
      const endDatePadded = endDate.day.toString().padStart(2, '0');
      const endDateFormatted = `${endDate.year}-${endMonthPadded}-${endDatePadded}`;

      const dateToCheck = moment(dateFormatted, 'yyyy-MM-DD');
      const endDateToCheck = moment(endDateFormatted, 'yyyy-MM-DD');

      const booked = this.allBookingsOfFacility.find((e) => {
        const startDate = moment(e.startDate, 'yyyy-MM-DD');
        const endDate = moment(e.endDate, 'yyyy-MM-DD');

        // Check if the booking dates are within the passed startDateParam and endDateParam
        if (
          startDate.isBetween(dateToCheck, endDateToCheck, 'date', '[]') ||
          endDate.isBetween(dateToCheck, endDateToCheck, 'date', '[]') ||
          (startDate.isSameOrBefore(dateToCheck) && endDate.isSameOrAfter(endDateToCheck)) // Covers fully overlapping booking
        ) {
          return true;
        }
      });

      this.facilityBookedForDayCalculatedOnFrontend = !!booked;
    } else {
      this.facilityBookedForDayCalculatedOnFrontend = false;
    }
  }

  facilityBookedForHour = false;
  checkFacilityBookedForHour(startSlot = null, endSlot = null) {
    if (this.calenderAvilablity?.availabilityTimeType !== 'day' && this.allBookingsOfFacility?.length) {
      const formValues = this.meetingForm.value;

      // Use form values if startSlot or endSlot is not provided
      if (!startSlot) {
        startSlot = formValues['timeFrom'];
      }
      if (!endSlot) {
        endSlot = formValues['timeTo'];
      }

      // Convert startSlot and endSlot into moment objects for easier comparison (ignore date)
      const startTimeToCheck = moment(startSlot, 'HH:mm');
      const endTimeToCheck = moment(endSlot, 'HH:mm');

      const booked = this.allBookingsOfFacility.find((booking) => {
        // Only check bookings that are of type "hour"
        if (booking.bookingTimeType === 'hour') {
          // Convert booking times (ignoring the date)
          const bookingStartTime = moment(booking.startTime, 'HH:mm:ss');
          const bookingEndTime = moment(booking.endTime, 'HH:mm:ss');

          // Check if the booking overlaps with the provided startSlot and endSlot
          if (
            startTimeToCheck.isBetween(bookingStartTime, bookingEndTime, null, '()') ||
            endTimeToCheck.isBetween(bookingStartTime, bookingEndTime, null, '()') ||
            (startTimeToCheck.isSameOrBefore(bookingStartTime) && endTimeToCheck.isSameOrAfter(bookingEndTime)) // Full overlap
          ) {
            return true;
          }
        }
      });

      this.facilityBookedForHour = !!booked;
    } else {
      this.facilityBookedForHour = false;
    }
  }


  isDisabled = (date: NgbDate, current: { month: number }) => {
    if(this.calenderAvilablity?.availabilityTimeType==='day' && this.allBookingsOfFacility?.length) {
      const monthPadded = date.month.toString().padStart(2, '0')
      const datePadded = date.day.toString().padStart(2, '0')
      const dateFormatted = `${date.year}-${monthPadded}-${datePadded}`

      const dateToCheck = moment(dateFormatted, 'yyyy-MM-DD')
      const booked = this.allBookingsOfFacility.find((e)=> {
        const startDate= moment(e.startDate, 'yyyy-MM-DD')
        const endDate= moment(e.endDate, 'yyyy-MM-DD')
        if(dateToCheck.isBetween(startDate, endDate, 'date', "[]")) {
          return true
        }
      })
      if(booked) {
        return true
      }
    }
    return isDisabledDateForMeeting(date, this.calenderAvilablity, this.calendar, this.disabledDaysIndex)
  };



  async getMeetingAvailability(uid: string) {
    const res = await this.facilitiesService.getUsersAvailabilityById(uid).toPromise()
    console.log('ress-----', res)
    // subscribe((res)=>{
    this.calenderAvilablity = res.data || {};
    // console.log('this.calenderAvilablity',this.calenderAvilablity)
    if (this.calenderAvilablity.availabilitySettings === METTING_AVAILABILITY.specific_days) {
      this.disabledDaysIndex = this.calenderAvilablity.days.filter((e) => e.closed).map(e => e.dayIndex)
      console.log('this.disabledDaysIndex', this.disabledDaysIndex)
    } else if (this.calenderAvilablity.availabilitySettings === METTING_AVAILABILITY.anytime) {
      this.timeSlots = generateTimeSlotsWithAMPM(this.calenderAvilablity.hourlySlotTime || this.brandDetails?.features?.meeting_time_slot_difference_in_mins);
    }
    // })
  }


  getCompanyDetails() {
    const uid = this.modalData.otherUser.uid || this.modalData.otherUser.userUUID
    this.profileService.getUserPublicProfile(uid).subscribe(async (res) => {
      if (res) {
        await this.getMeetingAvailability(uid)
      }
    }, (error) => {
    })

  }


  setMeetingForm() {
    console.log('setMeetingFormsetMeetingFormsetMeetingForm')
    const leapDays = this.calenderAvilablity.availabilityTimeType === 'hour' ? 0 : this.calenderAvilablity.leapTime;
    let current = new Date();
    if(leapDays) {
      current = moment().add(leapDays, 'days').toDate()
    }
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    this.meetingForm = new FormGroup({
      date: new FormControl('', [Validators.required]),
      endDate: new FormControl('', this.calenderAvilablity.availabilityTimeType === 'hour' ? [] : [Validators.required]),
      timeFrom: new FormControl(null, this.calenderAvilablity.availabilityTimeType === 'hour' ? [Validators.required] : []),
      timeTo: new FormControl(null, this.calenderAvilablity.availabilityTimeType === 'hour' ? [Validators.required] : []),
      // meetingTitle: new FormControl('', [Validators.required]),
      meetingDescription: new FormControl('', []),
      bookingPocName: new FormControl(this.profileData.name, []),
      bookingPocEmail: new FormControl(this.profileData.email, []),
      bookingPocContactNumber: new FormControl(this.profileData.mobileNumber, []),
      bookingPoCOrgName: new FormControl(this.companyName, []),
      bookingPoCDesignation: new FormControl(this.profileData.accountRole, []),
      // otherUserUUID: new FormControl(''),
      // duration: new FormControl('30', [Validators.required]),
    });

    // if (this.modalData.otherUser) {
    this.meetingForm.get('date').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe(async(date) => {
        this.meetingForm.get('timeTo').reset()
        this.meetingForm.get('timeFrom').reset()
        if (this.calenderAvilablity.availabilitySettings === METTING_AVAILABILITY.specific_days || this.calenderAvilablity.availabilitySettings === METTING_AVAILABILITY.anytime) {
          this.timeSlots = await this.calculateSlots(date)
          this.checkFacilityBookedForDay(date)
        }
      })
    this.meetingForm.get('endDate').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((date) => {
        this.meetingForm.get('timeTo').reset()
        this.meetingForm.get('timeFrom').reset()
        if (this.calenderAvilablity.availabilitySettings === METTING_AVAILABILITY.specific_days || this.calenderAvilablity.availabilitySettings === METTING_AVAILABILITY.anytime) {
          this.checkFacilityBookedForDay(this.model, date)
        }
      })

    this.meetingForm.get('timeFrom').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe(async (change) => {
        const values = this.meetingForm.value
        const endAllTimeSlots = await this.calculateSlots(values.date, true)
        const index = endAllTimeSlots.findIndex((t) => {
          return t.value === change
        })
        this.endTimeSlots = endAllTimeSlots.slice(index+1)
        // remove slots with < time
        this.endTimeSlots = this.endTimeSlots.filter(slot => moment(slot.value, "HH:mm").isAfter(moment(change, "HH:mm")));
        this.checkFacilityBookedForHour(change)

      })
    this.meetingForm.get('timeTo').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((change) => {
        this.checkFacilityBookedForHour(null, change)

      })
    const today = add(new Date(), {
      hours: 1,
    });
    today.setMinutes(0);
    const startTime = today.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);

    today.setHours(today.getHours() + 2)
    const endTime = today.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);


    const nextAvailableDate = findNextAvailableDate(
      this.calenderAvilablity,
      this.calendar,
      this.disabledDaysIndex,
      current
    )
    if (nextAvailableDate) {
      console.log('next ava', nextAvailableDate, current)
      this.model = nextAvailableDate
      console.log('calenderAvilablity abcd', this.calenderAvilablity, this.disabledDaysIndex)
    }
  }


  companyName = ''
  getMeetingCompanyName() {
    console.log('getMeetingCompanyNamegetMeetingCompanyName', this.profileData)
    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {

      this.store.pipe(select(getInvestorsOrg)).subscribe((response: OrganizationInformationResponseModel) => {
        if (response) {
          this.companyName = response.data.organizationName
          this.setMeetingForm()
        }else{
          this.store.dispatch(new InvestorsOrgInfoActionActions.GetInvestorsOrgInfo);
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {

      const startupInfo$ = this.store.pipe(select(getStartUpInfo));
      console.log('startupInfo$')
      startupInfo$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.companyName = res.companyName
          console.log('startupInfo comp', this.companyName)
          this.setMeetingForm()
        }else{
          this.store.dispatch(new StartupActions.GetStartUpInfo);
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.pipe(select(getCorporateInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.companyName = res.data.companyName
          this.setMeetingForm()
        }else{
          this.store.dispatch(new CorporateActions.GetCorporateInfo);
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
      this.store.pipe(select(getMentorInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.companyName = res.data.name
          this.setMeetingForm()
        }else{
          this.store.dispatch(new MentorActions.GetMentorInfo);
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      this.store.pipe(select(getServiceProviderInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.companyName = res.data.name
          this.setMeetingForm()
        }else{
          this.store.dispatch(new ServiceProviderActions.GetServiceProviderInfo);
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.PARTNER) {

      this.store.pipe(select(getPartnerInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((response) => {
        if (response) {
          this.companyName = response.data.name
          this.setMeetingForm()
        }else{
          this.store.dispatch(new PartnerActions.GetPartnerInfo);
        }
      })
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {

      this.store.pipe(select(getProgramOfficeInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((response) => {
        if (response) {
          this.companyName = response.data.name
          this.setMeetingForm()
        }else{
          this.store.dispatch(new ProgramOfficeActions.ProgramOfficeInfo);
        }
      })
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.INDIVIDUAL) {

      this.store.pipe(select(getIndividualProfileInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((response) => {
        if (response) {
          this.companyName = response.data.name
          this.setMeetingForm()
        }else{
          this.store.dispatch(new IndividualActions.GetIndividualProfileInfo);
        }
      })
    }
  }

  async calculateSlots(date, calculatingEndTime = false) {

    const uid = this.facility.uuid
    const month = ('' + date.month).length === 2 ? date.month : '0' + date.month
    const day = ('' + date.day).length === 2 ? date.day : '0' + date.day
    const selectedDateFormatted = `${date.year}-${month}-${day}`

    const dateAvailability = await this.facilitiesService.getAvailabilityByDate(uid, selectedDateFormatted).toPromise()
    console.log('dateAvailability', dateAvailability)
    if(this.calenderAvilablity.availabilityTimeType === 'day' && dateAvailability?.length) {
      this.facilityBookedForDay = true
    }else{
      this.facilityBookedForDay = false
    }

    // this.timeSlots =
    let newTimeSlots = await calculateSlots({
      date,
      brandDetails: this.brandDetails,
      calendar: this.calendar,
      calenderAvilablity: this.calenderAvilablity,
      dateAvailability,
      timeSlots: this.timeSlots,
      localtime: true,
      bufferTime: this.calenderAvilablity.availabilityTimeType === 'hour' ? this.calenderAvilablity.leapTime :0,
      calculatingEndTime
    })
    return newTimeSlots
    // console.log('this.timeSlots', this.timeSlots)
  }


  onSubmit(): void {
    const payload: IMeetingPatchModel = {
      ...this.meetingForm.value,
      meetingTimeType: MeetingTime.schedule_later,
    }
    // return
    const { date, endDate, bookingPocName, bookingPocEmail,
      bookingPocContactNumber, bookingPoCOrgName, bookingPoCDesignation
     } = this.meetingForm.value;
    payload.date = `${date.year}-${date.month}-${date.day}`

    // const dateFrom = new Date(`${date.year}-${date.month}-${date.day} ${payload.timeFrom}`).toUTCString()
    // const dateTo = new Date(`${date.year}-${date.month}-${date.day} ${payload.timeTo}`).toUTCString()

    const utcTimeFrom = moment(`${date.year}-${date.month}-${date.day} ${payload.timeFrom}`, 'YYYY-MM-DD HH:mm')
    // const sqlDateFrom = utcTimeFrom.utc().format("YYYY-MM-DD")
    // const sqlTimeFrom = utcTimeFrom.utc().format("HH:mm")
    const sqlDateFrom = utcTimeFrom.format("YYYY-MM-DD")
    const sqlTimeFrom = utcTimeFrom.format("HH:mm")


    const endDateToConsider = this.calenderAvilablity.availabilityTimeType === 'hour' ? date : endDate;
    const utcTimeTo = moment(`${endDateToConsider.year}-${endDateToConsider.month}-${endDateToConsider.day} ${payload.timeTo}`, 'YYYY-MM-DD HH:mm')
    // const sqlDateTo = utcTimeTo.utc().format("YYYY-MM-DD")
    // const sqlTimeTo = utcTimeTo.utc().format("HH:mm")
    const sqlDateTo = utcTimeTo.format("YYYY-MM-DD")
    const sqlTimeTo = utcTimeTo.format("HH:mm")

    console.log('sqlDateFrom', sqlDateFrom, 'sqlTimeFrom', sqlTimeFrom);
    console.log('sqlDateFrom', sqlDateTo, 'sqlTimeFrom', sqlTimeTo, utcTimeTo);


    let payloadNew: any = {
      bookingPocName, bookingPocEmail,
      bookingPocContactNumber, bookingPoCOrgName, bookingPoCDesignation
      // ...this.meetingForm.value,
      // meetingTimeType: MeetingTime.schedule_later,
      // date: sqlDateFrom,
      // timeFrom: sqlTimeFrom,
      // timeTo: sqlTimeTo,
      // offset: '' + new Date().getTimezoneOffset(),
      // timeZone: moment.tz.guess(),

    }
    if (this.calenderAvilablity.availabilityTimeType === 'hour') {
      payloadNew = {
        ...payloadNew,
        "startDate": sqlDateFrom,
        "startTime": sqlTimeFrom,
        "endDate": sqlDateTo,
        "endTime": sqlTimeTo,
        "comments": this.meetingForm.value.meetingDescription,
        // offset: '' + new Date().getTimezoneOffset(),
        // timeZone: moment.tz.guess(),
      }
    } else {
      payloadNew = {
        ...payloadNew,
        "startDate": sqlDateFrom,
        "startTime": "00:00",
        "endDate": sqlDateTo,
        "endTime": "00:00",
        "comments": this.meetingForm.value.meetingDescription,
        // offset: '' + new Date().getTimezoneOffset(),
        // timeZone: moment.tz.guess(),
      }
    }
    console.log('payload new', payloadNew)
    // return
    // new
    this.ngxLoaderService.start();
    this.facilitiesService.bookFacility(this.facility.uuid,payloadNew).subscribe((response) => {
      if (response) {
        this.toastAlertService.showToast('Booking request submitted successfully');
        this.close();
        this.ngxLoaderService.stop();
      }
    }, err => {
      this.ngxLoaderService.stop();
    })
  }


  close() {
    this.modal.dismissAll();
  }

  onTimeToChange($event) {
    console.log(';event', $event)
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


}
