import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICommunityFeed, IFeedComment, ReactionType } from 'src/app/core/domain/community-feed';
import { IReactions, Reaction } from 'src/app/core/domain/reaction.model';
import { CommunityFeedService } from 'src/app/core/service/community-feed.service';
import { ProfileService } from 'src/app/core/service/profile.service';

@Component({
  selector: 'app-reactions-modal',
  templateUrl: './reactions-modal.component.html',
  styleUrls: ['./reactions-modal.component.scss']
})
export class ReactionsModalComponent implements OnInit {
  @Input() post: ICommunityFeed;
  @Input() comment: IFeedComment;
  activeMeetingsTab = 0;
  reactions: IReactions;

  listReactions = []
  selectedList: Reaction[] = []

  cssIdToReactionMap = {
    [ReactionType.LIKE]: 'home',
    [ReactionType.LOVE]: 'home2',
    [ReactionType.LAUGH]: 'home3',
    [ReactionType.SURPRISE]: 'home4',
    [ReactionType.SUPPORT]: 'home5',
    [ReactionType.DIS_LIKE]: 'home6',
  }

  constructor(
    private feedService: CommunityFeedService,
    private modalService: NgbModal,
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    if(this.comment) {
      this.reactions = this.comment.replies as any;
    }else{
      this.getReactionsOnPost()
    }
  }

  getReactionsOnPost() {
    this.feedService.getReactionsPost(this.post.uuid).subscribe((res) => {
      if (res) {
        console.log('comments', res);
        this.reactions = res;

        this.selectedList = this.reactions.reactions;
        this.selectedList.forEach((item) => {
          item.user.profileUrl = this.profileService.getProfileLink(item.user.organizationUUID || item.user.uuid, item.user.organizationName, item.user.accountType)
        })


        this.listReactions = [
          {
            id: 'All',
            index: 0,
            count: this.reactions.stats.totalReactions
            // key: ReactionType.LIKE,
            // count: this.reactions.stats.reactionCount[ReactionType.LIKE]
          },
          {
            id: 'home',
            index: 1,
            key: ReactionType.LIKE,
            count: this.reactions.stats.reactionCount[ReactionType.LIKE]
          },
          {
            id: 'home2',
            index: 2,
            key: ReactionType.LOVE,
            count: this.reactions.stats.reactionCount[ReactionType.LOVE]
          },
          {
            id: 'home3',
            index: 3,
            key: ReactionType.LAUGH,
            count: this.reactions.stats.reactionCount[ReactionType.LAUGH]
          },
          {
            id: 'home4',
            index: 4,
            key: ReactionType.SURPRISE,
            count: this.reactions.stats.reactionCount[ReactionType.SURPRISE]
          },
          {
            id: 'home5',
            index: 5,
            key: ReactionType.SUPPORT,
            count: this.reactions.stats.reactionCount[ReactionType.SUPPORT]
          },
          {
            id: 'home6',
            index: 6,
            key: ReactionType.DIS_LIKE,
            count: this.reactions.stats.reactionCount[ReactionType.DIS_LIKE]
          },
        ];
        this.listReactions = this.listReactions.filter((e) => e.count > 0 || e.id == 'All')
      }
    })
  }

  onSelect(item) {
    this.activeMeetingsTab = item.index;
    this.selectedList = this.reactions.reactions.filter((e) => e.reactionType === item.key)
    if (item.id === 'All') {
      this.selectedList = this.reactions.reactions;
    }
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }
}
