<div class="row g-6 g-xl-9" *ngIf="investors?.length"
[ngClass]="{'mb-8': !simpleUI}"
>
  <!-- <h2>
    Recommended Investors
  </h2> -->
  <div class="d-flex flex-wrap" *ngIf="!simpleUI">
    <span class="circle"></span>
    <h1 class="fs-1hx text-dark mb-5  ms-6">Recommended Investors</h1>
  </div>

  <swiper #swiper [spaceBetween]="30" [navigation]="false" [autoplay]="true" [virtual]="true" [pagination]="{
      clickable: true
    }" class="recommended-investor-slider"
      [ngClass]="{'simple-ui': simpleUI}"
      (mouseenter)="onMouseenter()" (mouseleave)="onMouseleave()" [breakpoints]="{
        '320': {
            slidesPerView: 2,
            spaceBetween: 20
        },
        '640': {
          slidesPerView: 2,
          spaceBetween: 20
        },
        '768': {
          slidesPerView: 3,
          spaceBetween: 30
        },
        '1024': {
          slidesPerView: 4,
          spaceBetween: 30
        }
      }">


    <ng-template swiperSlide *ngFor="let investor of investors">
      <app-investors-search-card [avtarSize]="'small'" [openedFrom]="'recommended'" [isShowCompareButton]="false" [investor]="investor"
      (hideRecommendedInvestor)="hideInvestor($event)"
      ></app-investors-search-card>
    </ng-template>
  </swiper>




</div>

<div class="text-center mt-10 mb-10" *ngIf="!investors?.length && simpleUI">
  <i class="bi bi-exclamation-circle" style="font-size: 60px;">
  </i><br><br>
  <span class="fs-5 text-center my-4  text-gray-500">
    No Data Found
  </span>
</div>
