import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-layout-wrapper',
  templateUrl: './public-layout-wrapper.component.html',
  styleUrls: ['./public-layout-wrapper.component.scss']
})
export class PublicLayoutWrapperComponent implements OnInit {
  pageScrolled: boolean = false;
  showMobileNavBar: string  = "off";
  currentPosition = window.pageYOffset;

  constructor() {

  }

  ngOnInit(): void {

  }

  @HostListener('window:scroll', ['$event']) onWindowScroll(e: any) {
    let scroll = e.target['scrollingElement'].scrollTop;

    if (scroll > this.currentPosition) {
      this.pageScrolled = true;
    }
    this.currentPosition = scroll;
    if(this.currentPosition == 0){
      this.pageScrolled = false;
      this.showMobileNavBar = "off";
    }else{
      this.showMobileNavBar = "on";
    }

    console.log(this.showMobileNavBar);
  }
}
