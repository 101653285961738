<div class="card h-xl-100">

  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label fw-bolder text-dark fs-3" [innerHTML]="title"></span>
    </h3>
  </div>


  <div class="card-body pt-5">

    <ng-template [ngIf]="data && updatedList.length > 0" [ngIfElse]="empty">

      <div class="d-flex align-items-sm-center justify-content-between mb-7" *ngFor="let data of updatedList"
        container="body" triggers="mouseenter:mouseleave" popoverClass="popover-custom-width">

        <div class="d-flex ">
          <div class="symbol symbol-50px me-5 dashboard-common-avatar" style="height: 50px; width: 50px;">
            <ngx-avatars *ngIf="!data.originalLogo" fgColor="#fff" bgColor="var(--color-theme)" initialsSize="2" size="50" [name]="data.corporateName"></ngx-avatars>

            <img *ngIf="data.originalLogo && data.corporateName !== 'confidential'" src="{{data.corporateLogo | imagekitUrlAppender}}"
              class="h-100" alt="image">
            <img *ngIf="data.originalLogo && data.corporateName === 'confidential'"
              src="{{'/images/confidential.png?tr=w-150,h-150,cm-pad_resize'| assetsImagekitUrlAppender}}"
              class="h-100 p-2" alt="image">
          </div>


          <div class="d-flex align-items-center flex-row-fluid flex-wrap">
            <div class="flex-grow-1 me-2">
              <a [href]="'/search/challenges/' + data.challengeUUID" target="_blank"
                class="text-gray-800 text-hover-primary fs-4 fw-bolder">{{data.challengeTitle}}</a>
              <span class="text-muted fw-bold d-block fs-7">
                Deadline: {{data.deadLine| date: 'longDate' }}
              </span>
            </div>
          </div>
        </div>

        <div>
          <div *ngIf="data.isApplied" class="badge badge-secondary">
            {{'Applied'}}
          </div>
          <a *ngIf="!data.isApplied" [href]="'/search/challenges/' + data.challengeUUID" target="_blank"
            class="text-white badge badge-primary cursor-pointer">
            {{'Apply'}}
          </a>
        </div>

      </div>

      <a [routerLink]="'/search/challenges'"
        class="text-center btn-outline border-secondary w-100 btn text-hover-primary">
        View more
      </a>
    </ng-template>
    <ng-template #empty>
      <div class="d-flex align-items-sm-center mb-7">
        <div class="alert alert-primary alert-width-100 text-center w-100" role="alert">
          No results found
        </div>
      </div>
    </ng-template>

  </div>

</div>
