<ng-container *ngIf="!profileCompleteness?.isApproved">
  <app-startup-investor-dashboard-profile [profileCompleteness]="profileCompleteness"></app-startup-investor-dashboard-profile>
</ng-container>

<ng-container  *ngIf="profileCompleteness?.isApproved">
  <div class="card card-flush">
    <div
      class="card-header bg-primary rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-210px">
      <h3 class="card-title align-items-start flex-column text-white pt-10">


        <div class="fs-4 text-white mb-3">
          {{greetingMessagetext}},
        </div>
        <span class="fw-bolder fs-2 mb-3">{{profileData?.name}}</span>
        <span class="">
          Your profile is <b>{{profileCompleteness?.percentage}}%</b> complete.
        </span>

      </h3>

      <div class="card-toolbar pt-5">
        <button [routerLink]="profilePageUrl"
          class="btn btn-sm btn-icon btn-active-color-primary btn-color-white bg-white bg-opacity-25 bg-hover-opacity-100 bg-hover-white bg-active-opacity-25 w-20px h-20px"
          *ngIf="!brandDetails?.features?.disable_startup_profile_update">
          <span class="svg-icon svg-icon-4">
            <i class="fa fa-edit"></i>
          </span>
        </button>
      </div>
    </div>

    <div class="card-body mt-n20">
      <div class="mt-n20 position-relative">
        <div class="row g-3 g-lg-6">
          <ng-container *ngFor="let count of countBoxes">
            <div [routerLink]="count.link" class="col-6 " *ngIf="!count.onlyShowIfNotZero || counts[count.countKey] > 0">
              <div
                class="bg-gray-100 bg-opacity-70 rounded-2 px-6 ps-4 pe-1 py-5 cursor-pointer"
              >
              <div class="d-flex justify-content-between align-items-center mb-4">
                <span
                class="text-gray-700 fw-boldest d-block fs-2qx lh-1 ls-n1 "
                [innerHTML]="counts[count.countKey]"
                ></span>
                <div class="symbol symbol-30px me-5 ">
                  <span class="symbol-label">
                    <span class="svg-icon">
                      <i class="{{count.icon}} {{counts[count.countKey] > 0 ? 'text-primary': 'text-gray-300'}}"></i>
                    </span>
                  </span>
                </div>
              </div>
                <div class="m-0">

                  <span class="text-dark fs-7"
                    >{{count.name}}</span
                  >
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- <button class="btn btn-dark text-white ls-2 text-uppercase fw-boldest btn-md mt-5 w-100"
        *ngIf="profileData?.accountType === ACCOUNT_TYPE.MENTOR"
        ngbTooltip="You can set your availability timings for upcoming meetings"
        routerLink="/calender" [queryParams]="{'openSetAvailability': 'true'}"
          >
          Update Availability
        </button> -->

      </div>
    </div>
  </div>
</ng-container>


