<div class="row g-6 g-xl-9 " *ngIf="investors?.length"
[ngClass]="{'mb-8': !simpleUI}">

  <!-- <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true" [interval]="3000">
        <ng-template ngbSlide *ngFor="let investors of chunkedInvestors">
            <div class="row g-6 g-xl-9">

                <div class="col-6 col-md-3 col-xxl-3" *ngFor="let investor of investors">
                    <app-startup-search-card [startup]="investor"></app-startup-search-card>
                </div>
            </div>

        </ng-template>

    </ngb-carousel> -->
    <div class="d-flex flex-wrap" *ngIf="!simpleUI">
      <span class="circle"></span>
      <h1 class="fs-1hx text-dark mb-5  ms-6">Recommended Mentors</h1>
    </div>


  <swiper #swiper  [virtual]="true" [spaceBetween]="30" [navigation]="true" [autoplay]="true" [pagination]="{
        clickable: true
      }" class="recommended-investor-slider"
      [ngClass]="{'simple-ui': simpleUI}"
      (mouseenter)="onMouseenter()" (mouseleave)="onMouseleave()" [breakpoints]="{
          '320': {
              slidesPerView: 2,
              spaceBetween: 20
          },
          '640': {
            slidesPerView: 2,
            spaceBetween: 20
          },
          '768': {
            slidesPerView: 3,
            spaceBetween: 30
          },
          '1024': {
            slidesPerView: 4,
            spaceBetween: 30
          }
        }">


    <ng-template swiperSlide *ngFor="let investor of investors">
      <!-- <app-startup-search-card [avtarSize]="'small'" [disableHover]="true" [isShowCompareButton]="false"
      [openedFrom]="'recommended'" (hideRecommendedStartup)="hideStartup($event)"
        [startup]="investor"></app-startup-search-card> -->
        <app-mentor-search-card [corporate]="investor">

        </app-mentor-search-card>
    </ng-template>
  </swiper>



</div>
<div class="text-center mt-10 mb-10" *ngIf="!investors?.length && simpleUI">
  <i class="bi bi-exclamation-circle" style="font-size: 60px;">
  </i><br><br>
  <span class="fs-5 text-center my-4  text-gray-500">
    No Data Found
  </span>
</div>
