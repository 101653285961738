import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard/startup.dashboard.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import { IJobs } from "../domain/jobs.model";
import * as JobsAction from 'src/app/core/state/jobs';
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class MentorshipService {

  constructor(private http: HttpClient, private toastService: ToastAlertService, private globalService: GlobalService, private store: Store,
    private router: Router) { }


  public getMentorShip(filters = {}): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MENTORSHIP);
    return this.http.get(url, { params: {
      ...filters
    } }).pipe(
      map((response: any): IJobs[] => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`Jobs( ${fault.error.message} )`);
        this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
        return throwError(() => fault);
      })
    )
  }
  public getMentorShipStartups(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MENTORSHIP) + 'startups';
    return this.http.get(url).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`Jobs( ${fault.error.message} )`);
        this.toastService.showToast(fault?.error?.message || 'Error while fetching startups', 'error');
        return throwError(() => fault);
      })
    )
  }
  public getMentorShipMentors(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MENTORSHIP) + 'mentors';
    return this.http.get(url).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`Jobs( ${fault.error.message} )`);
        this.toastService.showToast(fault?.error?.message || 'Error while fetching mentors', 'error');
        return throwError(() => fault);
      })
    )
  }

  public getMentorShipStats(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MENTORSHIP) + 'stats';
    return this.http.get(url).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`Jobs( ${fault.error.message} )`);
        this.toastService.showToast(fault?.error?.message || 'Error while fetching startups', 'error');
        return throwError(() => fault);
      })
    )
  }

  public createManualEntry(payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MENTORSHIP) + 'manual-entry';
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.toastService.showToast('Approval awaited');
      }),
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while logging hours'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public createAutoEntry(payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MENTORSHIP) + 'auto-entry';
    return this.http.post(url, payload).pipe(
      tap(() => {
        // this.toastService.showToast('Approval awaited');
      }),
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        // this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while logging hours'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public addRating(id, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MENTORSHIP) + 'ratings/' + id;
    return this.http.patch(url, payload).pipe(
      tap(() => {
        this.toastService.showToast('Ratings added');
      }),
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while adding rating'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public approveRejectHours(id, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MENTORSHIP) + 'approve-hours/' + id;
    return this.http.patch(url, payload).pipe(
      // tap(() => {
      //   this.toastService.showToast('Ratings added');
      // }),
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Something went wrong'), 'error');
        return throwError(() => fault);
      })
    );
  }


}
