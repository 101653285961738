<!--begin::Header-->
<div
  class="landing-header bg-light-orange"
  data-kt-sticky="true"
  data-kt-sticky-name="landing-header"
  data-kt-sticky-offset="{default: '200px', lg: '300px'}"
>
  <!--begin::Container-->
  <div class="container">
    <!--begin::Wrapper-->
    <div class="d-flex align-items-center justify-content-between">
      <!--begin::Logo-->
      <div class="d-flex align-items-center flex-equal">
        <!--begin::Mobile menu toggle-->
        <button
          class="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
          id="kt_landing_menu_toggle"
          (click)="open()"
        >
          <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
          <span class="svg-icon svg-icon-2hx">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                fill="currentColor"
              />
              <path
                opacity="0.3"
                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </button>
        <!--end::Mobile menu toggle-->
        <!--begin::Logo image-->
        <a href="#">
          <img
            alt="Logo"
            [lazyLoad]="'/images/logos/logo-2.svg' | assetsImagekitUrlAppender"
            [useSrcset]="true"
            class="logo-default h-25px h-lg-50px"
          />
          <img
            alt="Logo"
            [lazyLoad]="'/images/logos/logo-2.svg' | assetsImagekitUrlAppender"
            [useSrcset]="true"
            class="logo-sticky h-20px h-lg-40px"
          />
        </a>
        <!--end::Logo image-->
      </div>
      <!--end::Logo-->
      <!--begin::Menu wrapper-->
      <div class="d-lg-block" id="kt_header_nav_wrapper">
        <div
          class="d-lg-block p-5 p-lg-0"
          data-kt-drawer="true"
          data-kt-drawer-name="landing-menu"
          data-kt-drawer-activate="{default: true, lg: false}"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="200px"
          data-kt-drawer-direction="start"
          data-kt-drawer-toggle="#kt_landing_menu_toggle"
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
        >
          <!--begin::Menu-->
          <div
            class="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold"
            id="kt_landing_menu"
          >
            <!--begin::Menu item-->
            <div class="menu-item">
              <!--begin::Menu link-->
              <a
                class="menu-link nav-link py-3 px-4 px-xxl-6"
                routerLink="/home"
                routerLinkActive="active"
                data-kt-scroll-toggle="true"
                data-kt-drawer-dismiss="true"
                id="home_menu_v1"
                (click)="trackOnMixPanel('home_menu_v1')"
                >Home</a
              >
              <!--end::Menu link-->
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item">
              <!--begin::Menu link-->
              <a
                class="menu-link nav-link py-3 px-4 px-xxl-6"
                routerLink="/investors"
                routerLinkActive="active"
                data-kt-scroll-toggle="true"
                data-kt-drawer-dismiss="true"
                id="investors_menu_v1"
                (click)="trackOnMixPanel('investors_menu_v1')"
                >Investors</a
              >
              <!--end::Menu link-->
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item">
              <!--begin::Menu link-->
              <a
                class="menu-link nav-link py-3 px-4 px-xxl-6"
                routerLink="/startups"
                routerLinkActive="active"
                data-kt-scroll-toggle="true"
                data-kt-drawer-dismiss="true"
                id="startup_menu_v1"
                (click)="trackOnMixPanel('startup_menu_v1')"
                >Startups</a
              >
              <!--end::Menu link-->
            </div>
            <!--end::Menu item-->

            <!--begin::Menu item-->
            <!-- <div class="menu-item">
              <a class="menu-link nav-link py-3 px-4 px-xxl-6">Resources</a>
            </div> -->

            <!--begin::Menu wrapper-->
            <div ngbDropdown class="menu-item">
              <!--begin::Toggle-->
              <a
                ngbDropdownToggle
                class="menu-link nav-link py-3 px-4 px-xxl-6"
              >
                Resources
              </a>
              <!--end::Toggle-->

              <!--begin::Menu-->
              <div
                ngbDropdownMenu
                class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px py-3"
              >
                <!--begin::Menu item-->
                <div
                  class="menu-item px-3"
                  id="glossary_menu"
                  (click)="trackOnMixPanel('glossary_menu')"
                >
                  <a
                    routerLink="/glossary"
                    ngbDropdownItem
                    class="menu-link px-3"
                  >
                    Glossary
                  </a>
                </div>
                <!--end::Menu item-->

                <!--begin::Menu item-->
                <div
                  class="menu-item px-3"
                  id="resources_menu"
                  (click)="trackOnMixPanel('resources_menu')"
                >
                  <a
                    routerLink="/resources"
                    ngbDropdownItem
                    class="menu-link px-3"
                  >
                  Contracts & Legal Templates
                  </a>
                </div>
                <!--end::Menu item-->



                <div class="menu-item px-3" id="government-grants" (click)="trackOnMixPanel('government-grants')">
                  <a routerLink="/government-grants" ngbDropdownItem class="menu-link px-3">
                    Government Grants
                  </a>
                </div>


                <!--begin::Menu item-->
                <div
                  class="menu-item px-3"
                  id="faqs_menu"
                  (click)="trackOnMixPanel('faqs_menu')"
                >
                  <a routerLink="/faqs" ngbDropdownItem class="menu-link px-3">
                    FAQs
                  </a>
                </div>
                <!--end::Menu item-->


                <div class="menu-item px-3" id="resources_menu" (click)="trackOnMixPanel('startup-kit_menu')">
                  <a routerLink="/startup-kit" ngbDropdownItem class="menu-link px-3">
                    Startup Booster Kit
                  </a>
                </div>

              </div>
              <!--end::Menu-->
            </div>
            <!--end::Dropdown wrapper-->

            <!--begin::Menu item-->
            <div
              class="menu-item"
              id="blog_menu"
              (click)="trackOnMixPanel('blog_menu')"
            >
              <!--begin::Menu link-->
              <a
                class="menu-link nav-link py-3 px-4 px-xxl-6"
                href="https://sanchiconnect.com/blog"
                target="_blank"
                data-kt-scroll-toggle="true"
                data-kt-drawer-dismiss="true"
                >Blog</a
              >
              <!--end::Menu link-->
            </div>
            <!--end::Menu item-->

            <!-- <div ngbDropdown lass="menu-item">
              <div
                ngbDropdownToggle
                class="menu-link nav-link py-3 px-4 px-xxl-6"
              >
                Resources
              </div>
              <div ngbDropdownMenu>fsds</div>
            </div> -->

            <!--end::Menu item-->
          </div>
          <!--end::Menu-->
        </div>
      </div>
      <!--end::Menu wrapper-->
      <!--begin::Toolbar-->

      <div class="flex-equal text-end ms-1">
        <app-user-profile-menu
          [isPublicHeader]="true"
          *ngIf="profileData"
        ></app-user-profile-menu>
        <ng-container *ngIf="!profileData">
          <a
            routerLink="/auth/login"
            class="menu-link py-3 px-4 px-xxl-6 fs-5 text-gray-700"
            id="login_button"
            (click)="trackOnMixPanel('login_button')"
            >Login</a
          >
          <a
            routerLink="/auth/register"
            class="btn btn-theme text-white ls-2 text-uppercase fw-boldest"
            id="signup_button"
            (click)="trackOnMixPanel('signup_button')"
            >Sign Up</a
          >
        </ng-container>
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Container-->
</div>
<!--end::Header-->

<app-offcanvas-sidebar [menuList]="menuList"></app-offcanvas-sidebar>
