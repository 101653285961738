import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { interval, take } from 'rxjs';

export interface ICongratulationsScreenConfig {
  title: string;
  subtitle: string;
  callToActionButtonText: string;
  /**
   *  In Seconds
   */
  timeout: number;
}

@Component({
  selector: 'app-congratulations-animation',
  templateUrl: './congratulations-animation.component.html',
  styleUrls: ['./congratulations-animation.component.scss']
})
export class CongratulationsAnimationComponent implements OnInit {
  @ViewChild('successModal') successModal;

  @Input('config') config: ICongratulationsScreenConfig = {
    callToActionButtonText: 'Complete profile',
    subtitle: 'Your account is created',
    title: 'Congratulations!',
    timeout: 5
  };

  @Output() handleCallToAction: EventEmitter<any> = new EventEmitter();

  options: AnimationOptions = {
    path: '/assets/112854-congratulations.json',
  };

  successOptions: AnimationOptions = {
    path: '/assets/69021-success-interaction.json',
    loop: false
  };

  seconds;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.seconds = this.config.timeout;

    interval(1000).pipe(take(this.config.timeout || 5)).subscribe((res) => {
      this.seconds = this.seconds - 1;
      if ((res + 1) === (this.config.timeout ?? 5)) {
        this.emitCallToActionEvent();
      }
    })
  }

  emitCallToActionEvent() {
    this.handleCallToAction.emit();
    this.modalService.dismissAll();
  }


  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
    this.modalService.open(this.successModal, { size: 'sm', backdrop: true, centered: true })
  }

}
