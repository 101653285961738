<window *ngIf="selectedUrlDetails" [selectedUrlDetails]="selectedUrlDetails" (closed)="onWindowClose()">
</window>


<div class="d-flex justify-content-between align-items-center">
  <span class="svg-icon svg-icon-4 me-1 fs-4" *ngIf="!hideTitle">
    Share:
  </span>
  <img [lazyLoad]="'assets/images/facebook.png'" [useSrcset]="true" class="share-icons" alt=""
    (click)="handleShareOption('fb')" />
  <img [lazyLoad]="'assets/images/whats_app.png'" [useSrcset]="true" class="share-icons" alt=""
    (click)="handleShareOption('wp')" />
  <img [lazyLoad]="'assets/images/lnkedin.png'" [useSrcset]="true" class="share-icons" alt=""
    (click)="handleShareOption('linkedin')" />
  <img [lazyLoad]="'assets/images/x_logo.png'" [useSrcset]="true" class="share-icons" alt=""
    (click)="handleShareOption('twitter')" />
  <img [lazyLoad]="'assets/images/copy-icon.png'" *ngIf="!hideCopyButton" [useSrcset]="true" class="share-icons bg-white" alt=""
    ngbTooltip="Copy" (click)="handleShareOption('clipboard')" />
</div>
