<!-- <p>ad-viewer works!</p> -->
<ng-container *ngIf="placement && ads && ads[placement] && ads[placement].display.length">
  <!-- <img width="100%" alt="" class="ng-lazyloaded" [useSrcset]="true"
    [lazyLoad]="ads[placement].display[0].image| imagekitUrlAppender" /> -->
  <div class="mb-8">
    <ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="true" [interval]="3000">
      <ng-template ngbSlide *ngFor="let slide of ads[placement].display">
        <div class="text-center">
          <img style="max-height: 165px; width: 100%;height: 165px;" class="ng-lazyloaded" [useSrcset]="true" [class.cursor-pointer]="slide.link"
            [lazyLoad]="slide.image | imagekitUrlAppender" (click)="handleClick(slide)" />
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</ng-container>

<ng-template #modalAdViewer>
  <!-- <div class="modal"> -->

  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">{{ "Notification" }}</h4>
    <button type="button" class="btn-close text-white" aria-label="Close" (click)="handleCloseClick()"></button>
  </div>

  <div class="modal-body p-0">
    <!-- <ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="false"> -->
<!--
      <div class="modal-header  bg-primary">
        <h4 class="modal-title text-white">{{ 'Notification' }}</h4>
        <button type="button" class="btn-close text-white" aria-label="Close" (click)="handleCloseClick()"></button>
      </div> -->

      <div class="modal-body p-0">
        <ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="false">

          <ng-template ngbSlide *ngFor="let slide of ads[placement].modal">
            <div class="text-center">
              <img alt="" class="ng-lazyloaded cursor-pointer" [src]="slide.image | imagekitUrlAppender"
                (click)="handleClick(slide)" style="
              width: 100%;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            " />
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
      <!-- </div> -->
      <!-- </ng-template> -->
    <!-- </ngb-carousel> -->
  </div>
