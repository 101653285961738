<div class="card shadow-sm h-100">

  <div class="card position-relative">
    <div class="position-relative cursor-pointer" (click)="playVideo(item)">
      <img class="w-100 rounded-top" src="{{
      item.thumbNailImage
    }}" alt="Card image cap">

      <div class="position-absolute bottom-0 gradient"></div>

      <div class="icon-round cursor-pointer">
        <div class="bg-primary play d-flex justify-content-center align-content-center">
          <i class="bi bi-play-fill fs-2hx text-white"></i>
        </div>
      </div>
    </div>

    <div class="card-body px-4 py-3">
      <h5 class="card-title truncate-2-line" [ngbTooltip]="item.description" tooltipClass="desc-tooltip"
        container="body">{{item.title}}</h5>
      <p class="card-text text-gray-600 mb-1"><span class="text-black">Courtesy:</span> {{item.origin}}</p>
      <p class="card-text text-gray-600  mb-1"><span class="text-black">Speaker:</span> {{item.speakerName}}
      </p>
    </div>

  </div>


</div>
