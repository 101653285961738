<div class="modal-header bg-light-orange">
  <h4 class="modal-title"> Connect </h4>
  <button type="button" class="btn-close" (click)="closeModal()"></button>
</div>
<div class="modal-body">

  <div class="d-flex justify-content-center flex-column">

    <div class="w-100 d-flex align-items-center justify-content-around">
      <app-logo-renderer class="logo-default h-25px h-lg-40px"></app-logo-renderer>

      <img [src]="'assets/images/connect.svg'" width="12%" alt="" />
      <!-- <app-logo-renderer class="logo-default h-25px h-lg-40px"></app-logo-renderer> -->
      <img src="https://i0.wp.com/powerpitch.ai/wp-content/uploads/2023/09/Powerpitch-logo.png?w=250&ssl=1"
      class="logo-default h-25px h-lg-30px"/>
    </div>

    <p class="fs-4 text-center mt-8">

      To create your video pitch, we will be taking you to <a href="http://powerpitch.ai/" target="_blank">PowerPitch.ai</a>.
      Please click on “Connect” to start powerpitch creation.
      Want to know more about PowerPitch, visit <a href="http://powerpitch.ai/" target="_blank">https://powerpitch.ai</a>

    </p>
    <button class="mx-auto btn   text-white ls-2 text-uppercase fw-boldest w-50 mt-8"
      (click)="handleConnect()"
      [ngClass]="isConnecting ? 'btn-gray' : 'btn-theme'" target="_blank" [disabled]="isConnecting">
      {{isConnecting ? 'Connecting...' : 'Connect'}}
    </button>
  </div>

  <!-- <div>
    <textarea
      class="form-control"
      placeholder="Enter message..."
      [(ngModel)]="message"
      [maxlength]="maxChars"
      rows="3"
    >
    </textarea>
    <label class="w-100 text-end pt-2 small">
      <span>{{ message.length }}/{{ maxChars }}</span>
      <span class=""> characters remaining</span>
    </label>
  </div>





  <div class="row gy-5 g-xl-10 mt-8" *ngIf="toProfileData?.connectionRequestDocument && toProfileData?.askForConnectionDocument">
    <div class="col-xl-12">
      <div class="row gy-5 g-xl-10">
        <div class="col-xl-12 mb-7">
          <h2 class=" fs-4">
            Upload Document
          </h2>
          <p class=" text-muted">This document is specific to above investor as mandatory to be considered for investment.</p>

          <div>
            <input
              type="file"
              #fileDropRef
              [id]="documentType + 'Ref'"
              hidden
              (change)="pitchDeckFileBrowseHandler($event)"
              accept=".doc, .docx, .xls, .xlsx, .csv"
            />
            <label
              [for]="documentType + 'Ref'"
              appDragAndDropFileSelect
              class="btn btn-outline btn-outline-dashed text-center p-10 mb-5 w-100"
              (fileDropped)="pitchDeckFileDroppedHandler($event)"
            >
              Click or Drop file in this box to upload.<br />Accepted formats:
              doc, docx, xls, xlsx, csv
              <br />

              <span
                class="badge badge-secondary badge-lg ms-2 mt-4 px-5 py-4 fs-3"
                ><i class="bi bi-cloud-upload text-black fs-3"></i> Upload</span
              >
            </label>
          </div>

          <div
            class="table-responsive"
            *ngIf="
              (pitchObj?.fileName && pitchObj?.pitchDocument) ||
              selectedPitchDeckFile
            "
          >
            <table class="table table-bordered table-striped">
              <thead></thead>
              <tbody>
                <tr>
                  <td class="p-3 pt-5 ps-5">
                    {{
                      pitchObj?.fileName && pitchObj?.pitchDocument
                        ? pitchObj.fileName
                        : selectedPitchDeckFile?.name
                    }}

                  </td>

                  <td width="150" class="text-center" *ngIf="pitchObj?.pitchDocument">
                    <a
                      class="btn btn-theme text-white ls-2 text-uppercase fw-boldest btn-sm w-max-content"
                      [href]="pitchObj.pitchDocument"
                      target="_blank"
                      >
                      Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


    <a class="text-primary fs-5 mt-0 text-center" [href]="toProfileData.connectionRequestDocument" target="_blank" download="">
      Download sample format document
    </a>
  </div> -->

</div>
