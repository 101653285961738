import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard/startup.dashboard.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import { IJobs } from "../domain/jobs.model";
import * as JobsAction from 'src/app/core/state/jobs';
import { Router } from "@angular/router";
import { ACCOUNT_TYPE } from "../domain";
import { IIpManagement, IIpRequest } from "../domain/ip-management.model";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class FacilitiesService {

  constructor(private http: HttpClient, private toastService: ToastAlertService, private globalService: GlobalService, private store: Store,
    private router: Router) { }


  public getFacilitiesList(action): Observable<any> {
    const filters = action.filters || {}

    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES);
    return this.http.get(url, { params: filters }).pipe(
      map((response: any): IIpManagement[] => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching patents', 'error');
        return throwError(() => fault);
      })
    )
  }

  public getFacilityTypes(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES)+'/types-with-count';
    return this.http.get(url).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching patents', 'error');
        return throwError(() => fault);
      })
    )
  }


  public getAllBookingsOfFacilityId(id:string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES)+`/${id}/bookings`;
    return this.http.post(url, {}).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching bookings', 'error');
        return throwError(() => fault);
      })
    )
  }

  public getFacilityBookings(filters): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES)+'/bookings';
    return this.http.get(url, {
      params: filters
    }).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching patents', 'error');
        return throwError(() => fault);
      })
    )
  }

  public getIpsRequestList(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.IP_MANAGEMENT) + 'patents/requests';
    return this.http.get(url).pipe(
      map((response: any): IIpManagement[] => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching patents', 'error');
        return throwError(() => fault);
      })
    )
  }

  public getSingleFacilityInfo(id): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES) + `/${id}/info`;
    return this.http.get(url).pipe(
      map((response: any): IIpManagement => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching patents', 'error');
        return throwError(() => fault);
      })
    )
  }


  public getUsersAvailabilityById(id: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES) + `/${id}/availability`;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  /**
   * @param date : format 2023-06-14
   */
  public getAvailabilityByDate(id: string, date: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES) + `/${id}/availability/`+ date;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }


  public bookFacility(id,payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES) + `/${id}/book`;
    return this.http.post(url, payload).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while saving meeting data', 'error');
        return throwError(() => fault);
      })
    );
  }

  public checkIn(bookingUUID, time): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES) + `/bookings/${bookingUUID}/check-in`;
    return this.http.post(url, {
      time,
      offset: '' + new Date().getTimezoneOffset(),
      timeZone: moment.tz.guess(),
    }).pipe(
      tap(()=> {
        this.toastService.showToast('Checked in ');
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while checking in!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public cancelBooking(bookingUUID): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES) + `/bookings/${bookingUUID}/cancel`;
    return this.http.patch(url, {}).pipe(
      tap(()=> {
        this.toastService.showToast('Cancelled booking');
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while cancelling booking!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public checkOut(bookingUUID, time): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES) + `/bookings/${bookingUUID}/check-out`;
    return this.http.post(url,{time,
      offset: '' + new Date().getTimezoneOffset(),
      timeZone: moment.tz.guess(),
    }).pipe(
      tap(()=> {
        this.toastService.showToast('Checked out ');
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while checking out!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public getRatingCriteria(bookingUUID): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES) + `/bookings/${bookingUUID}/rating-criterias`;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }
  public getFacilityRatings(facilityId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES) + `/${facilityId}/ratings`;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  public saveRating(bookingUUID, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FACILITIES) + `/bookings/${bookingUUID}/rating`;
    return this.http.patch(url, payload).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }




  public getIpRequestInfo(id): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.IP_MANAGEMENT) + `patents/requests/${id}`;
    return this.http.get(url).pipe(
      map((response: any): IIpRequest => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching patents', 'error');
        return throwError(() => fault);
      })
    )
  }


  public connectForIp(patentUUID, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.IP_MANAGEMENT) + `patents/${patentUUID}/connect`;
    return this.http.post(url, payload).pipe(
      map((response: any): IIpManagement => {
        this.toastService.showToast('Request sent successfully');
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while sending request', 'error');
        return throwError(() => fault);
      })
    )
  }



  public addConvesationToPatent(id, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.IP_MANAGEMENT) + `patents/requests/${id}/message`;
    return this.http.post(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating message!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public deleteComment(id, commentId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.IP_MANAGEMENT) + `patents/requests/${id}/message/${commentId}`;
    return this.http.delete(url).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while deleting message!'), 'error');
        return throwError(() => fault);
      })
    );
  }
  public uploadFile(id, files: File[]): Observable<any> {
    const payload = new FormData();
    for (const file of files) {
      payload.append('files', file);
    }
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.IP_MANAGEMENT)+  `patents/requests/${id}/attachment`;
    return this.http.post(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading file'), 'error');
        return throwError(() => fault);
      })
    );
  }



  //   public completeHiringProfile(payload: {
  //       companySize?: string,
  //       fundingStatus?: string
  //   }): Observable<any> {
  //       const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMPLETE_HIRING_PROFILE);
  //       return this.http.patch(url, payload).pipe(
  //           tap(() => {
  //               this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
  //               this.store.dispatch(new StartupActions.GetStartUpInfo);
  //           }),
  //           map((response: any): any => {
  //               return response;
  //           }),
  //           catchError((fault: HttpErrorResponse) => {
  //               this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating completing hiring profile'), 'error');
  //               return throwError(() => fault);
  //           })
  //       );
  //   }

  //   public createJob(payload): Observable<any> {
  //       const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS);
  //       return this.http.post(url, payload).pipe(
  //           tap(() => {

  //           }),
  //           map((response: any): any => {
  //               return response;
  //           }),
  //           catchError((fault: HttpErrorResponse) => {
  //               this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating job'), 'error');
  //               return throwError(() => fault);
  //           })
  //       );
  //   }


  //   public updateJob(id, payload): Observable<any> {
  //       const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS + `${id}/details`);
  //       return this.http.patch(url, payload).pipe(
  //           tap(() => {
  //               this.store.dispatch(new JobsAction.GetJobsList());
  //           }),
  //           map((response: any): any => {
  //               return response;
  //           }),
  //           catchError((fault: HttpErrorResponse) => {
  //               this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating job'), 'error');
  //               return throwError(() => fault);
  //           })
  //       );
  //   }




  //   public getPublicJobs(action): Observable<any> {
  //     const filters = action.filters || {}
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS_PUBLIC);
  //     return this.http.get(url, { params: filters }).pipe(
  //         map((response: any): IJobs[] => {
  //             return response?.data;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             console.warn(`Jobs( ${fault.error.message} )`);
  //             this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
  //             return throwError(() => fault);
  //         })
  //     )
  //   }

  //   public getPublicJobDetails(uuid): Observable<any> {
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS_PUBLIC_DETAILS) + `/${uuid}`;
  //     return this.http.get(url).pipe(
  //         map((response: any): IJobs[] => {
  //             return response?.data;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             console.warn(`Jobs( ${fault.error.message} )`);
  //             this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
  //             if(fault.status === 404) {
  //               this.router.navigate(['/search/jobs'])
  //             }
  //             return throwError(() => fault);
  //         })
  //     )
  //   }


  //   public applyForJob(id, payload): Observable<any> {
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS + `${id}/apply`);
  //     return this.http.post(url, payload).pipe(
  //         map((response: any): any => {
  //             return response;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while applying for job'), 'error');
  //             return throwError(() => fault);
  //         })
  //     );
  // }

  //   public applyForJobResume(applicationUUID, payload): Observable<any> {
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS + `apply/${applicationUUID}/upload/resume`);

  //     const formData = new FormData()
  //     formData.append('file', payload.file)
  //     formData.append('applicationUUID', payload.applicationUUID)

  //     return this.http.post(url, formData).pipe(
  //         map((response: any): any => {
  //             return response;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while applying for job'), 'error');
  //             return throwError(() => fault);
  //         })
  //     );
  //   }




  //   public resumeSubmit(payload): Observable<any> {
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.RESUME + `submit`);
  //     return this.http.post(url, payload).pipe(
  //         map((response: any): any => {
  //             return response;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while submitting details'), 'error');
  //             return throwError(() => fault);
  //         })
  //     );
  // }

  //   public resumeUpload(payload): Observable<any> {
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.RESUME + `upload`);

  //     const formData = new FormData()
  //     formData.append('file', payload.file)

  //     return this.http.post(url, formData).pipe(
  //         map((response: any): any => {
  //             return response;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading resume'), 'error');
  //             return throwError(() => fault);
  //         })
  //     );
  //   }

  //   public getAppliedJobsList(): Observable<any> {
  //     // const filters = action.filters || {}
  //     // console.log({ filters }, '==============')
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS) + 'applied/list';
  //     return this.http.get(url).pipe(
  //         map((response: any): IJobs[] => {
  //             return response?.data;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             console.warn(`Jobs( ${fault.error.message} )`);
  //             this.toastService.showToast(fault?.error?.message || 'Error while fetching applied jobs', 'error');
  //             return throwError(() => fault);
  //         })
  //     )
  // }



  // public uploadAttachment(payload): Observable<any> {
  //   const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS + `upload/attachment`);

  //   const formData = new FormData()
  //   formData.append('file', payload.file)

  //   return this.http.post(url, formData).pipe(
  //       map((response: any): any => {
  //           return response;
  //       }),
  //       catchError((fault: HttpErrorResponse) => {
  //           this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while applying for job'), 'error');
  //           return throwError(() => fault);
  //       })
  //   );
  // }

  // public getInterviews(userType:'job-seeker' | 'startup'): Observable<any> {
  //   const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS) + 'interviews/' + userType;
  //   return this.http.get(url).pipe(
  //       map((response: any): IJobs[] => {
  //           return response?.data;
  //       }),
  //       catchError((fault: HttpErrorResponse) => {
  //           console.warn(`Jobs( ${fault.error.message} )`);
  //           this.toastService.showToast(fault?.error?.message || 'Error while fetching interviews', 'error');
  //           return throwError(() => fault);
  //       })
  //   )
  // }


}
