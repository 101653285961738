<div class="modal-content ">

  <div class="modal-header border-0 justify-content-end">

    <div class="btn btn-icon btn-sm btn-color-gray-500 btn-active-icon-primary" (click)="closeModal()"
      data-bs-toggle="tooltip" title="" data-bs-dismiss="kt_modal_view_event_edit" data-bs-original-title="Hide Event">

      <span class="svg-icon svg-icon-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
            fill="currentColor"></rect>
          <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor">
          </rect>
        </svg>
      </span>

    </div>

  </div>

  <div class="modal-body pt-0 pb-10 px-lg-17">
    <div class="d-flex justify-content-between align-items-start row">
      <div class="col-md-6 col-12">
        <div class="d-flex">

          <div class="position-relative me-5">
            <p class="calendar mb-0 pb-0">{{modalData?.event?.dateFormatted}} <em>{{modalData?.event?.month}}</em></p>
          </div>


          <div class="mb-9">

            <input type="text" style="display:none" />
            <div class="d-flex align-items-center mb-1">
              <span class="fs-3 fw-bolder me-3" data-kt-calendar="event_name">{{modalData?.event?.eventTitle}}</span>
            </div>


            <div class="fs-6 mb-1" data-kt-calendar="event_description">{{modalData?.event?.date | date :'MMM d, y'}}
            </div>

            <div>
              <span class="badge badge-secondary badge-sm me-3" *ngIf="modalData?.event?.eventType">
                {{formatEnumStringForUI(modalData?.event?.eventType) === 'Webinar' ?
                'Seminar/Webinar':formatEnumStringForUI(modalData?.event?.eventType) }}
              </span>

              <span class="badge badge-secondary badge-sm me-3" *ngIf="modalData?.event?.deliveryMode">
                {{formatEnumStringForUI(modalData?.event?.deliveryMode)}}
              </span>

            </div>

          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="d-flex align-items-center mb-2">

              <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
                <i class="bi  bi-clock fs-2 header-icons-font-size"></i>
              </span>
              <div class="fs-6">
                <span class="fw-bolder me-2">Time</span>
                <span data-kt-calendar="event_start_date">{{ modalData?.event?.timeFrom}} - {{
                  modalData?.event?.timeTo }}</span>
              </div>
            </div>

            <!-- <div class="d-flex align-items-center " *ngIf="modalData?.event?.speakerName">
              <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
                <i class="bi bi-person-fill fs-1 header-icons-font-size"></i>
              </span>
              <div class="fs-6">
                <span class="fw-bolder me-2">Speaker</span>
                <span data-kt-calendar="event_end_date">
                  <a [href]="modalData?.event.speakerLinkedin" target="_blank" class="text-black"
                    [ngbTooltip]="modalData?.event?.speakerBio">
                    {{ modalData?.event?.speakerName}}
                  </a></span>
              </div>
            </div> -->


            <div class="d-flex align-items-center " *ngIf="modalData?.event?.speakerDetails">
              <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
                <i class="bi bi-person-fill fs-1 header-icons-font-size"></i>
              </span>
              <div class="fs-6">
                <span class="fw-bolder me-2">Speaker</span>
                <span data-kt-calendar="event_end_date text-black">
                  {{ modalData?.event?.speakerDetails?.name}}

                  <!-- <a [href]="speakerProfileLink" target="_blank" class="text-primary">
                    View profile
                  </a> -->

                  <a *ngIf="modalData?.event?.speakerType == 'registered'" [href]="speakerProfileLink" target="_blank" class="text-primary">
                    View profile
                  </a>

                  <a *ngIf="modalData?.event?.speakerType == 'not_registered' && modalData?.event?.speakerLinkedin" [href]="modalData?.event?.speakerLinkedin" target="_blank" class="text-primary">
                    Go to Linkedin
                  </a>

                </span>
              </div>

            </div>

            <!-- <div class="d-flex align-items-start mt-3" *ngIf="modalData?.event?.eventRegistrationUrl && modalData?.event?.deliveryMode == 'online'">
              <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
                <i class="bi bi-link-45deg fs-1 header-icons-font-size"></i>
              </span>
              <div class="fs-6 d-flex flex-column">
                <span class="fw-bolder me-2">External Meeting Url</span>
                <div data-kt-calendar="event_end_date">
                  <a [href]="modalData?.event.eventRegistrationUrl" target="_blank"
                    class="text-primary d-block text-truncate" style="width: 325px;">
                    {{ modalData?.event?.eventRegistrationUrl}}
                  </a>
                </div>
              </div>
            </div> -->

            <div class="d-flex align-items-start mt-3" *ngIf="modalData?.event?.location && modalData?.event?.deliveryMode == 'in_person'">
              <span class="svg-icon svg-icon-1 svg-icon-danger me-5">
                <i class="bi bi-link-45deg fs-1 header-icons-font-size"></i>
              </span>
              <div class="fs-6 d-flex flex-column">
                <span class="fw-bolder me-2">Venue</span>
                <div data-kt-calendar="event_end_date">
                  <p class="text-black d-block text-truncate" style="width: 325px;">
                    {{ modalData?.event?.location}}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="resume-banner2 mt-8">
          <span class="fw-bolder me-2 mb-3 fs-3 text-primary">Description</span>
          <div class="separator separator-custom separator-dashed mt-2 mb-4"></div>
          <div *ngIf="modalData?.event?.eventDescription" [innerHTML]="modalData?.event?.eventDescription">
          </div>

          <div *ngIf="!modalData?.event?.eventDescription">
            No Notes found
          </div>

        </div>
      </div>

      <div class="col-md-6 col-12">
        <ng-container *ngIf="isInPast">
          <div class="mb-5">
            <div class="alert alert-danger p-4">
              The event has already passed or has been closed for registrations.
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isInPast">
          <ng-container *ngIf="!attendeeDetail">
            <div class="fs-6 mt-4">
              <span class="fw-bolder me-2">Book your slot</span>
            </div>
            <div class="max-h-slots fs-5 mt-3">
              <div *ngFor="let slot of slots"
                class=" alert alert-secondary-dark  p-2 d-flex justify-content-between align-items-center">
                <span>
                  {{slot.timeFrom | twentyFourHoursToAmPm}} - {{slot.timeTo | twentyFourHoursToAmPm}}
                </span>
                <button class="btn badge badge-pill rounded-30 px-5 py-2 cursor-pointer me-3 fs-7 badge-dark book-btn"
                  (click)="handleBook(slot)" [disabled]="slot.booked || bookingLoading">
                  {{slot.booked ? "Booked" : "Book"}}

                  <span *ngIf="bookingLoading && slot.timeFrom===bookingLoadingForSlotTimeFrom">
                    <span class="indicator-progress" [class.d-inline-block]="true">
                      <span class="spinner-border spinner-border-sm align-middle ms-2">
                      </span>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="attendeeDetail">
            <div class="d-flex justify-content-between align-items-center pt-6 flex-column alert alert-secondary-dark ">
              <h2>
                Your slot is booked
              </h2>

              <div class="fs-4 mt-5">
                <span class="fw-bolder me-2">Time</span>
                <span data-kt-calendar="event_start_date">{{ attendeeDetail?.timeFrom | twentyFourHoursToAmPm}} - {{
                  attendeeDetail?.timeTo | twentyFourHoursToAmPm }}</span>
              </div>

            </div>

          </ng-container>
        </ng-container>
      </div>
    </div>





    <!-- <div class="d-flex justify-content-between align-items-center pt-6 flex-column alert alert-secondary-dark mt-5">
      <p class="fs-3 text-black">
        Are you attending?
      </p>
      <div class="">
        <span *ngFor="let item of globalSettings?.platformEventResponseActions" (click)="selectResponse(item)"
          class="badge badge-pill rounded-30 px-5 py-3 cursor-pointer me-3 fs-6 "
          [ngClass]="item.value === selectedResponse?.value ?  'badge-primary': 'badge-dark'">
          {{item.name}}</span>
      </div>
      <div *ngIf="modalData?.event?.date && selectedResponse?.value === 'attending'" class="mt-4">
        <add-to-calendar-button [attr.name]="modalData?.event?.eventTitle"
          [attr.description]="modalData?.event?.eventDescription" [attr.location]="modalData?.event?.rsvpUrl"
          timeZone="Asia/Calcutta" location="World Wide Web"
          options="'Apple','Google','iCal','Yahoo','Microsoft365','MicrosoftTeams','Outlook.com'" style="width: 100%;"
          [attr.startTime]="formatTo24Hour(modalData?.event?.timeFrom)"
          [attr.endTime]="formatTo24Hour(modalData?.event?.timeTo)" [attr.startDate]="modalData.event.date"
          trigger="click" label="Add to calender"></add-to-calendar-button>
      </div>
    </div> -->





  </div>

</div>
