import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-protected-layout-footer',
  templateUrl: './protected-layout-footer.component.html',
  styleUrls: ['./protected-layout-footer.component.scss']
})
export class ProtectedLayoutFooterComponent implements OnInit {

  currentYear: string;
  constructor() {


  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear().toString();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

}
