<div class=" header-tablet-and-mobile-fixed" [class.header-fixed]="!isSideNav">

  <div class="d-flex flex-column flex-root">

    <div class="page d-flex flex-row flex-column-fluid">
      <app-public-layout-sidebar *ngIf="isSideNav && !sidebarLessNonLoggedInViewActive"></app-public-layout-sidebar>

      <div class="wrapper d-flex flex-column flex-row-fluid community-feed-list" [class.scroll-100vh]="isSideNav" id="kt_wrapper">

        <div [ngStyle]="{'display': !sidebarLessNonLoggedInViewActive? 'none': ''}">
          <app-public-header-logo-only></app-public-header-logo-only>
        </div>


        <div [ngStyle]="{'display': isSideNav? 'none': ''}">
          <app-protected-layout-header></app-protected-layout-header>
        </div>
        <div style="flex-grow: 1;">
          <router-outlet></router-outlet>
        </div>
        <app-public-layout-footer-v2></app-public-layout-footer-v2>
      </div>

    </div>

  </div>

</div>
