<!-- <div class="modal-header">
  <h2 class="text-dark fw-bold fs-1 fs-4qx text-center">
    Reactions
  </h2>
</div> -->


<div class="modal-body px-10 pt-3 pb-4 position-relative">


  <div class="tab-content">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeMeetingsTab"
      class="nav nav-pills nav-pills-custom d-flex position-relative w-100">
      <li (click)="onSelect(item);" [ngbNavItem]="1" class="nav-item mx-0 p-0 me-0" style="width: 60px;"
        [ngClass]="item.index === activeMeetingsTab ? 'active-border' : ''" *ngFor="let item of listReactions">
        <a class="text-center btn-color-muted border-0 h-100 px-0 d-flex justify-content-center align-items-center"
          data-bs-toggle="pill" ngbNavLink [ngClass]="
            activeMeetingsTab === item.index
              ? 'text-primary active-border'
              : 'text-black'
          ">
          <span [class.reaction]="item.index !== 0" id="{{item.id}}">
            <ng-container *ngIf="item.id === 'All'">
              <span class="me-3"> {{item.id}}</span>
            </ng-container>
          </span>
          {{item.count}}
        </a>
      </li>

    </ul>

    <div style="max-height: 60vh; height: 60vh; overflow: auto;" class="pt-3">

      <div *ngFor="let item of selectedList" class="">

        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <img *ngIf="item.user.avatar" style="max-width: 30px;" height="30px" class="border "
              [src]="(item.user.avatar | imagekitUrlAppender)+'?tr=w-150,h-150,cm-pad_resize'">

            <img *ngIf="
              !item.user.avatar
            " src="assets/images/no-image.svg" class="h-30px" alt="image" />

            <div class="d-flex flex-column ms-3">
              <a class="mb-1 fw-bolder fs-4 cursor-pointer d-inline-block text-truncate text-black"
              [href]="item.user.profileUrl" target="_blank">

                {{ item.user.name }}
              </a>
            </div>
          </div>

          <span class="reaction" id="{{cssIdToReactionMap[item.reactionType]}}" ></span>
        </div>

        <div class="separator "></div>
      </div>

    </div>
  </div>



  <button type="button" class="btn-close position-absolute " style="top: 8px; right: 8px;" aria-label="Close"
    (click)="closeModal()"></button>
  <div class=" w-100">

    <div class="d-flex mb-3 flex-column align-items-start">

    </div>

    <div class="form-floating mb-7">
      <div class=" ">

      </div>


    </div>



  </div>
  <!-- <div class="my-2">

    <button class="btn w-100 btn-theme text-white ls-2 text-uppercase fw-boldest"
       (click)="closeModal()">
      Closse
    </button>
  </div> -->
</div>
