import { Directive, HostListener, ElementRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[appNumberFormat]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumberFormatDirective),
    multi: true
  }]
})
export class NumberFormatDirective implements ControlValueAccessor {
  private onChange: (value: any) => void;
  private onTouched: () => void;

  constructor(private el: ElementRef) {}

  writeValue(value: any): void {
    this.el.nativeElement.value = value ? this.formatValue(value) : '';
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = value => {
      const unformatted = this.unformatValue(this.el.nativeElement.value);
      fn(unformatted);
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.el.nativeElement.disabled = isDisabled;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.el.nativeElement.value = this.formatValue(value);
    this.onChange(value);
  }

  private formatValue(value: string): string {
    if(value?.length <5) {
      return value
    }
    return value.replace(/[^0-9.]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private unformatValue(value: string): string {
    return value.replace(/,/g, '');
  }
}
