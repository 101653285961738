import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChallengesCardDetails, RecentJobs } from 'src/app/core/domain';
import { JobTypeLabels } from '../../constants/enum';

@Component({
  selector: 'app-dashboard-recent-job-applications',
  templateUrl: './dashboard-recent-job-applications.component.html',
  styleUrls: ['./dashboard-recent-job-applications.component.scss']
})
export class DashboardRecentJobApplicationsComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() data: RecentJobs[] = [];
  updatedList: RecentJobs[] = [];
  JobTypeLabels = JobTypeLabels;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updatedList = this.data
  }


}
