<div class="d-flex mb-5">

  <a class="symbol symbol-45px me-5 p-1 border rounded cursor-pointer position-relative" style="height: fit-content;" target="_blank"
    [href]="redirectUri">
    <img *ngIf="comment.user.avatar"
      [src]="(comment.user.avatar | imagekitUrlAppender) + '?tr=w-150,h-150,cm-pad_resize'" alt="" />
    <ngx-avatars bgColor="#eaeaea" *ngIf="!comment.user.avatar" [name]="comment.user?.name" cornerRadius="4" size="40"
      [round]="false">
    </ngx-avatars>
    <div
    class="bg-green ms-2 rounded cursor-pointer position-absolute  "
    *ngIf="socketService.isUserOnline(post.user?.uuid)"
    ngbTooltip="Online"
      style="height: 10px; width: 10px;right: -5px;top: -5px;">

  </div>
  </a>


  <div class="d-flex flex-column flex-row-fluid ">

    <div class="d-flex align-items-center flex-wrap mb-1 justify-content-between">
      <div class="d-flex align-items-center">
        <p class="text-gray-800 text-hover-primary fw-bolder me-2 cursor-pointer mb-0"
          >{{comment.user.name}}</p>

        <span class="text-gray-400 fw-bold ms-3 fs-7">{{daysPassed(comment.createdAt)}} ago</span>


      </div>

      <div class="d-flex align-items-center position-relative">
        <div class="ms-2 text-gray-400 text-hover-primary fw-bold fs-7 "
          *ngIf="profileData.uuid  === comment.user.uuid">
          <i class="bi bi-trash fs-5 header-icons-font-size text-red cursor-pointer" (click)="handleDelete()"></i>
        </div>
      </div>
    </div>


    <span class="text-gray-800 fs-7 fw-normal pt-1" [innerHTML]="comment.comment | linky"></span>

    <div class="my-3 d-flex align-items-center rounded" style="left: 0;" *ngIf="!parentComment">
      <div class="d-flex align-items-center me-5 cursor-pointer"
     >
        <div class=" mt-1 me-1"
          >

          <span class=" m-0">
            <i class="bi bi-chat-left-text fs-5 header-icons-font-size"></i>
          </span>
        </div>
        {{!parentComment ? comment?.replies?.length : post.stats.totalComments}}
        {{(!parentComment ? comment?.replies?.length : post.stats.totalComments) === 1 ? 'reply' : 'replies'}}
      </div>


      <app-community-feed-reaction [comment]="comment"
        (onReaction)="handleReaction($event)"></app-community-feed-reaction>
    </div>

  </div>
</div>

<div class="ms-15 mb-2 rounded " [ngClass]="{'bg-gray-100 p-3': comment?.replies?.length}">
  <ng-container *ngIf="comment?.replies?.length">
    <div *ngFor="let item of comment?.replies; let i = index">
      <app-feed-single-comment [comment]="item" (onDelete)="afterCommentAdded()" [post]="post"
        [parentComment]="comment"></app-feed-single-comment>
      <div class="separator separator-dashed my-3" *ngIf="comment?.replies?.length-1 !== i "></div>
    </div>
  </ng-container>

  <div class=""  *ngIf="!parentComment && !showReplyBox">
    <p class=" btn px-3 py-1 btn-sm btn-primary class fs-bolder fs-7 cursor-pointer"  (click)="handleToggleComments()">Reply</p>
  </div>


  <div *ngIf="!parentComment && showReplyBox" class="mt-3  bg-white rounded border px-4 py-2 my-3">
    <app-feed-comment-form [postId]="post.uuid" [parentCommentId]="comment.uuid"
      (onAdded)="afterCommentAdded()"></app-feed-comment-form>
  </div>
</div>

