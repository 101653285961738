import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChallengesCardDetails } from 'src/app/core/domain';

@Component({
  selector: 'app-dashboard-recent-challenges',
  templateUrl: './dashboard-recent-challenges.component.html',
  styleUrls: ['./dashboard-recent-challenges.component.scss']
})
export class DashboardRecentChallengesComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() data: ChallengesCardDetails[] = [];
  updatedList: ChallengesCardDetails[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updatedList = this.data

    this.updatedList = this.updatedList.map((e) => {
      return {
        ...e,
        originalLogo: e.corporateLogo,
        corporateLogo: e.corporateName=== 'confidential'?
          '/images/confidential.png'+'?tr=w-150,h-150,cm-pad_resize'
           :   e.corporateLogo +'?tr=w-150,h-150,cm-pad_resize'
      }
    })

  }

}
