
<div class="" *ngIf="certificates?.length">
  <div class="">

    <div class=" rounded shadow-sm pt-5 pb-5">
      <div class="card-title m-0 d-flex justify-content-start align-items-center d-none d-md-flex ms-md-9 justify-content-between">
        <div class="d-flex align-items-center">
          <a class="btn btn-dark text-white mb-2 ls-2 px-6" (click)="downloadCertiAsImage()">
            <span class="indicator-label">Download as Image</span>
          </a>

          <a class="btn btn-dark text-white mb-2 ls-2 px-6 ms-3" (click)="downloadCerti()">
            <span class="indicator-label">Download as PDF</span>
          </a>
        </div>

        <button type="button" class="btn-close me-md-9" aria-label="Close" (click)="closeModal()"></button>


      </div>
      <div class="aspect-ratio mx-auto " >
        <iframe [src]="('/certi?certificateNo='+certificates[0].number) | safeHtml:'resourceUrl'" id="certificate-div" frameborder="0" ></iframe>
      </div>

    </div>
  </div>

</div>
