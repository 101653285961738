<ng-container *ngIf="profileCompleteness && !profileCompleteness.isRejected">
  <ng-container *ngIf="profileCompleteness">
    <div class="d-flex align-items-center w-450px flex-column mt-3" *ngIf="!isProfileCompleted">
      <div class="d-flex justify-content-between w-100 mt-auto mb-2 align-items-center">
        <span class="fw-bold fs-6 text-gray-400">
          Profile completion

          <span class="bi bi-exclamation-circle fs-3 me-3 text-red" [ngbPopover]="popContent"
            *ngIf="profileCompleteness?.percentage < 100" [popoverTitle]="popTitle" triggers="mouseenter"
            placement="bottom"></span>

          <button class="btn btn-theme text-white ls-2 text-uppercase fw-boldest btn-sm fs-10 " [routerLink]="
          (profileData.investorType === InvestorTypes.ORGANIZATION || profileData.investorType === InvestorTypes.SYNDICATE)
            ? '/investors/edit/organization-details'
            : '/investors/edit/individual-details'
        " *ngIf="profileCompleteness?.percentage < 100 && showEditProfileBtn">
            <i class="bi bi-pencil-fill cursor-pointer text-white fs-10"></i>

            <span>
              Edit Profile
            </span>
          </button>

          <ng-template #popTitle>
            <div class="d-flex justify-content-between align-items-center" style="width: 250px;">
              <span>Missing fields</span>
              <i class="bi bi-pencil-fill text-primary cursor-pointer" [routerLink]="
                  (profileData.investorType === InvestorTypes.ORGANIZATION || profileData.investorType === InvestorTypes.SYNDICATE)
                    ? '/investors/edit/organization-details'
                    : '/investors/edit/individual-details'
                "></i>
            </div>
          </ng-template>

          <span class="bi bi-exclamation-circle fs-3 me-3 text-success" ngbTooltip="Your profile is completed"
            *ngIf="profileCompleteness?.percentage >= 100"></span>
        </span>

        <span class="fw-bolder fs-6">{{ profileCompleteness?.percentage }}%</span>

        <button (click)="checkAndShowModalForSubmitProfile()"  *ngIf="
            !profileCompleteness?.isApprovalRequested &&
            profileCompleteness?.canRequestApproval &&
            profileCompleteness?.percentage >= 100
          " class="btn btn-success text-white ls-2 text-uppercase fw-boldest btn-sm me-2"
          [disabled]="!profileCompleteness?.canRequestApproval">
          Submit
        </button>

        <span class="badge badge-primary fs-4 p-2 blink_me" *ngIf="
            profileCompleteness &&
            profileCompleteness?.isApprovalRequested &&
            !isProfileCompleted && !profileCompleteness?.isApproved
          " ngbPopover="Profile under review by admin" placement="bottom" triggers="mouseenter:mouseleave">
          <span class="bi bi-card-list fs-5 me-3 text-white ms-2"></span>
          Under Review
        </span>

        <!-- <span
          class="badge badge-primary"
          ngbPopover="Profile under review by admin"
          placement="bottom"
          popoverTitle="Profile under verification"
          triggers="mouseenter:mouseleave"
          *ngIf="
            profileCompleteness &&
            profileCompleteness?.isApprovalRequested &&
            !profileCompleteness?.canToggleStatus
          "
        >
          Under review
        </span>-->


      </div>

      <div class="h-5px mx-3 w-100 bg-light mb-3">
        <div class="bg-primary rounded h-5px" role="progressbar" style="width: {{ profileCompleteness?.percentage }}%;"
          aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" [ngClass]="
            profileCompleteness?.percentage > 80
              ? 'bg-success'
              : profileCompleteness?.percentage >= 50 &&
                profileCompleteness?.percentage < 80
              ? 'bg-primary'
              : 'bg-dark'
          ">
          <!-- </div> -->
        </div>
      </div>
    </div>

    <!-- <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3"
      *ngIf="profileCompleteness?.canToggleStatus">
      <div class="form-check form-switch form-check-custom form-check-solid justify-content-end w-100 ">
        <label class="form-check-label me-4" for="flexSwitchDefault">
          Providing funding
        </label>
        <input class="form-check-input" type="checkbox" value="" id="flexSwitchDefault"
          [checked]="profileCompleteness?.isProvidingFundings" (change)="onToggleChange($event)" />
      </div>
    </div> -->
  </ng-container>

  <ng-template #popContent>
    <ul class="list-group list-group-flush">
      <li class="list-group-item" *ngFor="let field of missingFields">
        <span class="bi bi-x fs-3 me-3 text-danger"></span>

        {{ field }}
      </li>
    </ul>
  </ng-template>
</ng-container>

<ng-container *ngIf="profileCompleteness && profileCompleteness.isRejected">
  <!-- style="background-color: red" -->
  <span
    class="badge fs-4 p-3"
    [ngStyle]="{'background': isLimitedAccess? '#181c32' : 'red'}"
    [ngbPopover]="isLimitedAccess? 'Your profile has limited access.': 'Your application was rejected during the review.'"
    placement="bottom"
    [popoverTitle]="isLimitedAccess ?'Limited Access' :'Profile Rejected'"
    triggers="mouseenter:mouseleave"
  >
   {{isLimitedAccess ? "Limited Access" :"Profile Rejected"}}
  </span>
</ng-container>

<ng-template [ngIf]="false">
  <div *ngIf="
    profileCompleteness &&
    isProfileCompleted
    " class="d-flex">
    <!-- && profileData.investorType !== InvestorTypes.INDIVIDUAL -->

    <button class="btn btn-dark text-white ls-2 text-uppercase fw-boldest btn-md me-2"
      [routerLink]="''+investorProfilePageUrl+''">
      View Profile
    </button>

    <app-add-team-button></app-add-team-button>
  </div>
</ng-template>


<ng-container *ngIf="profileCompleteness && isProfileCompleted && showShareFeedback">
  <div class="d-flex align-items-center">
    <span class="text-gray-600 fs-6 max-w-140px text-align-right">
      Let the world know you are here!
    </span>

    <button class="btn btn-dark text-white ls-2 fw-boldest border-radius ms-3 text-uppercase"
      (click)="handleShareButton()">
      Share
    </button>
  </div>

</ng-container>
