import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LightGalleryAllSettings } from 'lightgallery/lg-settings';
import lgZoom from 'lightgallery/plugins/zoom';
import { sleep } from '../../utils/common-methods';
import { ImagekitUrlAppenderPipe } from '../../pipes/imagekit-url-appender.pipe';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { HTTPS_REQUIRED_URL } from 'src/CONSTS';
import { DynamicFormFieldType, Field, Field2, IDynamicForm, IEvent, IEventForm, IIPublicProfileViewField, IParticipationDetails, IPublicProfileView } from 'src/app/core/domain/event.interface';
import { getFormsList } from 'src/app/core/state/global';
import { PublicApiService } from 'src/app/core/service/public-api.service';

@Component({
  selector: 'app-public-profile-dynamic-form-view',
  templateUrl: './public-profile-dynamic-form-view.component.html',
  styleUrls: ['./public-profile-dynamic-form-view.component.scss']
})
export class PublicProfileDynamicFormViewComponent implements OnInit, OnChanges {
  @Input() data: IPublicProfileView[] = [];
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private imagekit: ImagekitUrlAppenderPipe,
    private store: Store,
    private publicApiService: PublicApiService
  ) { }

  settings: Partial<LightGalleryAllSettings> = {
    counter: false,
    plugins: [lgZoom],
  };
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(';datadata',this.data)

  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
