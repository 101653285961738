<div class="card shadow-sm h-100">

  <div class="card position-relative">
    <div class="position-relative ">
      <img class="w-100 rounded-top" src="{{
      (item.thumbNailImage) + '?tr=w-250,h-130'
        | imagekitUrlAppender
    }}" alt="Card image cap">

      <div class="position-absolute bottom-0 gradient"></div>

      <div class="icon-round cursor-pointer" (click)="playVideo(item)">
        <div class="bg-primary play d-flex justify-content-center align-content-center">
          <i class="bi bi bi-download fs-2hx text-white"></i>
        </div>
      </div>
    </div>

    <div class="card-body px-4 py-3">
      <!-- truncate-2-line -->
      <h5 class="card-title" [ngbTooltip]="item.description">{{item.title}}</h5>
      <p class="mb-1">Courtesy: <span class="text-primary">{{item.courtesy}}</span> </p>
      <!-- <p class="card-text text-gray-600  mb-1 text-truncate" [ngbTooltip]="item.industries"><span class="text-black">Industries:</span> {{item.industries || '-'}}</p> -->
      <!-- <p class="card-text text-gray-600 truncate-2-line" [ngbTooltip]="item.description">{{item.description}}
      </p> -->
    </div>

  </div>

</div>
