<div class="row"
>
<!-- [ngStyle]="{'margin-top' : (brandDetails?.features?.looking_for_section) ? '-15px !important;' : '-40px !important;'}" -->

  <div class="col-12 col-md-10 mx-auto mb-4 text-center"
    *ngIf="connectionRequestStatus?.status === 'pending' && connectionRequestStatus?.connectionMessage">
    <div class="border border-gray-500 border-dashed rounded w-100 p-5 mb-3 fs-4 text-gray-600 bg-white">
      <div class="fs-4 fw-bold mb-4 text-black">
        Connection Request Message:
      </div>
      <div [innerHTML]="connectionRequestStatus.connectionMessage">
        <!-- [innerHTML]="'<b>Key objective for which you would like to connect and detailed context: </b> Hello world <br /><b> What are the broad action items and end goal for this engagement: </b> Hello world <br /><b>Why do you think said mentor will be able to help you on this front: </b> Hello world <br />'" -->

      </div>
    </div>
  </div>
</div>
