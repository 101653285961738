  <div class="search-results"
        [infiniteScrollContainer]="'.community-feed-list'"
  [fromRoot]="true" infinite-scroll (scrolled)="onScrollDown($event)">
  <div class="tab-content">
    <div id="kt_project_users_card_pane" class="tab-pane fade show active">
      <div class="g-xl-9" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()" [scrollWindow]="false">

        <div class="row flex-column justify-content-center align-items-center w-100 my-15p"
          *ngIf="!feedList || !feedList.length">
          <i class="bi bi-exclamation-circle" style="font-size: 120px; width: fit-content;"></i>
          <span class="fs-4 text-center my-4 fw-bold">No posts found.</span>
        </div>


        <ng-container *ngIf="feedList && feedList.length">

          <div class="" *ngFor="let post of feedList; let i = index">
            <app-community-feed-single-post (onDelete)="onDelete(i)" [post]="post"></app-community-feed-single-post>
          </div>
        </ng-container>

      </div>
    </div>

  </div>
</div>
