import * as moment from "moment";

const timeSequence = ["years", "months", "days", "hours", "minutes", "seconds"];
const daysPassed = (date) => {
  try {
    const s = moment(date);
    const currentDate = moment();

    const diff = moment.duration(currentDate.diff(s));

    const foundTime:any = timeSequence.find((e) => diff.get(e as any) > 0);
    let timeStr = foundTime;
    const value = diff.get(foundTime);
    if(value === 1) {
      timeStr = timeStr.substr(0, timeStr.length-1)
    }

    return diff.get(foundTime) + " " + timeStr;
  } catch (error) {
    return  '0 seconds'
  }
};

export default daysPassed;
