import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalQuillConfig } from '../../constants/quillConfig';
import { ICommunityFeed } from 'src/app/core/domain/community-feed';

@Component({
  selector: 'app-community-feed-list',
  templateUrl: './community-feed-list.component.html',
  styleUrls: ['./community-feed-list.component.scss']
})
export class CommunityFeedListComponent implements OnInit {
  @Input() feedList: ICommunityFeed[] = [];
  quillModules = GlobalQuillConfig;
  @Output() handleLoadMore = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onScroll() {
    console.log("scrolled!!");
  }

  onDelete(index) {
    this.feedList.splice(index, 1);
  }

  onScrollDown(ev: any) {
    console.log("scrolled down!!", ev);
    this.handleLoadMore.emit()
    // if (this.hadMoreStartups) {
      // this.handleLoadMore();
    // }
  }

  // get hadMoreStartups() {
    // return this.paginationMeta && (this.paginationMeta.currentPage * this.paginationMeta.itemsPerPage) < this.paginationMeta.totalItems
  // }
}
