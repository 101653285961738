import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format, isToday } from 'date-fns';
import * as moment from 'moment';
import { ACCOUNT_TYPE, CalenderEvent, CalenderEventData, CalenderWeek } from 'src/app/core/domain';
import { ICalenderEventModal } from 'src/app/core/domain/meetings.model';
import { FullCalenderComponent } from '../../../modules/calender/calender-component/full-calender-component';
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';
import * as CorporateDashboardAction from 'src/app/core/state/corporate-dashboard/corporate.dashboard.action';
import * as StartupDashboardAction from 'src/app/core/state/startup-dashboard/startup.dashboard.action';
import { select, Store } from '@ngrx/store';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { getProfileData } from 'src/app/core/state/profile';
import { InterviewAfterStatusLabel, meetingTypeLabel } from '../../constants/constants';
@Component({
  selector: 'app-dashboard-common-calender',
  templateUrl: './dashboard-common-calender.component.html',
  styleUrls: ['./dashboard-common-calender.component.scss']
})
export class DashboardCommonCalenderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild('modalEditMeeting', { static: true }) modalEditContent: TemplateRef<any>;
  @ViewChild('modalDeleteMeeting', { static: true }) modalDeleteContent: TemplateRef<any>;
  InterviewAfterStatusLabel = InterviewAfterStatusLabel

  @Input() viewCalender = true;
  @Input() calenderData: any;
  public weekArray: CalenderWeek[] = [];
  public calenderEventArray: CalenderEventData[] = [];
  modalData: {
    action: string;
    event: ICalenderEventModal;
  };
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();
  ACCOUNT_TYPE = ACCOUNT_TYPE;
  isRescheduling = false;
  meetingTypeLabel = meetingTypeLabel
  constructor(
    private modalService: NgbModal,
    private store: Store
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.calenderData = this.getCalenderEventData(this.calenderData);
  }

  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
      }
    })
  }


  getCalenderEventData(data): CalenderEvent[] {
    if (data?.length > 0) {
      const calenderEventList = data.map((data: CalenderEvent) => {

        const fromGMTTime = moment.utc(`${data.date} ${data.timeFrom}`).local();
        const toGMTTime = moment.utc(`${data.date} ${data.timeTo}`).local();

        const payload: any = {
          ...data,
          month: moment(fromGMTTime).format('MMM'),
          dateFormatted: moment(fromGMTTime).format('DD'),
          formattedTimeFrom: moment(fromGMTTime).format('hh:mm a'),
          formattedTimeTo: moment(toGMTTime).format('hh:mm a'),
          timeFrom: moment(fromGMTTime).format('hh:mm a'),
          timeTo: moment(toGMTTime).format('hh:mm a')
        }
        // console.log({ payload })
        return payload;
      })
      return calenderEventList;
    }
    return [];
  }


  handleClick(data) {
    this.modalData = {
      action: 'click',
      event: data
    }
    console.log('modalData', this.modalData)
    this.modalService.open(this.modalContent, { size: 'mw-650px' });
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  deleteEvent() {
    this.closeModal();
    this.modalService.open(this.modalDeleteContent);
  }

  editEvent(): void {
    this.closeModal();
    this.isRescheduling = false;
    this.modalService.open(this.modalEditContent, { size: 'mw-650px' });
  }

  rescheduleEvent(): void {
    this.isRescheduling = true;
    this.closeModal();
    this.modalService.open(this.modalEditContent, { size: 'mw-650px' });
  }

  getMeetings() {
    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {
      this.store.dispatch(new InvestorDashboardAction.GetInvestorDashboard);
    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      this.store.dispatch(new StartupDashboardAction.GetStartupDashboard);
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.dispatch(new CorporateDashboardAction.GetCorporateDashboard);
    } else if (this.profileData.accountType === ACCOUNT_TYPE.PARTNER) {
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

}
