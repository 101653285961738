import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { MixpanelService } from 'src/app/core/service/mixpanel.service';
import { getProfileData } from 'src/app/core/state/profile';
import { OffCanvasMenu, OffcanvasSidebarComponent } from 'src/app/shared/common-components/offcanvas-sidebar/offcanvas-sidebar.component';

@Component({
  selector: 'app-public-layout-header',
  templateUrl: './public-layout-header.component.html',
  styleUrls: ['./public-layout-header.component.scss']
})
export class PublicLayoutHeaderComponent implements OnInit, OnDestroy {
  // pageScrolled: boolean = false;
  // showMobileNavBar: string  = "off";
  // currentPosition = window.pageYOffset;


  private destroyed$: Subject<void> = new Subject();

  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;

  @ViewChild(OffcanvasSidebarComponent) offcanvasSidebarComponent: OffcanvasSidebarComponent;
  public menuList: Array<OffCanvasMenu>;

  constructor(
    private readonly store: Store,
    private mixPanel: MixpanelService
  ) { }

  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
      } else {
        this.profileData = undefined;
      }
    })

    this.menuList = [
      {
        id: 1,
        menu: 'Home',
        link: 'home',
        haveSubmenu: false,
        subMenuList: [],
        haveExternalLink: false
      },
      {
        id: 2,
        menu: 'About us',
        link: 'about-us',
        haveSubmenu: false,
        subMenuList: [],
        haveExternalLink: false
      },
      {
        id: 3,
        menu: 'Investors',
        link: 'investors',
        haveSubmenu: false,
        subMenuList: [],
        haveExternalLink: false
      },
      {
        id: 4,
        menu: 'Startups',
        link: 'startups',
        haveSubmenu: false,
        subMenuList: [],
        haveExternalLink: false
      },
      {
        id: 5,
        menu: 'Resources',
        link: '',
        haveSubmenu: true,
        subMenuList: [
          {
            id: 1,
            menu: 'Glossary',
            link: 'glossary',
            haveExternalLink: false
          },
          {
            id: 2,
            menu: 'Downloads',
            link: 'resources',
            haveExternalLink: false
          },
          {
            id: 3,
            menu: 'FAQs',
            link: 'faqs',
            haveExternalLink: false
          },
          {
            id: 4,
            menu: 'Blog',
            link: 'https://sanchiconnect.com/blog',
            haveExternalLink: true
          }
        ],
        haveExternalLink: false
      }
    ]
  }

  // @HostListener('window:scroll', ['$event']) onWindowScroll(e: any) {
  //   let scroll = e.target['scrollingElement'].scrollTop;
  //   if (scroll > this.currentPosition) {
  //     this.pageScrolled = true;
  //   }
  //   this.currentPosition = scroll;
  //   if(this.currentPosition == 0){
  //     this.pageScrolled = false;
  //   }
  // }

  // showMobileNavbar(){
  //   this.showMobileNavBar = this.showMobileNavBar ? "off" : "on";
  // }

  trackOnMixPanel(id: string) {
    console.log('event', event);
    this.mixPanel.track('click', {
      element: id
    });
  }


  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  open() {
    this.offcanvasSidebarComponent.openNav();
  }
}

