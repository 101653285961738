<!-- <div id="kt_toolbar_container" class="container-xxl d-flex flex-stack flex-wrap gap-2"> -->

<div class="page-title d-flex justify-content-between w-100 align-items-center

 align-items-lg-center me-3 gap-2  flex-md-row">
  <h1 class="d-flex text-dark fw-bolder fs-1hx text-dark m-0">
    <!-- Account Settings -->
    <span class="d-none d-md-block">
      Account Settings
    </span>
    <span class="d-block d-md-none">
      Settings
    </span>
  </h1>


  <div class="d-flex align-items-start align-items-lg-center flex-md-row flex-column">
    <span class="text-gray-600 fs-6 text-align-md-right text-start d-none d-md-block">
      After using {{globalSettings?.branding?.brandName}} platform for a bit now, how would <br /> you rate your experience?
    </span>

    <button class="btn btn-light ls-2 fw-boldest border-radius ms-0 ms-lg-3 mt-2 mt-lg-0 text-uppercase" (click)="openFeedback()">
      Leave feedback
    </button>
  </div>
</div>



<!-- </div> -->
