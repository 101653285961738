<app-startup-edit-profile-top-bar></app-startup-edit-profile-top-bar>
<ngx-ui-loader></ngx-ui-loader>

<!-- <div
  id="kt_content_container"
  class="d-flex flex-column-fluid align-items-start container-xxl"
>
  <div class="content flex-row-fluid pb-20 pt-5" id="kt_content">
    <app-startup-edit-page-nav-links></app-startup-edit-page-nav-links>
    <div class="card shadow-sm mt-10">
      <div class="row g-0">
        <div class="col-12 col-md-5">
          <app-pitch-deck-documents [documentType]='documentType' [onlyDocumentsForm]="true"></app-pitch-deck-documents>
        </div>
        <div class="col-12 col-md-7 bg-light-orange" >
          <div class="py-10">
            <div class="text-center">
              <h2 class="fs-bold">
                Lights, Camera, Action; Personalize Your Story
              </h2>

              <p class="fs-3 text-muted">Pick any of the below format</p>
              <br />
            </div>

            <div class="mb-10 row px-8 my-5 justify-content-center">
              <div class="col-12 col-md-4 px-2">
                <div class="box-icon justify-content-between align-items-center d-flex flex-column ribbon ribbon-top ribbon-clip ribbon-end bg-white"
                  [ngClass]="{
                    active:
                      pitchDeckManagementMode === PitchDeckManagementMode.PowerPitch
                  }"
                  (click)="
                    changeDeckManagementMode(PitchDeckManagementMode.PowerPitch)
                  "
                >
                  <div class="ribbon-label me-minus-25">
                    Popular
                    <span class="ribbon-inner bg-success"></span>
                  </div>
                  <div class="d-flex flex-column align-items-center">
                    <img
                      style="width: 60px"
                      [lazyLoad]="'/images/icons/record-power-pitch.svg' | assetsImagekitUrlAppender" [useSrcset]="true"
                    />
                    <span class="">Create Video</span>

                    <span class=" fs-8 text-gray-600 fw-normal px-4 text-center">
                      Using an AI based video pitch platform
                    </span>
                  </div>
                  <button
                    *ngIf="defaultPitchType === PitchType?.PowerPitch"
                    class="btn btn-sm btn-theme text-white ls-2 text-uppercase fw-boldest default-btn mt-1"
                  >
                    DEFAULT
                  </button>
                </div>
              </div>

              <div class="col-12 col-md-4 px-2">
                <div class="box-icon   justify-content-center align-items-center d-flex flex-column bg-white"
                  [ngClass]="{
                    active:
                      pitchDeckManagementMode === PitchDeckManagementMode.UploadPitch
                  }"
                  (click)="
                    changeDeckManagementMode(PitchDeckManagementMode.UploadPitch)
                  "
                >
                  <div class="d-flex flex-column align-items-center">
                    <img
                      style="width: 60px"
                      [lazyLoad]="'/images/icons/upload-pitch-deck.svg' | assetsImagekitUrlAppender" [useSrcset]="true"
                    />
                    <span class="">Upload Video</span>
                    <span class=" fs-8 text-gray-600 fw-normal px-4 text-center">
                      If you have an existing video created
                    </span>

                  </div>
                  <button
                    *ngIf="defaultPitchType === PitchType?.UploadPitch"
                    class="btn btn-sm btn-theme text-white ls-2 text-uppercase fw-boldest default-btn mt-1"
                  >
                    DEFAULT
                  </button>
                </div>
              </div>
            </div>


            <ng-container
              *ngIf="pitchDeckManagementMode === PitchDeckManagementMode.PowerPitch"
            >

            <ng-container *ngTemplateOutlet="NoPowerPitchTemplate"></ng-container>

            </ng-container>

            <ng-template #PowerPitchTemplate>
              <div
                class="d-flex powerPitchWithdeck-template flex-column align-items-center justify-content-center bg-white h-100 w-95 mx-auto"
              >
                <div class="w-100 d-flex justify-content-between mb-5">
                  <button
                    class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest"
                    (click)="redirectToPitchDeckRecorder()"
                  >
                    {{ "Edit or Recreate" }}
                  </button>
                  <button
                    class="btn btn-secondary ls-2 text-uppercase fw-boldest"
                    (click)="setDefaultPitchType(PitchType?.PowerPitch)"
                  >
                    {{ "Use as Default" }}
                  </button>
                </div>

              </div>
            </ng-template>

            <ng-template #NoPowerPitchTemplate>
              <div
                class="d-flex powerPitchWithdeck-template flex-column align-items-center justify-content-center bg-white w-95 mx-auto h-auto h-auto-imp rounded"
              >

              <ng-container  *ngIf="!pitchVideoDetails">
                <span class="fs-3 text-gray-600 mb-10"
                  >You do not have any video created</span
                >
                <button
                  class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest"
                  (click)="redirectToPitchDeckRecorder()"

                >
                  {{ "+ Create Video" }}
                </button>
              </ng-container>
              <ng-container *ngIf="pitchVideoDetails">
                <div class="w-100 d-flex">
                  <button (click)="openVideoEditPage()"
                  class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest mb-3 text-white"
                  >
                    {{ "Edit Video" }}
                  </button>

                </div>
                <div class="separator separator-dashed mt-2 mb-4 w-100"></div>
                <iframe *ngIf="!videoLoading" [src]="embedLink | safeHtml:'resourceUrl'" width="100%" height="480" style="border-radius: 8px;"></iframe>

              </ng-container>


              </div>
            </ng-template>

            <ng-container
              *ngIf="
                pitchDeckManagementMode === PitchDeckManagementMode.PowerPitchDeck
              "
            >
              <ng-container
                *ngIf="
                  pitchDeckManagmentObj?.powerPitchDeckUrl;
                  else NoPitchDeckTemplate
                "
              >
                <ng-container
                  *ngTemplateOutlet="PowerPitchWithDeckTemplate"
                ></ng-container>
              </ng-container>
            </ng-container>

            <ng-template #PowerPitchWithDeckTemplate>
              <div
                class="d-flex powerPitchWithdeck-template flex-column align-items-center justify-content-center bg-white h-100 w-95 mx-auto"
              >
                <div class="w-100 d-flex justify-content-between mb-5">
                  <button
                    class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest"
                    [id]="BUTTON_ID"
                    #pitchRecordEditButton
                  >
                    {{ "Edit or Recreate" }}
                  </button>
                  <button
                    class="btn btn-secondary ls-2 text-uppercase fw-boldest"
                    (click)="setDefaultPitchType(PitchType?.PowerPitchDeck)"
                  >
                    {{ "Use as Default" }}
                  </button>
                </div>

                <embed
                  style="width: 100%;"
                  type="video/webm"
                  [src]="powerPitchWithDeckUrl"
                  height="480"
                />

              </div>
            </ng-template>

            <ng-template #NoPitchDeckTemplate>
              <div
                class="d-flex powerPitchWithdeck-template flex-column align-items-center justify-content-center bg-white w-95 mx-auto"
              >
                <span class="fs-3 text-gray-600 mb-10"
                  >You do not have any video created</span
                >
                <button
                  class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest"
                  [id]="BUTTON_ID"
                  #pitchRecordButton
                >
                  {{ "+ Create Video" }}
                </button>
              </div>
            </ng-template>

            <ng-container
              *ngIf="
                pitchDeckManagementMode === PitchDeckManagementMode.UploadPitch
              "
            >
              <ng-container
                *ngIf="
                  pitchDeckManagmentObj?.uploadPitchUrl || selectedPitchDeckFileUrl;
                  else UploadPitchTemplate
                "
              >
                <ng-container *ngTemplateOutlet="Test"></ng-container>
              </ng-container>
            </ng-container>

            <ng-template #Test>
              <div
                class="d-flex powerPitchWithdeck-template flex-column align-items-center justify-content-center bg-white h-100 w-95 mx-auto"
              >
                <div class="w-100 d-flex justify-content-between mb-5">
                  <button
                    class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest"
                    (click)="reUploadExistingVideo()"
                  >
                    {{ "Upload New" }}
                  </button>
                  <button
                    class="btn btn-secondary ls-2 text-uppercase fw-boldest"
                    (click)="setDefaultPitchType(PitchType?.UploadPitch)"
                  >
                    {{ "Use as Default" }}
                  </button>
                </div>

                <div class="w-100">
                  <video
                    controls
                    disablePictureInPicture
                    autoplay
                    preload
                    class="w-100"
                    [src]="
                      pitchDeckManagmentObj?.uploadPitchUrl ?? selectedPitchDeckFileUrl
                    "
                  ></video>
                </div>

              </div>
            </ng-template>

            <ng-template #UploadPitchTemplate>

              <div>
                <input
                  type="file"
                  #fileDropRef
                  accept="video/mp4,video/webm"
                  id="fileDropRef"
                  hidden
                  (change)="pitchDeckFileBrowseHandler($event)"
                />
                <label
                  for="fileDropRef"
                  appDragAndDropFileSelect
                  class="d-flex uploadPitch-template flex-column align-items-center justify-content-center btn btn-outline btn-outline-dashed text-center p-10 mb-5 w-95 bg-white mx-auto"
                  (fileDropped)="pitchDeckFileDroppedHandler($event)"
                >
                  <span class="fs-3 text-gray-600 mb-10"
                    >You do not have any video created</span
                  >
                  <span class="text-gray-600">
                    Click or Drop file in this box to upload.<br />Accepted formats:
                    .mp4, .webm, .mov
                  </span>
                  <br />

                  <span
                    class="btn btn-lg btn-theme text-white ls-2 text-uppercase fw-boldest"
                  >
                    <i class="bi bi-cloud-upload text-white fs-3"></i> Upload
                  </span>


                </label>
              </div>
            </ng-template>
          </div>
          </div>

      </div>

    </div>
    <div class="mt-8 d-flex justify-content-center align-items-center" >
      <app-form-next-step-button [url]="'/startups/edit/custom-forms/first/first'"></app-form-next-step-button>
    </div>

  </div>
</div> -->
