<div class="multi-select-dropdown w-100">
  <div ngbDropdown class="d-flex">
    <input
      type="text"
      [placeholder]="placeholder"
      class="form-control"
      autocomplete="off"
      [readonly]="!searchSupported"
      ngbDropdownToggle
      [(ngModel)]="searchTerm"
      (keydown)="handleSearch()"
    />
    <div class="w-100 multi-select-wrapper" ngbDropdownMenu>
      <div *ngFor="let item of filteredItems" class="bg-white dropdown-item">
        <label class="fs-5 cursor-pointer">
          <input
            class="form-check-input me-2"
            [name]="item.value"
            type="checkbox"
            [(ngModel)]="item.selected"
            (change)="onItemSelectionChange(item)"
          />
          {{ item.label }}
        </label>
      </div>
    </div>
  </div>

  <div class="mt-3 d-flex flex-row" *ngIf="isShowSelectedItemsChip">
    <div class="me-3" *ngFor="let item of selectedItems; let i = index">
      <div
        class="d-flex badge rounded-pill bg-primary text-primary px-4 py-2 fs-6"
      >
        <span> {{ item.label }} </span>
        <i
          class="bi bi-x ms-2 fs-3 cursor-pointer"
          (click)="removeSelectedItem(item, i )"
        ></i>
      </div>
    </div>
  </div>
</div>
