import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-power-pitch-edit-page-wrapper',
  templateUrl: './power-pitch-edit-page-wrapper.component.html',
  styleUrls: ['./power-pitch-edit-page-wrapper.component.scss']
})
export class PowerPitchEditPageWrapperComponent implements OnInit {
  editPageUrl = '';
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }
  closeModal(): void {
    this.activeModal.close();
  }
}
