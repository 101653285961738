import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { IAds, IAdItem } from 'src/app/core/domain/ads.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getAds } from 'src/app/core/state/ads';
import { getProfileData } from 'src/app/core/state/profile';
import { ImagekitUrlAppenderPipe } from 'src/app/shared/pipes/imagekit-url-appender.pipe';

@Component({
  selector: 'app-ad-viewer',
  templateUrl: './ad-viewer.component.html',
  styleUrls: ['./ad-viewer.component.scss']
})
export class AdViewerComponent implements OnInit, OnDestroy {

  @ViewChild('modalAdViewer') modalAdViewer;

  private destroyed$: Subject<void> = new Subject();
  public ads$: Observable<IAds>;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;

  @Input('placement') placement: 'login' | 'register' | 'search' | 'home' | 'public_profile' | 'dashboard' | 'government-grants' | 'login_bottom';

  // visibleAds: IAds;
  ads: IAds;
  originalAds: IAds;

  constructor(
    private store: Store,
    public modal: NgbModal,
    private imagekitUrlAppender: ImagekitUrlAppenderPipe
  ) {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
  }

  ngOnInit(): void {
    this.ads$ = this.store.pipe(select(getAds));
    this.ads$.pipe(takeUntil(this.destroyed$)).subscribe((ads) => {
      if (ads) {
        this.originalAds = ads;
        this.ads = JSON.parse(JSON.stringify(this.originalAds))
        this.filterAdsBasedOnProfile()

        setTimeout(() => {
          this.checkModalAds();
        }, 2500)
      }
    })

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
        this.filterAdsBasedOnProfile()
      }
    })
  }

  filterAdsBasedOnProfile() {
    if (this.originalAds) {
      Object.keys(this.originalAds).forEach((k) => {
        this.ads[k].display = this.originalAds[k].display.filter((d) => {
          return !d.showTo.length || d.showTo.includes('all') || (this.profileData && d.showTo.includes(this.profileData.accountType))
        })


        this.ads[k].modal = this.originalAds[k].modal.filter((d) => {
          return !d.showTo.length || d.showTo.includes('all') || (this.profileData && d.showTo.includes(this.profileData.accountType))
        })

        this.ads[k].display = this.originalAds[k].display.map((d) => {
          return {
            ...d,
            image: d.image +  '/tr:q-100'
          }
        })

        this.ads[k].modal = this.originalAds[k].modal.map((d) => {
          return {
            ...d,
            image: d.image +  '/tr:q-100'
          }
        })
      })
    }
  }


  checkModalAds() {
    if (this.ads && this.placement && this.ads[this.placement]?.modal?.length) {
      let adsViewed = JSON.parse(localStorage.getItem('adsViewed') || '{}');
      if (!adsViewed[this.placement] || this.placement === 'home') {

        this.ploadImage(this.ads[this.placement]?.modal);

        setTimeout(() => {
          this.modal.open(
            this.modalAdViewer, { centered: true, size: 'lg' }
          )
        });
      }
    }
  }


  imgs = [];
  ploadImage(args: any[]): void {
    for (var i = 0; i < args.length; i++) {
      this.imgs[i] = new Image();
      this.imgs[i].src = this.imagekitUrlAppender.transform(args[i].image);
      console.log('loaded: ' + args[i]);
    }
  }

  handleCloseClick() {
    if (this.placement === 'home') {
      this.modal.dismissAll()
      return
    }
    let adsViewed = JSON.parse(localStorage.getItem('adsViewed') || '{}');
    adsViewed[this.placement] = true;
    localStorage.setItem('adsViewed', JSON.stringify(adsViewed));

    this.modal.dismissAll()
  }


  handleClick(slide: IAdItem) {
    if (slide.link) {
      // if (slide.linkType === 'external') {
      window.open(slide.link, slide.linkType === 'external' ? '_blank' : '_self');
      // }
    }
    console.log('slide', slide)
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
