import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ApexChart, ApexFill, ApexNonAxisChartSeries, ApexPlotOptions, ApexStroke, ChartComponent } from 'ng-apexcharts';
import { Observable, Subject, Subscription, distinctUntilChanged, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE, CorporateProfileCompleteness, FormsInterface, InvestorProfileCompleteness, InvestorTypes, MentorProfileCompleteness, ProgramOfficeProfileCompleteness, ServiceProviderProfileCompleteness, StartupProfileCompleteness } from 'src/app/core/domain';
import * as StartupDashBoardActions from "../../../core/state/startup-dashboard";
import { rasingFundRequestFault, rasingFundRequestSuccess } from '../../../core/state/startup-dashboard';
import { USER_TYPE_STARTUP } from '../../constants/startup-invetor-constants';
import { ToastAlertService } from '../../utils/toast.service';
import * as InvestorDashBoardActions from "../../../core/state/investor-dashboard";
import { providingFundRequestFault, providingFundRequestSuccess } from '../../../core/state/investor-dashboard';
import Swal from 'sweetalert2';
import { Approval } from 'src/app/core/domain';
import { Router } from '@angular/router';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  stroke: ApexStroke;
  fill: ApexFill;
};
@Component({
  selector: 'app-startup-investor-dashboard-profile',
  templateUrl: './startup-investor-dashboard-profile.component.html',
  styleUrls: ['./startup-investor-dashboard-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartupInvestorDashboardProfileComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions!: ChartOptions;

  // @Input() public forms: FormsInterface;
  @Input() public profileCompleteness: StartupProfileCompleteness | MentorProfileCompleteness | InvestorProfileCompleteness | CorporateProfileCompleteness | ServiceProviderProfileCompleteness | ProgramOfficeProfileCompleteness;
  // @Input() public userType: string;
  @Input() public investorType?: InvestorTypes;
  InvestorTypes = InvestorTypes;
  public chartPer: number = 0;
  private rasingFundRequestSuccess$: Subscription;
  private rasingFundRequestFail$: Subscription;
  private providingFundRequestSuccess$: Subscription;
  private providingFundRequestFail$: Subscription;
  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings:IGlobalSettings;
  private destroyed$: Subject<void> = new Subject();
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  profileEditPageUrl = '';
  constructor(private readonly store: Store, private toastAlertService: ToastAlertService, private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profileCompleteness) {
      this.chartPer = this.profileCompleteness.percentage;

      this.globalSettings$ = this.store.pipe(select(getGlobalSettings));

      this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.globalSettings =res;
          this.initializeChart();
        }})
    }
  }

  ngOnInit(): void {
    this.initializeChart();

    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;



        if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {
          if (this.profileData.investorType === InvestorTypes.ORGANIZATION || this.profileData.investorType === InvestorTypes.SYNDICATE) {
            this.profileEditPageUrl = '/investors/edit/organization-details';
          } else if (this.profileData.investorType === InvestorTypes.INDIVIDUAL) {
            this.profileEditPageUrl = '/investors/edit/individual-details';
          }
        } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
          this.profileEditPageUrl = '/startups/edit/startup-information';
        } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
          this.profileEditPageUrl = '/corporates/edit/corporate-intro';
        } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
          this.profileEditPageUrl = '/mentors/edit/mentor-information';
        }else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
          this.profileEditPageUrl = '/service-provider/edit/service-provider-information';
        } else if (this.profileData.accountType === ACCOUNT_TYPE.PARTNER) {
          this.profileEditPageUrl = '/partners/edit/partner-information';
        } else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {
          this.profileEditPageUrl = '/program-office-members/edit/program-office-member-information';
        } else if (this.profileData.accountType === ACCOUNT_TYPE.OTHER) {
        }

      }
    })
  }

  ngOnDestroy(): void {
    if (this.rasingFundRequestSuccess$) this.rasingFundRequestSuccess$.unsubscribe();
    if (this.rasingFundRequestFail$) this.rasingFundRequestFail$.unsubscribe();
    if (this.providingFundRequestSuccess$) this.providingFundRequestSuccess$.unsubscribe();
    if (this.providingFundRequestFail$) this.providingFundRequestFail$.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeChart() {
    this.chartOptions = {
      labels: [],
      series: [this.chartPer],
      chart: {
        height: 130,
        type: "radialBar",
        sparkline: {
          enabled: true,
        }
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "50%"
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              show: false
            }
          },
          track: {
            background: "#f5f5f5",
            strokeWidth: '100%'
          }
        }
      },
      fill: {
        type: 'solid',
        colors: [this.globalSettings?.branding?.colors?.primary || '#ed7c31']
      },
      stroke: {
        lineCap: "round",
        curve: 'smooth',
      },
    };
  }

  // onToggleChange($event) {
  //   if ($event.target.checked) {
  //     if (this.userType === USER_TYPE_STARTUP) {
  //       this.store.dispatch(new StartupDashBoardActions.RasingFundRequest);
  //       this.rasingFundRequestSuccess$ = this.store.pipe(select(rasingFundRequestSuccess)).subscribe(res => {
  //         if (res) {
  //           this.toastAlertService.showToast(res);
  //         }
  //       })

  //       this.rasingFundRequestFail$ = this.store.pipe(select(rasingFundRequestFault)).subscribe(res => {
  //         if (res) {
  //           this.toastAlertService.showToast(res, 'error');
  //         }
  //       })
  //     } else {
  //       this.store.dispatch(new InvestorDashBoardActions.ProvidingFundRequest);
  //       this.providingFundRequestSuccess$ = this.store.pipe(select(providingFundRequestSuccess)).subscribe(res => {
  //         if (res) {
  //           this.toastAlertService.showToast(res);
  //         }
  //       })

  //       this.providingFundRequestFail$ = this.store.pipe(select(providingFundRequestFault)).subscribe(res => {
  //         if (res) {
  //           this.toastAlertService.showToast(res, 'error');
  //         }
  //       })
  //     }
  //   }

  // }


  checkAndShowModalForSubmitProfile() {
    if (this.chartPer >= 100) {
      Swal.fire({
        title: 'Awesome! your profile is now complete.',
        text: 'Please submit your profile.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        confirmButtonText: 'Submit',
      }).then((result) => {
        if (result.isConfirmed) {
          this.requestApproval();
        }
      });
    }
  }

  requestApproval() {
    const payload: Approval = {
      servicesLookingFor: []
    }
    this.store.dispatch(new StartupDashBoardActions.SendRequestApproval(payload));
    this.router.navigate(['/startups/dashboard']);
  }

}
