<div class="position-relative d-flex justify-content-center">
  <ng-lottie class="position-absolute top-0 z-index-3 w-1000px" [options]="options" [height]="'100vh'"
    (animationCreated)="animationCreated($event)"></ng-lottie>
</div>


<ng-template #successModal let-modal>
  <div class="modal-body  d-flex justify-content-center flex-column align-items-center lottie-container">
    <ng-lottie [options]="successOptions" height="200px"></ng-lottie>
    <h2 class="fs-2">{{config.title}}</h2>
    <p class="text-muted">{{config.subtitle}}</p>
    <button class="btn btn-theme text-white ls-2 text-uppercase fw-boldest mt-2 z-index-3" (click)="emitCallToActionEvent()">
      {{config.callToActionButtonText}}
    </button>
    <p class="text-muted mt-2 mb-0 pb-0">Redirecting in {{seconds}} seconds...</p>
  </div>
</ng-template>
