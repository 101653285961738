import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ALL_COUNTRY_CODES_ARR, COUNTRY_CODES_ARR } from 'src/app/shared/constants/country-code';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, finalize, Observable, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/service';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import { select, Store } from '@ngrx/store';
import * as AuthActions from "src/app/core/state/auth";
import { getPending } from 'src/app/core/state/auth';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import { getBrandDetails } from 'src/app/core/state/global';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import * as ProfileActions from "src/app/core/state/profile/profile.action";

@Component({
  selector: 'app-verify-mobile-number-modal',
  templateUrl: './verify-mobile-number-modal.component.html',
  styleUrls: ['./verify-mobile-number-modal.component.scss']
})
export class VerifyMobileNumberModalComponent implements OnInit {
  @Input('modalRef') modalRef: NgbModalRef;
  modalHeader = '';

  COUNTRY_CODES_ARR = ALL_COUNTRY_CODES_ARR;

  loginForm: FormGroup | any;
  otpSent = false;
  loader = false;
  public buttonDisabled: boolean = true;

  otpInputConfig = {
    length: 4,
    inputClass: 'form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2',
    inputStyles: {
      borderRadius: '0.75rem',
    },
    containerClass: 'd-flex flex-stack'
  }

  brandDetails: Partial<IBrandDetails>;

  private destroyed$: Subject<void> = new Subject();
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private toastAlertService: ToastAlertService,
    private readonly store: Store,
    private modalService: NgbModal,
  ) {
    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
        if(this.modalRef) {
          this.modalRef.close({ loggedIn: true })
        }
      }
    })



    this.loginForm = new FormGroup({
      countryCode: new FormControl(91, [Validators.required]),
      mobileNumber: new FormControl('', [Validators.pattern('^(\\+?\d{1,4}[\s-])?(?!0+\s+,?$)\\d{10}\s*,?$')]),
      code: new FormControl('', []),
      email: new FormControl('', [Validators.email]),
    });
  }

  ngOnInit(): void {
    this.store.pipe(select(getPending)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      this.loader = res;
    });

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
      }
    })
  }

  get otpControl(): FormControl {
    return this.loginForm?.get('code') as FormControl;
  }

  onOtpChange($event: any) {
    const value = $event;
    if (value.length === 4) {
      this.buttonDisabled = false;
    } else {
      this.buttonDisabled = true;
    }
  }

  onSubmit() {
    console.log('loginForm', this.loginForm.value)
    if (!this.otpSent) {
      this.sendLoginRequest()
    } else {
      const payload = {
        ...this.loginForm.value,
        name: this.profileData?.name || ''
      };
      payload.mobileNumber = +payload.mobileNumber;
      // this.store.dispatch(new AuthActions.VerifyOtp({ ...payload, loginFrom: 'modal' }));
      this.authService.verifyWhatsappOTP(payload).pipe(finalize(() => this.loader = false)).subscribe((res) => {
        console.log({ res })
        // this.otpSent = true;
        this.store.dispatch(new ProfileActions.GetProfile());
        this.toastAlertService.showToast('Whatsapp number verified');
        this.closeModal()
      }, err => {
        this.toastAlertService.showToast(err.error.message, 'error');
      });

    }
  }

  sendLoginRequest() {
    this.loader = true;
    const payloadData = {
      ...this.loginForm.value,
      mobileNumber: +this.loginForm.value.mobileNumber,
      countryCode: parseInt(this.loginForm.get('countryCode').value),
      name: this.profileData?.name || ''
    }
    this.authService.sendWhatsappOTP(payloadData).pipe(finalize(() => this.loader = false)).subscribe((res) => {
      console.log({ res })
      this.otpSent = true;
      // this.toastAlertService.showToast(res.message);
    }, err => {
      this.toastAlertService.showToast(err.error.message, 'error');
    });
  }


  openRegisterModal() {
    this.modalRef.close({ openRegisterModal: true })
  }


  closeModal(): void {
    this.activeModal.close();
  }

}
