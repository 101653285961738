import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MultiSelectDropDownItem } from './multi-select-dropdown.model';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {
  @Input() items: MultiSelectDropDownItem[] = [];
  @Input() placeholder: string = 'Choose';
  @Input() isShowSelectedItemsChip: boolean = true;
  @Input() searchSupported = false;
  @Input() keyForSearch = 'value'

  selectedItems: MultiSelectDropDownItem[] = [];
  searchTerm = '';

  filteredItems = []

  @Output() onSelectionChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.items = [...this.items]
    this.filteredItems = this.items
    this.setSelectedItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      // this.items = [...this.items]
      this.filteredItems = this.items
      this.setSelectedItems();
    }
  }

  setSelectedItems(): void {
    this.selectedItems = this.items.filter(i => i.selected);
  }

  onItemSelectionChange(item): void {
    // this.setSelectedItems();

    const index = this.selectedItems.findIndex(e=>e.value === item.value)
    if(index > -1) { 
      this.selectedItems.splice(index, 1)
    }else{
      this.selectedItems.push(item)
    }

    this.onSelectionChange.emit(this.selectedItems);
    this.searchTerm = ''
    this.handleSearch()
  }

  removeSelectedItem(item: MultiSelectDropDownItem, index): void {
    this.items.find(d => d.value === item.value).selected = false;
    // this.setSelectedItems();
    this.selectedItems.splice(index, 1)
    this.onSelectionChange.emit(this.selectedItems);
  }

  handleSearch() {
    if (this.searchTerm) {
      this.filteredItems = this.items.filter(e => e[this.keyForSearch]?.toLowerCase()?.includes(this.searchTerm?.toLowerCase()))
    } else {
      this.filteredItems = this.items
    }
  }
}
