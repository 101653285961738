import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
;
import {
    CalendarEvent
} from 'angular-calendar';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICalenderEventModal } from 'src/app/core/domain/meetings.model';

@Component({
    selector: 'app-delete-meeting-event',
    templateUrl: './delete-meeting-event.component.html',
    styleUrls: ['./delete-meeting-event.component.scss']
})
export class DeleteMeetingComponent implements OnInit {
    isNotDelete: boolean = false;
    @Output() refreshData = new EventEmitter<any>();
    @Input() modalData: {
        action: string;
        event: ICalenderEventModal;
    };
    constructor(private meetingService: MeetingService,
        private modal: NgbModal,
        private toastAlertService: ToastAlertService) {
    }

    ngOnInit(): void {

    }

    onDeleteMeeting() {
        this.meetingService.deleteMeetings(this.modalData.event.uuid).subscribe((response: any) => {
            if (response) {
                this.toastAlertService.showToast(response.message);
                this.refreshData.emit();
                this.closeModal()
            } 
        } , err => {
            console.log(err);
        });
    }

    closeModal(): void {
        this.modal.dismissAll();
      }
}