import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event-details-modal-wrapper',
  templateUrl: './event-details-modal-wrapper.component.html',
  styleUrls: ['./event-details-modal-wrapper.component.scss']
})
export class EventDetailsModalWrapperComponent implements OnInit {
  @Input() modalInst: NgbModalRef;
  @Input() modalData: {
    action: string;
    event: any;
  };

  @Input() isRescheduling = false;
  constructor() { }

  ngOnInit(): void {
  }

}
