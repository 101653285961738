import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDateStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { add } from 'date-fns';
import * as moment from 'moment';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE, OrganizationInformationResponseModel } from 'src/app/core/domain';
import { IMeetingAvailability, IMeetingPatchModel, METTING_AVAILABILITY, MeetingTime } from 'src/app/core/domain/meetings.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { ProfileService } from 'src/app/core/service/profile.service';
import { getCorporateInfo } from 'src/app/core/state/corporate-info';
import { getInvestorsOrg } from 'src/app/core/state/investors-organization-info';
import { getPartnerInfo } from 'src/app/core/state/partner-info';
import { getProfileData } from 'src/app/core/state/profile';
import { getStartUpInfo } from 'src/app/core/state/startup';
import { NgbTimeStringAdapter } from '../add-meeting-button/add-meeting-modal/add-meeting-modal.component';
import { getMentorInfo } from 'src/app/core/state/mentor-info';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { calculateSlots, convertToLocalTime, createTimeSlots, findNextAvailableDate, generateTimeSlots, generateTimeSlotsWithAMPM, isDisabledDateForMeeting } from '../../utils/common-methods';
import { getBrandDetails } from 'src/app/core/state/global';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { Router } from '@angular/router';
import { getProgramOfficeInfo } from 'src/app/core/state/program-office-members-info';

@Component({
  selector: 'app-accept-connection-modal',
  templateUrl: './accept-connection-modal.component.html',
  styleUrls: ['./accept-connection-modal.component.scss'],
  providers: [
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
  ]
})
export class AcceptConnectionModalComponent implements OnInit, OnDestroy {
  model: NgbDateStruct;

  @Input() title: string;
  @Input() msgText: string = '';
  @Input() applyBtnText: string = "Ok";
  @Input() cancelBtnText: string = "Cancel";
  @Input() maxCharLimit: number = 300;
  @Input() otherUser;

  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();

  ACCOUNT_TYPE = ACCOUNT_TYPE;

  message: string;

  messageAcceptOptions;
  // if chosen, lets show a field to enter email address and Accept message will be like “Email at info@sparkidea.in”
  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })
  acceptOptions = [

    {
      label: 'Schedule video call on platform',
      value: 'Schedule call'
    },
    {
      value: 'Connect offline',
    },
    {
      value: 'Other'
    },
  ];
  startUpAcceptOptions = [
    {
      label: 'Schedule video call on platform',
      value: 'Schedule call'
    },
    // {
    //   value: 'Share meeting details'
    // },
    {
      value: 'Connect offline',
    },
    {
      value: 'Other'
    }
  ];
  meetingForm: FormGroup;
  minDate;

  companyName = '';

  userProfileData;
  timeSlots: Array<{
    label: string,
    value: string
  }> = []
  METTING_AVAILABILITY = METTING_AVAILABILITY;
  calenderAvilablity: IMeetingAvailability;
  disabledDaysIndex = []
  brandDetails: Partial<IBrandDetails>;

  constructor(public activeModal: NgbActiveModal,
    private profileService: ProfileService,
    private readonly store: Store,
    private meetingService: MeetingService,
    private calendar: NgbCalendar,
    private router: Router
  ) {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        console.log({ res })
        this.profileData = res;
      }
    })
  }

  ngOnInit(): void {
    this.message = this.msgText;

    console.log('other user', this.otherUser)

    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res;
        this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
          if (res) {
            this.profileData = res;
            if(this.profileData.accountType=== ACCOUNT_TYPE.INVESTOR && this.router.url.includes('startups'))  {
              // TODO :
              this.acceptOptions.unshift({
                value: 'Internal evaluation started. If interested, you will receive an email soon.'// investor
              },)
            }
            // this.getMeetingCompanyName();
            this.getCompanyDetails()
          }
        })
      }})

  }



  getCompanyDetails() {
    const uid = this.otherUser.uid || this.otherUser.uuid || this.otherUser.userUUID
    this.profileService.getUserPublicProfile(uid).subscribe(async (res) => {
      if (res) {
        this.userProfileData = res;
        console.log('userProfileData', this.userProfileData)
        await this.getMeetingAvailability(uid);
        this.getMeetingCompanyName();
      }
    }, (error) => {
      this.getMeetingCompanyName();
    })

  }

  isDisabled = (date: NgbDate, current: { month: number }) => {
    return isDisabledDateForMeeting(date,this.calenderAvilablity,this.calendar,this.disabledDaysIndex)
  };


  async getMeetingAvailability(uid: string) {
    const res = await this.meetingService.getUsersAvailabilityById(uid).toPromise()
    // subscribe((res)=>{
    this.calenderAvilablity = res.data || {};
    if (this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.specific_days) {
      this.disabledDaysIndex = this.calenderAvilablity.days.filter((e) => e.closed).map(e => e.dayIndex)
      console.log('this.disabledDaysIndex', this.disabledDaysIndex)
    } else if (this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.anytime) {
      this.timeSlots = generateTimeSlotsWithAMPM(this.brandDetails?.features?.meeting_time_slot_difference_in_mins);
    }
    // })
  }


  getMeetingCompanyName() {
    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {

      this.store.pipe(select(getInvestorsOrg)).subscribe((response: OrganizationInformationResponseModel) => {
        if (response) {
          this.companyName = response.data.organizationName
          this.setMeetingForm()
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {

      const startupInfo$ = this.store.pipe(select(getStartUpInfo));
      startupInfo$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.companyName = res.companyName
          this.setMeetingForm()
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.pipe(select(getCorporateInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
        if (res) {
          this.companyName = res.data.companyName
          this.setMeetingForm()
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.PARTNER) {

      this.store.pipe(select(getPartnerInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((response) => {
        if (response) {
          this.companyName = response.data.name
          this.setMeetingForm()
        }
      })
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {

      this.store.pipe(select(getMentorInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((response) => {
        if (response) {
          this.companyName = response.data.name
          this.setMeetingForm()
        }
      })
    } else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {

      this.store.pipe(select(getProgramOfficeInfo)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((response) => {
        if (response) {
          this.companyName = response.data.name
          this.setMeetingForm()
        }
      })
    }
  }



  setMeetingForm() {

    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    this.meetingForm = new FormGroup({
      date: new FormControl('', [Validators.required]),
      timeFrom: new FormControl(null, [Validators.required]),
      timeTo: new FormControl('', [Validators.required]),
      meetingTitle: new FormControl('', [Validators.required]),
      otherUserUUID: new FormControl(''),
      duration: new FormControl('30', [Validators.required]),
    });

    this.meetingForm.get('date').valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
    .subscribe((date) => {
      if (this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.specific_days || this.calenderAvilablity.availabilityHours === METTING_AVAILABILITY.anytime) {
        this.calculateSlots(date)
        // console.log('slots', this.timeSlots)
      }
    })

    if (this.otherUser) {
      const today = add(new Date(), {
        hours: 1,
      });
      today.setMinutes(0);

      const startTime = today.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);

      today.setHours(today.getHours() + 2)
      const endTime = today.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);


      const nextAvailableDate = findNextAvailableDate(
        this.calenderAvilablity,
        this.calendar,
        this.disabledDaysIndex
      )
      if(nextAvailableDate) {
        this.model = nextAvailableDate
        console.log('calenderAvilablity abcd', this.calenderAvilablity  , this.disabledDaysIndex)
      }
      this.meetingForm.patchValue({
        otherUserUUID: this.otherUser.uid || this.otherUser.uuid || this.otherUser.userUUID,
        meetingTitle: (this.userProfileData.org_name || this.otherUser.name) + ' <> ' + this.companyName,
        // timeFrom: startTime,
        // timeTo: endTime,
      });

      console.log('userProfileData', this.meetingForm.value)
    }
  }

  handleDurationChange() {
    let today = new Date();
    const fromTime = this.meetingForm.value.timeFrom;
    today.setHours(fromTime.split(':')[0])
    today.setMinutes(fromTime.split(':')[1])
    today = add(today, {
      minutes: +this.meetingForm.value.duration
    })
    const endTime = today.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);

    this.meetingForm.patchValue({
      timeTo: endTime
    })
    console.log('event', this.meetingForm.value)
  }

  onApplyBtnClick(): void {
    // console.log('sahjcvsdjvn');
    // let message =  this.message;
    // let message = this.messageAcceptOptions === 'Other' ? this.message : this.messageAcceptOptions;
    // if (this.messageAcceptOptions === 'Email') {
    //   message = 'Email at ' + this.emailForm.value.email;
    // }
    let message = this.messageAcceptOptions === 'Other' || this.messageAcceptOptions === 'Connect offline' ? this.message : this.messageAcceptOptions;
    let data: any = {};
    // if (this.profileData.accountType !== ACCOUNT_TYPE.INVESTOR && this.profileData.accountType !== ACCOUNT_TYPE.STARTUP) {
    //   message = this.message
    // }

    if (this.messageAcceptOptions === 'Share meeting details') {
      message = 'Meeting Details: ' + this.message
    }
    if (this.messageAcceptOptions === 'Schedule call') {
      data = this.formatMeetingData()
    }

    console.log(message, 'message')
    this.activeModal.close({ message, data });
  }

  get isDisabledApply() {
    if (this.messageAcceptOptions === 'Share meeting details') {
      return !this.message
    }
    if (this.messageAcceptOptions === 'Schedule call') {
      return !this.meetingForm.valid
    }
    // if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR || this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      return this.messageAcceptOptions === 'Other' || this.messageAcceptOptions === 'Connect offline' ? !this.message : !this.messageAcceptOptions;
    // }
    // return !this.message;
  }

  closeModal(): void {
    this.activeModal.close(null);
  }

  async calculateSlots(date) {
    const uid = this.otherUser.uid || this.otherUser.userUUID
    const month = ('' + date.month).length === 2 ? date.month : '0' + date.month
    const day = ('' + date.day).length === 2 ? date.day : '0' + date.day
    const selectedDateFormatted = `${date.year}-${month}-${day}`

    const dateAvailability = await this.meetingService.getUsersAvailabilityByDate(uid, selectedDateFormatted).toPromise()

    this.timeSlots = await calculateSlots({
      date,
      brandDetails: this.brandDetails,
      calendar:this.calendar,
      calenderAvilablity: this.calenderAvilablity,
      dateAvailability,
      timeSlots:this.timeSlots
    })
  }


  formatMeetingData(): void {
    const payload: IMeetingPatchModel = {
      ...this.meetingForm.value,
      meetingTimeType: MeetingTime.schedule_later,
    }
    const { date } = this.meetingForm.value;
    payload.date = `${date.year}-${date.month}-${date.day}`

    const utcTimeFrom = moment(`${date.year}-${date.month}-${date.day} ${payload.timeFrom}`, 'YYYY-MM-DD HH:mm').utc()
    const sqlDateFrom = utcTimeFrom.utc().format("YYYY-MM-DD")
    const sqlTimeFrom = utcTimeFrom.utc().format("HH:mm")

    const utcTimeTo = moment(`${date.year}-${date.month}-${date.day} ${payload.timeTo}`, 'YYYY-MM-DD HH:mm').utc()
    const sqlDateTo = utcTimeTo.utc().format("YYYY-MM-DD")
    const sqlTimeTo = utcTimeTo.utc().format("HH:mm")

    const payloadNew = {
      ...this.meetingForm.value,
      meetingTimeType: MeetingTime.schedule_later,
      date: sqlDateFrom,
      timeFrom: sqlTimeFrom,
      timeTo: sqlTimeTo,
      offset: '' + new Date().getTimezoneOffset(),
      timeZone: moment.tz.guess(),
    }
    return payloadNew
  }


  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
