<div class="card p-0 shadow-sm mb-5 mb-xxl-8 single-post ribbon ribbon-end ribbon-clip" *ngIf="post">

  <div *ngIf="post && post.pinned" style="top:30px" class="ribbon-label co-investing-badge"> Pinned <span
      class="ribbon-inner bg-primary"></span></div>

  <div class="card-body pb-0">
    <div class="d-flex align-items-center mb-3">

      <div class="d-flex align-items-center flex-grow-1" *ngIf="post.isAdminPost">
        <div class="symbol symbol-45px me-5 p-1 border rounded">
          <img alt="Logo" [src]="(logoUrl | imagekitUrlAppender)+ '?tr=w-150,h-150,cm-pad_resize'" />
        </div>
        <div class="d-flex flex-column">
          <a class="mb-0 text-gray-900 text-hover-primary fs-6 fw-bolder" target="_blank">
            {{platformName}}
          </a>
          <span class="text-gray-400 fw-bold">{{daysPassed(post.createdAt)}} ago</span>
        </div>
      </div>

      <div class="d-flex align-items-center flex-grow-1" *ngIf="!post.isAdminPost">
        <div class="symbol symbol-45px me-5 p-1 border rounded position-relative">

          <img *ngIf="post.user.avatar"
            [src]="(post.user.avatar | imagekitUrlAppender)+ '?tr=w-150,h-150,cm-pad_resize'" alt="" />

          <ngx-avatars bgColor="#eaeaea" *ngIf="!post.user.avatar" [name]="profileData?.name" cornerRadius="4" size="50"
            [round]="false">
          </ngx-avatars>

          <div
            class="bg-green ms-2 rounded cursor-pointer position-absolute  "
            *ngIf="socketService.isUserOnline(post.user?.uuid)"
            ngbTooltip="Online"
              style="height: 10px; width: 10px;right: -5px;top: -5px;">

          </div>

        </div>

        <div class="d-flex flex-column">
          <a class="mb-0 text-gray-900 text-hover-primary fs-6 fw-bolder" (click)="$event.stopPropagation()"
            [routerLink]="redirectUri" >
            {{post.user.name}} <ng-container *ngIf="post.user.organizationType !== ACCOUNT_TYPE.MENTOR">({{post.user.organizationName}})</ng-container>
          </a>
          <span class="text-gray-400 fw-bold cursor-pointer"
            [routerLink]="'/community-feed/posts/post/' + post.uuid">{{daysPassed(post.createdAt)}} ago</span>

        </div>
      </div>

      <div class="my-0" ngbDropdown container="body"
        *ngIf="!post.isAdminPost && (profileData?.uuid  === post.user?.uuid)">
        <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" ngbDropdownToggle
          data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
          <i class="bi bi-three-dots fs-5 header-icons-font-size"></i>
        </button>


        <div ngbDropdownMenu
          class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
          data-kt-menu="true">


          <div class="menu-item px-5">
            <div class="menu-link px-5 d-flex justify-content-between align-items-center" ngbDropdownItem
              (click)="handleDeletePost()">
              Delete
              <i class="bi bi-trash fs-5 header-icons-font-size text-red cursor-pointer"></i>
            </div>
          </div>


          <div class="menu-item px-5">
            <div class="menu-link px-5 d-flex justify-content-between align-items-center" ngbDropdownItem
              (click)="handleEditPost()">
              Edit
              <i class="bi bi-pencil fs-5 text-black header-icons-font-size cursor-pointer"></i>
            </div>
          </div>

        </div>


      </div>

    </div>


    <div class="mb-7">
      <div id="kt_forms_widget_1_form" class="ql-quil ql-quil-plain pb-2" *ngIf="editMode">
        <quill-editor id="floatingTextarea-community-feed" [styles]="{height: 'auto', 'min-height': '50px'} "
          [(ngModel)]="editText" placeholder="What would you like to update?" [modules]="quillModules"></quill-editor>
        <div class="d-flex justify-content-start mb-4">
          <button class="btn btn-secondary text-black me-4 ls-2 text-uppercase btn-sm fs-6 text-end fw-boldest"
            [disabled]="!editText" (click)="handleCancle()">
            Cancel
          </button>

          <button class="btn btn-primary text-white ls-2 text-uppercase btn-sm fs-6 text-end fw-boldest"
            [disabled]="!editText" (click)="handleSubmit()">
            Save
          </button>

        </div>
      </div>

      <div class="text-gray-800 fs-5  mt-4" [innerHTML]="post.text | linky" *ngIf="!editMode"></div>

      <div class="d-flex align-items-center w-50 border p-2 rounded mt-3 cursor-pointer bg-light" *ngIf="post.profile">
        <div class="symbol symbol-45px me-5 p-1">
          <img alt="Logo" *ngIf="post.profile?.logo" [src]="(post.profile?.logo | imagekitUrlAppender)+ '?tr=w-150,h-150,cm-pad_resize'" />

          <ngx-avatars
          *ngIf="!post.profile?.logo"
          [name]="post?.profile?.name"
          size="45"
          [round]="false"
          fgColor="#fff" bgColor="var(--color-theme)"
          class="custom-avatar-comm-feed"
          [initialsSize]='2'
          >
          <!-- style="color:white!important" -->
        </ngx-avatars>
        </div>
        <div class="d-flex flex-column">
          <a [href]="profileRedirectUri" class="mb-0 text-gray-900 text-hover-primary fs-6 fw-bolder" target="_blank">
            {{post.profile?.name}}
          </a>
          <span class="text-gray-400 fw-bold">{{post.profile?.userName}}</span>
        </div>
      </div>



      <lightgallery class="row" [settings]="settings" *ngIf="post.image">

        <a [href]="post.image| imagekitUrlAppender" class="col-4 mb-3">

          <img alt="" class="ng-lazyloaded mt-2 border  border rounded" [useSrcset]="true" [lazyLoad]="(post.image| imagekitUrlAppender)+'?tr=w-200,h-200,cm-pad_resize'"
            style=" padding: 8px;">
        </a>

      </lightgallery>

      <lightgallery class="row" [settings]="settings">
        <ng-container *ngFor="let image of post.images">

          <a [href]="image| imagekitUrlAppender" class="col-4 mb-3 ">

            <img alt="" class="ng-lazyloaded mt-2  border rounded" [useSrcset]="true" [lazyLoad]="(image| imagekitUrlAppender)+'?tr=w-200,h-200,cm-pad_resize'"
              style=" padding: 8px;">
          </a>

        </ng-container>
      </lightgallery>


      <div class="d-flex align-items-center w-fit border p-2 rounded mt-3 cursor-pointer bg-light" *ngIf="post.file">
        <div class="symbol symbol-45px me-5 p-1">
          <div class="fi fi-{{fileExtension ? fileExtension : 'any'}} m-0">
            <div class="fi-content">{{fileExtension ? fileExtension : 'any'}}
            </div>
          </div>

        </div>
        <div class="d-flex flex-column">
          <a  [href]="post.file | imagekitUrlAppender" class="mb-0 text-gray-900 text-hover-primary fs-6 fw-bolder" target="_blank">
            {{fileName}}
          </a>
        </div>
      </div>

      <a [href]="post.url" target="_blank" class="d-flex align-items-center w-fit border py-3 ps-2 pe-5 rounded mt-3 cursor-pointer bg-light" *ngIf="post.url">
        <div class="symbol symbol-45px me-5 p-1">
          <i class="fs-2 text-gray-700 bi bi-box-arrow-up-right"></i>


        </div>
        <div class="d-flex flex-column">
          <p class="mb-0 text-gray-900 text-hover-primary fs-6 fw-bolder" >
            {{post.url}}
          </p>
        </div>
      </a>


      <!-- <div *ngIf="post.file" class="my-4">
        <a class="d-flex align-items-center" [href]="post.file | imagekitUrlAppender" target="_blank">
          <div class="fi fi-{{fileExtension ? fileExtension : 'any'}} m-0">
            <div class="fi-content">{{fileExtension ? fileExtension : 'any'}}
            </div>
          </div>
          <span class="ms-4">
            {{fileName}}
          </span>
        </a>
      </div> -->


      <div class="d-flex justify-content-between align-items-center border p-2 rounded mt-5">

        <div class="">
          <div class="d-flex align-items-center  " *ngIf="profileData">
            <div class="d-flex align-items-center me-5 cursor-pointer"  (click)="handleToggleComments()">
              <div class=" px-2 mt-1 me-1"
               >

                <span class=" m-0">
                  <i class=" bi bi-chat-left-text fs-5 header-icons-font-size"></i>
                </span>
              </div>
              {{post.stats.totalComments}} {{post.stats.totalComments === 1 ? 'comment' : 'comments'}}
            </div>

            <div class="d-flex align-items-center">
              <div
                class=" box mt-1 position-relative d-flex align-items-center me-1">

                <div class="">
                  <input type="checkbox" id="like" class="field-reactions">
                  <h3 class="text-desc">Press space and after tab key to navigation</h3>
                  <span class="svg-icon svg-icon-2 label-reactions m-0">
                    <i class="bi fs-5 header-icons-font-size" [ngClass]="
                    { 'bi-hand-thumbs-up-fill text-primary': post?.isLoggedInUserReacted,
                    'bi-hand-thumbs-up': !post?.isLoggedInUserReacted
                  }">
                    </i>
                  </span>

                  <ng-container *ngIf="!reacted">
                    <div class="toolbox"></div>
                    <label class="overlay" for="like"></label>
                    <button class="reaction-like" (click)="handleReaction(ReactionType.LIKE)"><span
                        class="legend-reaction">Like</span></button>
                    <button class="reaction-love" (click)="handleReaction(ReactionType.LOVE)"><span
                        class="legend-reaction">Love</span></button>
                    <button class="reaction-haha" (click)="handleReaction(ReactionType.LAUGH)"><span
                        class="legend-reaction">Haha</span></button>
                    <button class="reaction-wow" (click)="handleReaction(ReactionType.SURPRISE)"><span
                        class="legend-reaction">Wow</span></button>
                    <button class="reaction-sad" (click)="handleReaction(ReactionType.SUPPORT)"><span
                        class="legend-reaction">Sad</span></button>
                    <button class="reaction-angry" (click)="handleReaction(ReactionType.DIS_LIKE)"><span
                        class="legend-reaction">Angry</span></button>
                  </ng-container>
                </div>

              </div>
              <span class="cursor-pointer" (click)="openReactions()">
              {{post.stats.totalReactions}} {{post.stats.totalReactions === 1 ? 'reaction' : 'reactions'}}
              </span>
            </div>
          </div>
        </div>



        <div class="d-flex align-items-center ">
          <ng-template #popContent>
            <app-share-links [hideTitle]="true" [details]="{url:  postUrl, title: ''}"></app-share-links>
          </ng-template>
          <div container="body" [autoClose]="'outside'" [ngbPopover]="popContent" popoverClass="popover-custom-width">
            <div class="btn btn-sm btn-light btn-color-muted btn-active-light-success px-2 py-2 me-1">

              <span class="svg-icon svg-icon-3 m-0">
                <i class="bi bi-share fs-5 header-icons-font-size"></i>
              </span>
              Share
            </div>

          </div>
        </div>

      </div>


    </div>


    <ng-container *ngIf="commentsVisible && commentsList.length">

      <p class="text-gray-600 fw-bold btn-sm btn-light btn-color-muted btn-active-light-success w-100">
        Comments
      </p>

      <div class="mb-7 " *ngFor="let comment of commentsList; let i = index">
        <app-feed-single-comment (onDelete)="afterComment('delete')" (onRefresh)="afterComment('ignore')"
          [comment]="comment" (onAdded)="afterComment()" [post]="post"></app-feed-single-comment>
        <div *ngIf="i !== (commentsList.length-1)" class="separator separator-dashed"></div>
      </div>

      <div class="flex-equal text-center mt-4 mb-8" *ngIf="
      paginationMeta?.currentPage * paginationMeta?.itemsPerPage <
      paginationMeta?.totalItems
      ">
        <div class="border-primary me-2 inter text-primary fw-bolder cursor-pointer" (click)="handleLoadMore()">
          Load more
        </div>
      </div>

    </ng-container>

    <div class="separator mb-4"></div>

    <app-feed-comment-form (onAdded)="afterComment()" [postId]="post.uuid"></app-feed-comment-form>

  </div>

</div>
