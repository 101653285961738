import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Observable,
  of,
  throwError
} from "rxjs";
import {
  catchError, map,
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { IMeetingPatchModel, METTING_AVAILABILITY, MeetingsModel } from "../domain/meetings.model";
import { ApiEndpointService } from "./api-endpoint.service";
import { Store } from "@ngrx/store";
import * as NotificationsActions from "src/app/core/state/notifications/notifications.action";

@Injectable({
  providedIn: "root"
})
export class MeetingService {

  constructor(private http: HttpClient, private toastService: ToastAlertService,
    private store: Store
  ) { }

  public fetchMeetingData(meetingUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS_PUBLIC + meetingUUID);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching meeting data', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchInterviewData(meetingUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS + meetingUUID + '/job-interview');
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching meeting data', 'error');
        return throwError(() => fault);
      })
    );
  }

  public patchInterviewData(meetingUUID: string, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS + meetingUUID + '/job-interview');
    return this.http.patch(url, payload).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching meeting data', 'error');
        return throwError(() => fault);
      })
    );
  }



  public fetchMeetings(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS);
    return this.http.get(url).pipe(
      map((response: any): MeetingsModel => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching meeting data', 'error');
        return throwError(() => fault);
      })
    );
  }


  public fetchMeetingsWithPendingAcceptance(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS) + 'pending-acceptance';
    return this.http.get(url).pipe(
      map((response: any): MeetingsModel => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching meeting data', 'error');
        return throwError(() => fault);
      })
    );
  }

  public deleteMeetings(meetingUUID): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS + meetingUUID);
    return this.http.delete(url).pipe(
      map((response: any): MeetingsModel => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching meeting data', 'error');
        return throwError(() => fault);
      })
    );
  }

  public createMeeting(payload: IMeetingPatchModel): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS);
    return this.http.post(url, payload).pipe(
      map((response: any): MeetingsModel => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while saving meeting data', 'error');
        return throwError(() => fault);
      })
    );
  }


  public updateMeeting(meetingUUID: string, payload: IMeetingPatchModel): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS + meetingUUID);
    return this.http.patch(url, payload).pipe(
      map((response: any): MeetingsModel => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching meeting data', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchMeetingNotes(meetingUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETING_NOTES + meetingUUID);
    return this.http.get(url).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching meeting notes', 'error');
        return throwError(() => fault);
      })
    );
  }

  public saveMeetingNotes(meetingUUID: string, notes: { notes: string }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETING_NOTES + meetingUUID);
    return this.http.post(url, notes).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while saving meeting notes', 'error');
        return throwError(() => fault);
      })
    );
  }

  public proposeTime(meetingUUID: string, payload: any): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS + meetingUUID) + '/propose';
    return this.http.post(url, payload).pipe(
      map((response: any): MeetingsModel => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while proposing meeting time', 'error');
        return throwError(() => fault);
      })
    );
  }



  public proposeAction(meetingUUID: string, payload: any): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS + meetingUUID) + '/propose/action';
    return this.http.post(url, payload).pipe(
      map((response: any): MeetingsModel => {
        this.toastService.showToast(response?.message || 'Meeting time updated');
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while proposing meeting time', 'error');
        return throwError(() => fault);
      })
    );
  }


  public getCalenderAvilablity(): Observable<any> {
    return of(
      {
        availability: METTING_AVAILABILITY.specific_days,
        days: [
          {
            closed: true,
            dayName: "Mon",
            dayIndex: 1,
            times: [
              {
                startTime: "10:15",
                endTime: "16:00",
              },
              // {
              //   startTime: "20:30",
              //   endTime: "21:00",
              // }
            ]
          }],
      }
    )
  }

  public setUsersAvailability(payload: any): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS) + 'users/calendar-availability';
    return this.http.patch(url, payload).pipe(
      map((response: any): MeetingsModel => {
        this.toastService.showToast(response?.message || 'Meeting availability updated');
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while saving availability', 'error');
        return throwError(() => fault);
      })
    );
  }

  public getUsersAvailability(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS) + 'users/calendar-availability';
    return this.http.get(url).pipe(
      map((response: any): MeetingsModel => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }
  public getUsersAvailabilityById(userId: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS) + 'users/calendar-availability/'+userId;
    return this.http.get(url).pipe(
      map((response: any): MeetingsModel => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  /**
   * @param date : format 2023-06-14
   */
  public getUsersAvailabilityByDate(userId: string, date: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS) + 'users/calendar-availability/'+userId + '/'+ date;
    return this.http.get(url).pipe(
      map((response: any): MeetingsModel => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  handleAcceptMeetingRequest(mettingUUID: string) {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS) + mettingUUID + '/accept';
    return this.http.patch(url, {}).pipe(
      map((response: any): MeetingsModel => {
        this.store.dispatch(new NotificationsActions.SetNotificationsCount())
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while accepting request', 'error');
        return throwError(() => fault);
      })
    );
  }


  handleRejectMeetingRequest(mettingUUID: string, payload = {}) {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETINGS) + mettingUUID + '/reject';
    return this.http.patch(url, payload).pipe(
      map((response: any): MeetingsModel => {
        this.store.dispatch(new NotificationsActions.SetNotificationsCount())
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while rejecting request', 'error');
        return throwError(() => fault);
      })
    );
  }


  public shareMeetingNotes(meetingUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MEETING_NOTES + meetingUUID + '/share');
    return this.http.post(url, {}).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while share meeting notes', 'error');
        return throwError(() => fault);
      })
    );
  }

}
