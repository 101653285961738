<div class="card h-xl-100">

  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label fw-bolder text-dark fs-3" [innerHTML]="title"></span>
    </h3>
  </div>


  <div class="card-body pt-5">

    <ng-template [ngIf]="updatedList && updatedList.length > 0" [ngIfElse]="empty">
      <div class="d-flex align-items-sm-center mb-7" *ngFor="let data of updatedList" [ngbPopover]="popContent"
        [disablePopover]="!!tooltipDisabledProfileLock()"
        container="body" triggers="mouseenter:mouseleave" popoverClass="popover-custom-width">


        <ng-template #popContent>
          <div [innerHTML]="(data.shortDescription ||  data.aboutUs || data.name || '') | decodeHtmlString"
            *ngIf="data.shortDescription ||  data.aboutUs || data.name">
          </div>
          <div *ngIf="data.elevatorPitch">
            {{data.elevatorPitch}}
          </div>
        </ng-template>


        <div class="symbol symbol-50px me-5 dashboard-common-avatar">
          <ngx-avatars *ngIf="!data.logo" fgColor="#fff" bgColor="var(--color-theme)" initialsSize="2" size="50"
            [name]="data.name"></ngx-avatars>
          <ngx-avatars *ngIf="data.logo" size="50" cornerRadius="4" [round]="false"
            [src]="data.logo | imagekitUrlAppender" [name]="data.name"></ngx-avatars>
        </div>


        <div class="d-flex align-items-center flex-row-fluid flex-wrap">
          <div class="flex-grow-1 me-2">
            <a (click)="nagivate(data.pageRoute)" [ngbTooltip]="tooltipDisabledProfileLock()"
              class="text-gray-800 text-hover-primary fs-4 fw-bolder">{{data.name}}</a>
            <span class="text-muted fw-bold d-block fs-7">
              {{data.industries | slice:0:2}}
            </span>
          </div>
        </div>

      </div>

      <a [routerLink]="viewMoreLink" class="text-center btn-outline border-secondary w-100 btn text-hover-primary"
      [queryParams]="queryParams" >
        View more
      </a>
    </ng-template>
    <ng-template #empty>
      <div class="d-flex align-items-sm-center mb-7">
        <div class="alert alert-primary alert-width-100 text-center" role="alert">
          No results found
        </div>
      </div>
    </ng-template>

  </div>

</div>
