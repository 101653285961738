import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Console } from 'console';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';
import { getProfileData } from 'src/app/core/state/profile';

@Component({
  selector: 'app-reject-candidate-modal',
  templateUrl: './reject-candidate-modal.component.html',
  styleUrls: ['./reject-candidate-modal.component.scss']
})
export class RejectCandidateModalComponent implements OnInit {
  @Input() title: string;
  @Input() msgText: string = '';
  @Input() applyBtnText: string = "Ok";
  @Input() cancelBtnText: string = "Cancel";
  @Input() maxCharLimit: number = 300;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();
  public globalSettings$: Observable<IGlobalSettings>;
  public globalSettings: any;

  ACCOUNT_TYPE = ACCOUNT_TYPE;
  message: string;

  messageRejectOptions;

  rejectOptions = [
  ];



  constructor(
    public activeModal: NgbActiveModal,
    private readonly store: Store,
  ) {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        console.log({ res })
        this.profileData = res;
      }
    })
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
        this.rejectOptions = (this.globalSettings.CandidateRejectionReasons || []).map((e) => ({ value: e.value, isSelected: false, label: e.name }))
        this.rejectOptions.push({

          label: 'Other',
          value: 'Other',
          isSelected: false

        })
      }
    })
  }

  ngOnInit(): void {
    this.message = this.msgText;
  }

  onApplyBtnClick(): void {
    console.log('this.rejectOptions', this.rejectOptions, this.message)
    const messages = this.rejectOptions.filter(e => e.isSelected && e.value !== 'Other').map(e => e.value)
    if (this.message) {
      messages.push(this.message)
    }
    // let message = this.messageRejectOptions === 'Other' ? this.message : this.messageRejectOptions;
    this.activeModal.close(messages.join(','));
  }

  closeModal(): void {
    this.activeModal.close(null);
  }


  get isOthersSelected() {
    return this.rejectOptions.find((e) => e.value === 'Other')?.isSelected;
  }


  get isDisabled() {
    const selectedLen = this.rejectOptions.filter(e => e.isSelected && e.value !== 'Other')
    return (!selectedLen.length && !this.isOthersSelected) || (this.isOthersSelected && !this.message)
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
