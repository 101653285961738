import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'numberFormatter' })
export class NumberFormatterPipe implements PipeTransform {

  transform(value: number | string) {
    value = +value;
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return value;
  }
}
