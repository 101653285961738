import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Observable, distinctUntilChanged, takeUntil } from 'rxjs';
import { ICalenderEventModal } from 'src/app/core/domain/meetings.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { getProfileData } from 'src/app/core/state/profile';
import { getGlobalSettings } from 'src/app/core/state/global';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { GlobalService } from 'src/app/core/service/global.service';
import 'add-to-calendar-button';
import { ProfileService } from 'src/app/core/service/profile.service';
import { formatEnumStringForUI } from '../../utils/common-methods';
@Component({
  selector: 'app-event-details-one-on-one-modal',
  templateUrl: './event-details-one-on-one-modal.component.html',
  styleUrls: ['./event-details-one-on-one-modal.component.scss']
})
export class EventDetailsOneOnOneModalComponent implements OnInit {
  globalSettings: IGlobalSettings;

  @Input() modalInst: NgbModalRef;

  @Input() modalData: {
    action: string;
    event: any;
  };

  @Input() isRescheduling = false;
  bookingLoading = false;
  bookingLoadingForSlotTimeFrom;
  private destroyed$: Subject<void> = new Subject();
  public profileDetails$: Observable<IProfileDetails>;
  profileDetails: IProfileDetails;
  selectedResponse;

  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private meetingService: MeetingService,
    private profileService: ProfileService,
    private router: Router,
    private readonly store: Store,
    private uiLoader: NgxUiLoaderService,
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    console.log('modalData', this.modalData)
    if (this.modalData?.event?.checkIfResponseExists?.responseAction) {
      console.log('=========================')
      this.selectedResponse = { value: this.modalData.event?.checkIfResponseExists?.responseAction }
    }

    this.profileDetails$ = this.store.pipe(select(getProfileData));
    this.profileDetails$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.profileDetails = res; }
    })
    this.store.pipe(select(getGlobalSettings)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
  }


  get meetingUrl() {
    const event: any = this.modalData.event
    const link = this.router.serializeUrl(this.router.createUrlTree(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid]));
    return window.location.origin + link
  }
  meetingDetail(event) {
    this.closeModal();
    // this.router.navigate(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid])
    const link = this.router.serializeUrl(this.router.createUrlTree(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid]));
    window.open(link, '_blank');
  }


  closeModal(): void {
    this.modalInst.close({});
  }

  get isInPast() {
    return moment(this.modalData?.event?.date).isBefore(moment(), 'date')
  }

  tConvert(time, date) {
    return time
  }

  get eventDate() {
    return new Date(this.modalData.event.date)
  }


  formatTo24Hour(timeAMPM) {
    const time24Hour = moment(timeAMPM, 'h:mm A').format('HH:mm');
    return time24Hour
  }

  // selectResponse(item) {
  //   const event: any = this.modalData.event

  //   this.selectedResponse = item;

  //   this.globalService.eventResponse(event.id, item.value).subscribe()
  // }

  attendeeDetailBookedRes;
  handleBook(slot) {
    this.bookingLoading = true;
    const event: any = this.modalData.event
    console.log('event', event)
    this.bookingLoadingForSlotTimeFrom = slot.timeFrom;
    const timeFrom = slot.timeFrom.split(":").slice(0, 2).join(':')
    const timeTo = slot.timeTo.split(":").slice(0, 2).join(':')
    this.globalService.bookEventSlot(event.uuid, timeFrom, timeTo).subscribe(res => {
      this.bookingLoading = false;
      this.attendeeDetailBookedRes = res
      // this.closeModal()
    })
  }

  get speakerProfileLink() {
    if (this.modalData?.event?.speakerCompanyDetails) {
      return this.profileService.getProfileLink(
        this.modalData?.event?.speakerCompanyDetails?.uuid,
        this.modalData?.event?.speakerCompanyDetails?.name,
        this.modalData?.event?.speakerDetails?.accountType
      )
    }
    return ''
  }

  get attendeeDetail() {
    return this.modalData.event?.attendeeDetail || this.modalData.event?.checkIfResponseExists || this.attendeeDetailBookedRes
  }

  get slots() {
    return (this.modalData?.event?.slots || []).filter(e => !e.booked)
  }

  formatEnumStringForUI(input) {
    return formatEnumStringForUI(input)
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


}
