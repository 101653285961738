import { createFeatureSelector, createSelector } from "@ngrx/store";
import { StartupCompareState } from "./startup.compare.reducer";

export const selectStartupCompareState = createFeatureSelector<StartupCompareState>("startupCompare");

export const getStartupCompareDashboard = createSelector(
    selectStartupCompareState,
    (state: StartupCompareState) => state.payload
)

export const getStartupCompareDashboardError = createSelector(
    selectStartupCompareState,
    (state: StartupCompareState) => state.startupCompareError
)