import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE, InvestorTypes } from 'src/app/core/domain';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { GlobalService } from 'src/app/core/service/global.service';
import { getCorporateProfileCompleteness } from 'src/app/core/state/corporate-dashboard/corporate.dashboard.selectors';
import { getBrandDetails, getGlobalSettings } from 'src/app/core/state/global';
import { getIndividualInvestorProfileCompleteness, getInvestorProfileCompleteness } from 'src/app/core/state/investor-dashboard';
import { getMentorProfileCompleteness } from 'src/app/core/state/mentors-dashboard/mentors.dashboard.selectors';
import { getNotificationsCount } from 'src/app/core/state/notifications';
import { NotificationsCount } from 'src/app/core/state/notifications/notifications.model';
import { getProfileData } from 'src/app/core/state/profile';
import { getServiceProviderProfileCompleteness } from 'src/app/core/state/service-provider-dashboard/service-provider.dashboard.selectors';
import { getStartupProfileCompleteness } from 'src/app/core/state/startup-dashboard';
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard";
import * as CorporateDashBoardActions from "src/app/core/state/corporate-dashboard/corporate.dashboard.action";
import * as MentorDashBoardActions from "src/app/core/state/mentors-dashboard/mentors.dashboard.action";
import * as ServiceProviderDashBoardActions from "src/app/core/state/service-provider-dashboard/service-provider.dashboard.action";
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';
import { MentorshipService } from 'src/app/core/service/mentorship.service';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { CommunityFeedService } from 'src/app/core/service/community-feed.service';
import { IFeedUserDetails } from 'src/app/core/domain/community-feed';
import { ProfileService } from 'src/app/core/service/profile.service';
import { ActivatedRoute } from '@angular/router';
import * as FeedAction from 'src/app/core/state/feed';
import { getFeedStats } from 'src/app/core/state/feed/feed.selectors';

@Component({
  selector: 'app-community-feed-stats-count',
  templateUrl: './community-feed-stats-count.component.html',
  styleUrls: ['./community-feed-stats-count.component.scss']
})
export class CommunityFeedStatsCountComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userDetails: IFeedUserDetails

  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();
  brandDetails: Partial<IBrandDetails>;
  profileCompleteness;
  ACCOUNT_TYPE = ACCOUNT_TYPE;
  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings: IGlobalSettings;
  s3LogoUrl = ''
  stats = {
    totalComment: 0,
    totalPost: 0,
    totalPostReaction: 0
  };
  userId = '';
  type: 'comments' | 'reactions';

  constructor(
    private globalService: GlobalService,
    private readonly store: Store,
    private mentorshipService: MentorshipService,
    private communityFeedService: CommunityFeedService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.route.params.subscribe((res) => {
      if (res['userId']) {
        this.userId = res['userId']
      } else if (res['type']) {
        this.type = res['type']
      }
    })

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;

        this.store.dispatch(new FeedAction.GetStats());

        this.store.pipe(select(getFeedStats))
          .pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
            if (res) { this.stats = res; }
          });

        // this.communityFeedService.getStats().subscribe((stats) => {
        //   this.stats = stats;
        // })
      }
    })

    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.globalSettings = res; this.createS3LogoUrl(); }
    });
    // this.getProfileCompleteness();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userDetails'] && this.globalSettings) {
      this.createS3LogoUrl()
    }
  }

  createS3LogoUrl = () => {
    if (this.globalSettings?.imgKitUrl && (this.profileData?.avatar || this.userDetails?.avatar)) {
      this.s3LogoUrl = (this.userDetails?.avatar || this.profileData?.avatar) + '?tr=w-100,h-100,cm-pad_resize';;
    } else {
      this.s3LogoUrl = '';
    }
  }

  get showOwnProfile() {
    return !(!this.userDetails || this.userId === this.profileData.uuid)
  }

  openProfile() {
    if (this.userDetails) {
      let url = this.profileService.getProfileLink(
        this.userDetails.companyUUID,
        this.userDetails.companyName,
        this.userDetails.accountType
      )
      window.open(url, '_blank')
    } else {
      this.profileService.profilePageUrl$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res: string) => {
        if (res) {
          window.open(res, '_blank')
        }
      })
    }
  }


  // getProfileCompleteness() {

  //   if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {

  //     if (this.profileData.investorType === InvestorTypes.INDIVIDUAL) {
  //       this.store.dispatch(new InvestorDashboardAction.GetIndividualProfileCompleteness());
  //       this.store.pipe(select(getIndividualInvestorProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
  //         if (res) {
  //           this.profileCompleteness = res;
  //         }
  //       })
  //     } else {
  //       this.store.dispatch(new InvestorDashboardAction.GetProfileCompleteness());
  //       this.store.pipe(select(getInvestorProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
  //         if (res) {
  //           this.profileCompleteness = res;
  //         }
  //       })
  //     }

  //   } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
  //     this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
  //     this.store.pipe(select(getStartupProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
  //       if (res) {
  //         this.profileCompleteness = res;
  //         console.log({ res })
  //       }
  //     });
  //   } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
  //     this.store.dispatch(new CorporateDashBoardActions.GetProfileCompleteness());
  //     this.store.pipe(select(getCorporateProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
  //       if (res) {
  //         this.profileCompleteness = res;
  //       }
  //     });
  //   } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
  //     this.store.dispatch(new MentorDashBoardActions.GetProfileCompleteness());
  //     this.store.pipe(select(getMentorProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
  //       if (res) {
  //         this.profileCompleteness = res;
  //       }
  //     });
  //   }
  //   else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
  //     this.store.dispatch(new ServiceProviderDashBoardActions.GetProfileCompleteness());
  //     this.store.pipe(select(getServiceProviderProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
  //       if (res) {
  //         this.profileCompleteness = res;
  //       }
  //     });
  //   }
  // }

  ngOnDestroy(): void {
    this.destroyed$.complete();
    this.destroyed$.unsubscribe();
  }

}
