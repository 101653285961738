import { Injectable } from '@angular/core';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { getGlobalSettings } from '../state/global';
import { Title } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class SiteMetadataService {
  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings: IGlobalSettings;
  latestTitle = '';

  constructor(
    private readonly store: Store,
    private title: Title
  ) {
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.globalSettings = res;

        if (this.latestTitle) {
          this.setTitle(this.latestTitle)
        }
      }
    })
  }

  setTitle(title, type: '' = '') {
    if(this.globalSettings?.branding?.brandName){
      this.latestTitle = title ? (title + ` @${this.globalSettings?.branding?.brandName}`) : this.globalSettings?.branding?.brandName;

      this.title.setTitle(this.latestTitle);
    }else{
      this.latestTitle = title;
    }
  }
}
