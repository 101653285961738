import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'futureSlots'
})
export class FutureSlotsPipe implements PipeTransform {

  transform(slots: any[], time: any): any[] {
    const i = slots.findIndex(e => e === time);
    if (i < 0) {
      return [];
    }
    const copySlots = [...slots];
    return copySlots.slice(i + 1);
  }

}
