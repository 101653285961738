
<div>
  <input type="file" #supportingFileDropRef [multiple]="true" [id]="'doc-ref'" hidden
    (change)="supportingFileBrowseHandler($event)" [accept]="accept" />
  <!-- accept=".pdf, .doc, .docx, .rtf, .ppt, .pptx" -->
  <label [for]="'doc-ref'" appDragAndDropFileSelect
    class="btn btn-outline btn-outline-dashed text-center p-10 mb-5 w-100 bg-white"
    (fileDropped)="supportingFileDroppedHandler($event)">
    Click or Drop file in this box to upload.<br />Accepted
    formats: {{acceptLabel}}
    <!-- formats: ppt, pptx, doc, docx, pdf -->

    <br />

    <span class="badge badge-secondary badge-lg ms-2 mt-4 px-5 py-4 fs-3"><i
        class="bi bi-cloud-upload text-black fs-3"></i> Upload</span>
  </label>
</div>
