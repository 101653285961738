import { Component, Input, OnInit } from '@angular/core';
import { IResource } from 'src/app/core/domain/webinar';

@Component({
  selector: 'app-resource-report-card',
  templateUrl: './resource-report-card.component.html',
  styleUrls: ['./resource-report-card.component.scss']
})
export class ResourceReportCardComponent implements OnInit {
  @Input() item: IResource;

  constructor() { }

  ngOnInit(): void {
  }

  playVideo(webinar: IResource) {
    if (webinar.externalUrl) {
      window.open(webinar.externalUrl, '_blank');
    } else {
      // window.open(this.imageKit.transform(webinar.file?.objectUrl || webinar.file), '_blank');
      window.open(webinar.file?.objectUrl || webinar.file, '_blank');
    }
  }


}
