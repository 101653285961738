import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'twentyFourHoursToAmPm'
})
export class TwentyFourHoursToAmPmPipe implements PipeTransform {
  transform(time: string): any {
    if (time) {
      return moment(time, 'HH:mm').format('hh:mm A');
    }
    return time;
  }
}
