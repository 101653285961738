import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { ICommunityFeed, IFeedComment, ReactionType } from 'src/app/core/domain/community-feed';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { CommunityFeedService } from 'src/app/core/service/community-feed.service';
import { SocketService } from 'src/app/core/service/socket.service';
import { getProfileData } from 'src/app/core/state/profile';
import daysPassed from 'src/app/shared/constants/daysPassed';
import { createSlug } from 'src/app/shared/utils/common-methods';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-feed-single-comment',
  templateUrl: './feed-single-comment.component.html',
  styleUrls: ['./feed-single-comment.component.scss']
})
export class FeedSingleCommentComponent implements OnInit {
  @Input() comment: IFeedComment;
  @Input() parentComment: IFeedComment;
  @Input() post: ICommunityFeed;
  @Output() onDelete = new EventEmitter()
  @Output() onAdded = new EventEmitter()
  @Output() onRefresh = new EventEmitter()
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();
  redirectUri = '';
  reacted = false;
  ReactionType = ReactionType;

  showReplyBox = false;

  constructor(
    private feedService: CommunityFeedService,
    private store: Store,
    public socketService: SocketService
  ) { }

  ngOnInit(): void {
    const user = this.comment.user

    if (user.accountType === ACCOUNT_TYPE.STARTUP) {
      this.redirectUri = `/startups/profile/` + `${user.organizationUUID}/${createSlug(user.organizationName)}`
    } else if (user.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.redirectUri = `/corporates/profile/` + `${user.organizationUUID}/${createSlug(user.organizationName)}`
    } else if (user.accountType === ACCOUNT_TYPE.MENTOR) {
      this.redirectUri = `/mentors/profile/` + `${user.organizationUUID}/${createSlug(user.organizationName) || '-'}`
    } else if (user.accountType === ACCOUNT_TYPE.INVESTOR) {
      this.redirectUri = `/investors/profile/` + `${user.organizationUUID}/${createSlug(user.organizationName) || '-'}`
    } else if (user.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {
      this.redirectUri = `/program-office-members/profile/` + `${user.uuid}/${createSlug(user.organizationName) || '-'}`
    }else if (user.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      this.redirectUri = `/service-provider/profile/` + `${user.organizationUUID}/${createSlug(user.organizationName) || '-'}`
    }

    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      this.profileData = res;
    })
  }

  handleDelete() {
    // Swal.fire({
    //   title: 'Delete Comment',
    //   text: 'Are you sure you want to delete this comment?',
    //   icon: 'error',
    //   showCancelButton: true,
    //   confirmButtonColor: 'var(--color-theme)',
    //   cancelButtonColor: '#7D8185',
    //   confirmButtonText: 'Delete'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     console.log('ttttt', this)
    if (this.parentComment) {
      this.feedService.deleteCommentReply(this.post.uuid, this.parentComment.uuid, this.comment.uuid).subscribe((res) => {
        this.onDelete.emit()
      })
    } else {
      this.feedService.deleteComment(this.post.uuid, this.comment.uuid).subscribe((res) => {
        this.onDelete.emit()
      })
    }
    //   }
    // });
  }
  daysPassed(date) {
    return daysPassed(date);
  }

  afterCommentAdded() {
    this.onAdded.emit()
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleReaction(reaction: ReactionType) {
    console.log('comment', this.comment)
    this.feedService.reactionOnComment(this.post.uuid, this.comment.uuid, reaction).subscribe((res) => {
      this.onRefresh.emit()
    })
  }

  openReactions() { }

  handleToggleComments() {
    this.showReplyBox = !this.showReplyBox
  }

}
