import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil, tap } from 'rxjs';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { ConnectionsService } from 'src/app/core/service/connections.service';
import { getBrandDetails } from 'src/app/core/state/global';
import { getProfileData } from 'src/app/core/state/profile';

@Component({
  selector: 'app-connection-req-message',
  templateUrl: './connection-req-message.component.html',
  styleUrls: ['./connection-req-message.component.scss']
})
export class ConnectionReqMessageComponent implements OnInit, OnChanges {
  @Input() toUserUUID;
  brandDetails: Partial<IBrandDetails>;
  private destroyed$: Subject<void> = new Subject();
  public getLoggedInUserDetails$: Observable<IProfileDetails>;
  loggedInUserDetails: any;
  connectionRequestStatus: {
    connected: boolean,
    requested: boolean,
    connectionUUID: string,
    message: string,
    status: string,
    canConnect: boolean,
    profileCompletenessPercent: number;
    toUserId: number;
    fromUserId: number;
    isInvestor: boolean;
    connectionMessage: string
  };


  constructor(
    private readonly store: Store,
    private connectionsService: ConnectionsService,
  ) {
    this.getLoggedInUserDetails$ = this.store.pipe(select(getProfileData));
  }

  ngOnInit(): void {
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
      }
    })

    this.getLoggedInUserDetails$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.loggedInUserDetails = res;
        this.checkConnectionRequest()
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes',changes)
      if(changes['toUserUUID']) {
        this.checkConnectionRequest()
      }
  }

  checkConnectionRequest(): void {
    if (!this.toUserUUID) {
      return
    }
    if (this.loggedInUserDetails && this.loggedInUserDetails?.uuid !== this.toUserUUID) {
      this.connectionsService.checkConnectionRequest(this.toUserUUID).pipe(takeUntil(this.destroyed$), tap((response: any) => {
        if (response?.data) {
          this.connectionRequestStatus = response?.data;
        }
      })).subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
