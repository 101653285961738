import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { getNotificationsCount } from 'src/app/core/state/notifications';
import { NotificationsCount } from 'src/app/core/state/notifications/notifications.model';

@Component({
  selector: 'app-connection-count',
  templateUrl: './connection-count.component.html',
  styleUrls: ['./connection-count.component.scss']
})
export class ConnectionCountComponent implements OnInit {
  private destroyed$: Subject<void> = new Subject();

  counts: NotificationsCount = {
    pendingConnectionCount: 0,
    sentConnectionCount: 0,
    unreadMessageCount: 0,
    unreadNotificationCount: 0,
    pendingAcceptanceMentorHoursCount: 0,
    pendingDocumentsUploadCount: 0,
    pendingProgramFormSubmissionCount:0,
    pendingAcceptanceMeetingCount: 0
  };
  constructor(
    private readonly store: Store,
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getNotificationsCount)).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.counts = res;
      }
    });
  }


  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
