import { Component, Input, OnInit } from '@angular/core';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { Observable, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { getProfileData } from 'src/app/core/state/profile';

@Component({
  selector: 'app-add-team-button',
  templateUrl: './add-team-button.component.html',
  styleUrls: ['./add-team-button.component.scss']
})
export class AddTeamButtonComponent implements OnInit {
  @Input() titleText: string;
  public getProfileData$: Observable<IProfileDetails>;
  private destroyed$: Subject<void> = new Subject();
  profileData: IProfileDetails;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = {...res};
        // this.profileData.canAddMembers = true
      }
    })
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
