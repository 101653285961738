import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, takeUntil, tap } from 'rxjs';
import { ICommunityFeed, IFeedComment, ReactionType } from 'src/app/core/domain/community-feed';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { IMetaPagination } from 'src/app/core/domain/search.model';
import { CommunityFeedService } from 'src/app/core/service/community-feed.service';
import { getProfileData } from 'src/app/core/state/profile';
import daysPassed from 'src/app/shared/constants/daysPassed';
import Swal from 'sweetalert2';
import lgZoom from 'lightgallery/plugins/zoom';
import { LightGalleryAllSettings } from 'lightgallery/lg-settings';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { createSlug } from 'src/app/shared/utils/common-methods';

import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReactionsModalComponent } from '../reactions-modal/reactions-modal.component';
import { SocketService } from 'src/app/core/service/socket.service';


const GlobalQuillConfig = {
  toolbar: [
    ['bold', 'italic', 'underline'],
  ]
}

@Component({
  selector: 'app-community-feed-single-post',
  templateUrl: './community-feed-single-post.component.html',
  styleUrls: ['./community-feed-single-post.component.scss']
})
export class CommunityFeedSinglePostComponent implements OnInit, OnDestroy {

  globalSettings: IGlobalSettings;
  public globalSettings$: Observable<IGlobalSettings>;


  @Input() post: ICommunityFeed;
  @Output() onDelete = new EventEmitter();
  commentsVisible = false;
  commentsList: IFeedComment[] = []
  paginationMeta: IMetaPagination;
  ReactionType = ReactionType;
  fileExtension = '';
  fileName = '';
  quillModules = GlobalQuillConfig;

  headerLogoUrl = 'assets/images/logos/logo-2.svg'
  logoUrl = 'assets/images/logos/logo-2.svg'
  platformName: string = "";

  editMode = false;
  editText;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();
  settings: Partial<LightGalleryAllSettings> = {
    counter: false,
    plugins: [lgZoom],
  };
  redirectUri = '';
  profileRedirectUri = '';
  ACCOUNT_TYPE = ACCOUNT_TYPE
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };

  postUrl = '';

  constructor(
    private feedService: CommunityFeedService,
    private store: Store,
    private modalService: NgbModal,
    public socketService: SocketService
  ) { }

  ngOnInit(): void {
    this.editText = this.post.text;
    if (this.post.file) {
      const splitted = this.post.file.split('.')
      this.fileExtension = splitted[splitted.length - 1];

      const nameSplitted = this.post.file.split('/')
      this.fileName = nameSplitted[nameSplitted.length - 1];
    }

    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      this.profileData = res;
    })

    if (this.post.user) {
      const user = this.post.user;
      // community-feed/posts/user/a3513350-b965-4f20-900b-5335a44f7796
      this.redirectUri = `/community-feed/posts/user/` + `${user.uuid}`

      // if (user.organizationType === ACCOUNT_TYPE.STARTUP) {
      //   this.redirectUri = `/startups/profile/` + `${user.organizationUUID}/${createSlug(user.organizationName)}`
      // } else if (user.organizationType === ACCOUNT_TYPE.CORPORATE) {
      //   this.redirectUri = `/corporates/profile/` + `${user.organizationUUID}/${createSlug(user.organizationName)}`
      // } else if (user.organizationType === ACCOUNT_TYPE.MENTOR) {
      //   this.redirectUri = `/mentors/profile/` + `${user.organizationUUID}/${createSlug(user.organizationName) || '-'}`
      // } else if (user.organizationType === ACCOUNT_TYPE.INVESTOR) {
      //   this.redirectUri = `/investors/profile/` + `${user.organizationUUID}/${createSlug(user.organizationName) || '-'}`
      // } else if (user.organizationType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      //   this.redirectUri = `/service-provider/profile/` + `${user.organizationUUID}/${createSlug(user.organizationName) || '-'}`
      // }
    }

    if (this.post.profile) {
      const profile = this.post.profile;

      if (profile.type === ACCOUNT_TYPE.STARTUP) {
        this.profileRedirectUri = `/startups/profile/` + `${profile.uuid}/${createSlug(profile.name)}`
      } else if (profile.type === ACCOUNT_TYPE.CORPORATE) {
        this.profileRedirectUri = `/corporates/profile/` + `${profile.uuid}/${createSlug(profile.name)}`
      } else if (profile.type === ACCOUNT_TYPE.MENTOR) {
        this.profileRedirectUri = `/mentors/profile/` + `${profile.uuid}/${createSlug(profile.name) || '-'}`
      } else if (profile.type === ACCOUNT_TYPE.INVESTOR) {
        this.profileRedirectUri = `/investors/profile/` + `${profile.uuid}/${createSlug(profile.name) || '-'}`
      }else if (profile.type === ACCOUNT_TYPE.PROGRAM_OFFICE) {
        this.profileRedirectUri = `/program-office-members/profile/` + `${profile.uuid}/${createSlug(profile.name) || '-'}`
      } else if (profile.type === ACCOUNT_TYPE.SERVICE_PROVIDER) {
        this.profileRedirectUri = `/service-provider/profile/` + `${profile.uuid}/${createSlug(profile.name) || '-'}`
      }
    }


    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
        this.platformName = res?.branding?.brandName;
        this.logoUrl = res?.branding?.logo || 'assets/images/logos/logo-2.svg'
        this.headerLogoUrl = res?.branding?.headerLogo || 'assets/images/logos/logo-2.svg'
      }
    })
    this.postUrl = window.location.origin + '/community-feed/posts/post/' + this.post.uuid
  }


  handleToggleComments() {
    this.commentsVisible = !this.commentsVisible;

    this.getCommentsOnPost()
  }

  getCommentsOnPost(loadMore = false) {
    if (this.commentsVisible) {
      const page = loadMore ? this.paginationMeta.currentPage + 1 : 1;
      // console.log('page-----', page)
      this.feedService.getCommentsOnPost(this.post.uuid, { page }).subscribe((res) => {
        if (res) {
          // console.log('comments', res);
          this.paginationMeta = res.meta;
          this.commentsList = loadMore ? [...this.commentsList, ...res.items] : res.items;
        }
      })
    }
  }

  daysPassed(date) {
    return daysPassed(date);
  }

  afterComment(action?: string) {
    this.commentsVisible = true;
    if (action !== 'ignore') {
      if (action === 'delete') {
        this.post.stats.totalComments = this.post.stats.totalComments - 1;
      } else {
        this.post.stats.totalComments = this.post.stats.totalComments + 1;
      }
    }
    this.getCommentsOnPost()
  }

  reacted = false;
  handleReaction(re: ReactionType) {
    this.reacted = true;
    this.feedService.postReaction(this.post.uuid, re).subscribe((res) => {
      this.reacted = false;

      // this.post.isLoggedInUserReacted = !this.post.isLoggedInUserReacted;

      this.feedService.getReactionCount(this.post.uuid).subscribe((count) => {
        // console.log('getReactionCount', count);
        this.post.stats.totalReactions = count.totalReactions;
        this.post.stats.reactionCount = count.reactionCount;
        this.post.isLoggedInUserReacted = count.isLoggedInUserReacted;
      })
    })
  }

  handleLoadMore() {
    this.getCommentsOnPost(true)
  }

  handleDeletePost() {
    Swal.fire({
      title: 'Delete Post',
      text: 'Are you sure you want to delete this post?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-theme)',
      cancelButtonColor: '#7D8185',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('ttttt', this)
        this.feedService.deletePost(this.post.uuid).subscribe((res) => {
          this.onDelete.emit();
        })
      }
    });
  }

  handleEditPost() {
    this.editMode = true
  }

  handleSubmit() {
    this.feedService.updatePost(this.post.uuid, {
      ...this.post,
      text: this.editText
    }).subscribe((res) => {
      this.post.text = this.editText;
      this.editMode = false
    })
  }


  handleCancle() {
    this.editMode = false;
    this.editText = this.post.text;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // get likeText(){
  //   if(this.post.isLoggedInUserReacted) {
  //     if((this.post.stats.totalReactions)-1) {
  //       return `You and ${(+this.post.stats.totalReactions)-1} others`
  //     }
  //     return 'You'
  //   }
  //   return this.post.stats.totalReactions
  // }

  openReactions() {
    if (this.post.stats.totalReactions > 0) {
      let modalRef = this.modalService.open(ReactionsModalComponent, {
        centered: true,
        size: 'md',
      });
      modalRef.componentInstance.post = this.post;
      modalRef.dismissed.pipe(tap((val) => {
        modalRef = undefined;
        // this.handleSubscriptions()
      })).subscribe();
    }
  }
}

