import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IWebinar } from 'src/app/core/domain/webinar';
import { YoutubePlayerModalComponent } from 'src/app/modules/webinars/youtube-player-modal/youtube-player-modal.component';
import { getEmbedUrl, getIdFromUrl, getYouTubeThumbnail } from '../../utils/common-methods';

@Component({
  selector: 'app-webinar-card',
  templateUrl: './webinar-card.component.html',
  styleUrls: ['./webinar-card.component.scss']
})
export class WebinarCardComponent implements OnInit {
  @Input() item: IWebinar;

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {

    this.item.thumbNailImage = getYouTubeThumbnail(this.item.videoEmbedUrl);
    this.item.videoEmbedUrl = getEmbedUrl(getIdFromUrl(this.item.videoEmbedUrl))
    this.item.origin = (new URL(this.item.videoEmbedUrl)).host

  }

  playVideo(webinar) {
    console.log('playVideo')
    const modalRef = this.modalService.open(YoutubePlayerModalComponent, { size: 'xl', scrollable: true, centered: true })
    modalRef.componentInstance.webinar = webinar;
  }

}
